import {
  ConsentManagementConsent,
  ConsentsByEntity,
  ConsentsByKey,
  ConsentsByRegion
} from './../consentManagementSlice'
import {
  DateRange,
  DateRangeChartData,
  DateRangeOption,
  buildCMDateRangeGraphQLQuery,
  getMonthlyDateRanges,
  getWeeklyDateRanges,
  mapCMDateRangeGraphQLResponse
} from './utils'
import { ConsentOptStatus, EntityIdentifyStatus, UNKNOWN } from '../constants'
import { gql } from 'graphql-request'

export const queryConsentManagementConsentsByRegion = (): string => {
  return gql`
    query consentManagementConsentsByRegion {
      optInLocation: consentHistoryGroupByLocation {
        edges {
          node {
            location
            consentHistory(filter: [{ key: OPT_STATUS, values: ["${ConsentOptStatus.Subscribed}"] }]) {
              count
            }
          }
        }
      }

      optOutLocation: consentHistoryGroupByLocation {
        edges {
          node {
            location
            consentHistory(filter: [{ key: OPT_STATUS, values: ["${ConsentOptStatus.UnSubscribed}"] }]) {
              count
            }
          }
        }
      }
    }
  `
}

export const mapConsentManagementConsentsByRegion = (raw: any): ConsentsByRegion[] => {
  const optinEdges = raw.optInLocation?.edges
  const optoutEdges = raw.optOutLocation?.edges

  const locationMap = {}

  optinEdges.forEach((edge: any) => {
    const location = edge?.node?.location || UNKNOWN
    const count = edge?.node?.consentHistory?.count || 0

    if (!locationMap[location]) {
      locationMap[location] = {
        name: location,
        totalOptIns: 0,
        totalOptOuts: 0
      }
    }

    locationMap[location].totalOptIns += count
  })

  optoutEdges.forEach((edge: any) => {
    const location = edge?.node?.location || UNKNOWN
    const count = edge?.node?.consentHistory.count || 0

    if (!locationMap[location]) {
      locationMap[location] = {
        name: location,
        totalOptIns: 0,
        totalOptOuts: 0
      }
    }

    locationMap[location].totalOptOuts += count
  })

  // Convert map to array
  let locationsArray: ConsentsByRegion[] = Object.values(locationMap)

  // Separate "Unknown" location if it exists
  const unknownLocation = locationsArray?.find((location) => location.name === UNKNOWN)
  locationsArray = locationsArray?.filter((location) => location.name !== UNKNOWN)

  // Sort locations by totalOptins and totalOptOut
  locationsArray.sort((a, b) => b.totalOptIns + b.totalOptOuts - (a.totalOptIns + a.totalOptOuts))

  // Handle "Others" category if more than 4 locations (excluding "Unknown")
  if (locationsArray.length > 4) {
    const others: ConsentsByRegion = {
      name: 'Others',
      totalOptIns: 0,
      totalOptOuts: 0
    }

    // Keep the top 4 locations and aggregate the rest into "Others"
    const topLocations = locationsArray?.slice(0, 4)
    const remainingLocations = locationsArray?.slice(4)

    remainingLocations?.forEach((location) => {
      others.totalOptIns += location.totalOptIns
      others.totalOptOuts += location.totalOptOuts
    })

    topLocations?.push(others)
    locationsArray = topLocations
  }

  // Add "Unknown" location if it exists
  if (unknownLocation) {
    locationsArray?.push(unknownLocation)
  }

  return locationsArray || []
}

export const queryConsentManagementConsentByTrendOverTime = (
  selectedDateRangeOption: string,
  datasourceTypes: string[]
): { trendQuery: string; dateRanges: DateRange[] } => {
  //// Need to be relooked after feature confirmation
  let dateRangeToQuery: DateRange[] = []

  switch (selectedDateRangeOption) {
    case DateRangeOption.Last4Weeks:
      dateRangeToQuery = getWeeklyDateRanges()
      break
    case DateRangeOption.Last4Months:
      dateRangeToQuery = getMonthlyDateRanges(4)
      break
    case DateRangeOption.Last12Months:
      dateRangeToQuery = getMonthlyDateRanges(12)
      break
    default:
      dateRangeToQuery = []
  }

  return {
    trendQuery: buildCMDateRangeGraphQLQuery(dateRangeToQuery, datasourceTypes),
    dateRanges: dateRangeToQuery
  }
}

export const mapConsentManagementConsentByTrendOverTime = (
  response: any,
  dateRanges?: DateRange[]
): DateRangeChartData[] => {
  return mapCMDateRangeGraphQLResponse(response, dateRanges) || []
}

export const queryConsentManagementEntityDistribution = (): string => {
  return gql`
  {
  optInEntityType: consentHistoryGroupByEntityType {
    edges {
      node {
        entityType {
          edges {
            node {
              id
              name
            }
          }
        }
        consentHistory(filter: [{key: OPT_STATUS, values: ["${ConsentOptStatus.Subscribed}"]}]) {
          count
        }
      }
    }
  }
  optOutEntityType: consentHistoryGroupByEntityType {
    edges {
      node {
        entityType {
          edges {
            node {
              id
              name
            }
          }
        }
        consentHistory(filter: [{key: OPT_STATUS, values: ["${ConsentOptStatus.UnSubscribed}"]}]) {
          count
        }
      }
    }
  }
  entityIdentifiedByLB: consentHistory(filter: [{key: ENTITY_IDENTIFIED, values: ["${EntityIdentifyStatus.Identified}"]}]) {
    count
  }
  entityUnIdentifiedByLB: consentHistory(filter: [{key: ENTITY_IDENTIFIED, values: ["${EntityIdentifyStatus.Unidentified}"]}]) {
    count
  }
}
  `
}

type EntityDistributionQuery = {
  entityIdentifiedByLB: number
  entityUnIdentifiedByLB: number
  entitiesDistributionData: ConsentsByEntity[]
}

export const mapConsentManagementEntityDistribution = (raw: any): EntityDistributionQuery => {
  const entityIdentifiedByLB = raw.entityIdentifiedByLB.count
  const entityUnIdentifiedByLB = raw.entityUnIdentifiedByLB.count

  const optinEdges = raw?.optInEntityType?.edges
  const optoutEdges = raw?.optOutEntityType?.edges

  const entityMap = {}

  optinEdges.forEach((edge: any) => {
    const entityNode = edge?.node?.entityType?.edges[0]?.node
    const entityName = entityNode ? entityNode?.name : UNKNOWN
    const entityId = entityNode ? entityNode?.id : ''
    const count = edge?.node?.consentHistory?.count

    if (!entityMap[entityName]) {
      entityMap[entityName] = {
        id: entityId,
        name: entityName,
        totalOptIns: 0,
        totalOptOuts: 0
      }
    }

    entityMap[entityName].totalOptIns += count
  })

  optoutEdges.forEach((edge: any) => {
    const entityNode = edge?.node?.entityType?.edges[0]?.node
    const entityName = entityNode ? entityNode?.name : UNKNOWN
    const entityId = entityNode ? entityNode?.id : ''
    const count = edge?.node?.consentHistory?.count

    if (!entityMap[entityName]) {
      entityMap[entityName] = {
        id: entityId,
        name: entityName,
        totalOptIns: 0,
        totalOptOuts: 0
      }
    }

    entityMap[entityName].totalOptOuts += count
  })

  let entitiesArray: ConsentsByEntity[] = Object.values(entityMap)

  // Separating "Unknown" entity if it exists
  const unknownEntity = entitiesArray?.find((entity) => entity?.name === UNKNOWN)
  entitiesArray = entitiesArray?.filter((entity) => entity?.name !== UNKNOWN)

  // Sorting entities by totalOptins and totalOptOut
  entitiesArray?.sort((a, b) => b.totalOptIns + b.totalOptOuts - (a.totalOptIns + a.totalOptOuts))

  // Handling "Others" category if more than 4 entities (excluding "Unknown")
  if (entitiesArray.length > 4) {
    const others: ConsentsByEntity = {
      name: 'Others',
      totalOptIns: 0,
      totalOptOuts: 0
    }

    // Keeping the top 4 entities and aggregate the rest into "Others"
    const topEntities = entitiesArray?.slice(0, 4)
    const remainingEntities = entitiesArray?.slice(4)

    remainingEntities?.forEach((entity) => {
      others.totalOptIns += entity.totalOptIns
      others.totalOptOuts += entity.totalOptOuts
    })

    topEntities?.push(others)
    entitiesArray = topEntities
  }

  // Adding "Unknown" entity if it exists
  if (unknownEntity) {
    entitiesArray?.push(unknownEntity)
  }

  const data = {
    entityIdentifiedByLB,
    entityUnIdentifiedByLB,
    entitiesDistributionData: entitiesArray
  }

  return data || []
}

export const queryConsentManagementConsentByTypeDatasourceSubscriptionList = (): string => {
  return gql`
    {
      communicationPreferences {
        edges {
          node {
            id
            name
            datasource {
              edges {
                node {
                  id
                  name
                  type
                }
              }
            }
            consentStats {
              edges {
                node {
                  consentStats {
                    total
                    optIn
                    optOut
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapConsentManagementConsentByTypeDatasourceSubscriptionList = (
  raw: any
): ConsentsByKey[] => {
  const mappedData = raw?.communicationPreferences?.edges?.map((edge) => {
    const { id, name, datasource, consentStats } = edge?.node

    const datasourceNode = datasource?.edges[0]?.node

    return {
      id,
      name: name,
      total:
        consentStats?.edges?.reduce((acc, curr) => acc + curr?.node?.consentStats?.total, 0) || 0,
      type: datasourceNode ? datasourceNode?.name : UNKNOWN,
      consentData: [
        {
          label: ConsentManagementConsent.optIn,
          value:
            consentStats?.edges?.reduce((acc, curr) => acc + curr?.node?.consentStats?.optIn, 0) ||
            0
        },
        {
          label: ConsentManagementConsent.optOut,
          value:
            consentStats?.edges?.reduce((acc, curr) => acc + curr?.node?.consentStats?.optOut, 0) ||
            0
        }
      ]
    }
  })

  // Sort by total in descending order
  mappedData.sort((a, b) => b.total - a.total)

  // Take the top 5 Subscription Lists
  const top5 = mappedData?.slice(0, 5)

  return top5 || []
}

export const queryConsentManagementDistributionByDatasource = (): string => {
  return gql`
    {
      consentManagementSettings {
        edges {
          node {
            datasource {
              edges {
                node {
                  id
                  name
                  type
                  consentStats {
                    edges {
                      node {
                        consentStats {
                          total
                          optIn
                          optOut
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapConsentManagementDistributionByDatasource = (raw: any): ConsentsByKey[] => {
  const mappedData: ConsentsByKey[] = []

  raw?.consentManagementSettings?.edges?.forEach((settingEdge) => {
    settingEdge?.node?.datasource?.edges?.forEach((dataSourceEdge) => {
      mappedData.push({
        name: dataSourceEdge?.node?.name,
        id: dataSourceEdge?.node?.id,
        total:
          dataSourceEdge?.node?.consentStats?.edges?.reduce(
            (acc, curr) => acc + curr?.node?.consentStats?.total,
            0
          ) || 0,
        consentData: [
          {
            label: ConsentManagementConsent.optIn,
            value:
              dataSourceEdge?.node?.consentStats?.edges?.reduce(
                (acc, curr) => acc + curr?.node?.consentStats?.optIn,
                0
              ) || 0
          },
          {
            label: ConsentManagementConsent.optOut,
            value:
              dataSourceEdge?.node?.consentStats?.edges?.reduce(
                (acc, curr) => acc + curr?.node?.consentStats?.optOut,
                0
              ) || 0
          }
        ]
      })
    })
  })

  // Sort by total in descending order
  mappedData?.sort((a, b) => b.total - a.total)

  // Handle merging into "Others" if there are more than 5 entries
  if (mappedData.length > 5) {
    const top5 = mappedData?.slice(0, 4)
    const others = mappedData?.slice(4)

    const othersTotal = others?.reduce((acc, item) => acc + item?.total, 0)
    const othersEntry: ConsentsByKey = {
      name: 'Others',
      total: othersTotal
    }

    top5?.push(othersEntry)
    return top5 || []
  }

  return mappedData || []
}

export const queryConsentManagementComplainceStausDetails = (): string => {
  return gql`
    {
      consentManagementAlertCountGroupByCompliance {
        edges {
          node {
            compliance
            notificationCount
          }
        }
      }
    }
  `
}

export const mapConsentManagementComplainceStausDetails = (raw: any): ConsentsByKey[] => {
  const mappedData = raw?.consentManagementAlertCountGroupByCompliance?.edges?.map((edge) => ({
    name: edge?.node?.compliance,
    total: edge?.node?.notificationCount
  }))

  return mappedData || []
}

export const queryConsentManagementConsentByPrefenrenceCenterList = (): string => {
  return gql`
    {
      preferenceCenter {
        edges {
          node {
            id
            preferenceCenterGroupId
            name
            consentStats {
              edges {
                node {
                  consentStats {
                    total
                    optIn
                    optOut
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapConsentManagementConsentByPrefenrenceCenterList = (raw: any): ConsentsByKey[] => {
  const preferenceCenters = raw.preferenceCenter.edges
  const mappedData: ConsentsByKey[] = []

  preferenceCenters.forEach((edge) => {
    mappedData.push({
      name: edge?.node?.name,
      id: edge?.node?.id,
      preferenceGroupId: edge?.node?.preferenceCenterGroupId,
      total:
        edge?.node?.consentStats?.edges?.reduce(
          (acc, curr) => acc + curr?.node?.consentStats?.total,
          0
        ) || 0,
      consentData: [
        {
          label: ConsentManagementConsent.optIn,
          value:
            edge?.node?.consentStats?.edges?.reduce(
              (acc, curr) => acc + curr?.node?.consentStats?.optIn,
              0
            ) || 0
        },
        {
          label: ConsentManagementConsent.optOut,
          value:
            edge?.node?.consentStats?.edges?.reduce(
              (acc, curr) => acc + curr?.node?.consentStats?.optOut,
              0
            ) || 0
        }
      ]
    })
  })

  mappedData?.sort((a, b) => b.total - a.total)
  return mappedData
}

export const queryConsentManagementConsentCategoriesByPreferenceCenterId = (
  preferenceGroupId?: string
): string => {
  return gql`
    {
      preferenceCenter(filter: [{key: GROUP_ID, values: "${preferenceGroupId}"}]) {
          edges{
            node{
              id
              name
              consentStats{
                edges{
                  node{
                    consentStats {
                      total
                      optIn
                      optOut
                    }
                  }
                }
              }
              preferenceConfig{
                preferenceCategory{
                  edges{
                    node{
                      id
                    }
                  }
                }
              }
            }
          }
      }
      consentStats(
        filter: [
          { key: SOURCE, values: "PREFERENCE_CENTER_CATEGORY" }
          { key: PREFERENCE_CENTER_GROUP_ID, values: "${preferenceGroupId}" }
        ]
      ) {
        edges {
          node {
            id
            preferenceCategory {
              edges {
                node {
                  id
                  name
                }
              }
            }
            consentStats {
              total
              optIn
              optOut
            }
          }
        }
      }
    }
  `
}

export const mapConsentManagementConsentCategoriesByPreferenceCenterId = (
  raw: any
): {
  preferenceCenterCategoryData: ConsentsByKey[]
  preferenceCenterTotalConsentRecordCount: number
} => {
  const mappedData: ConsentsByKey[] = []

  const preferenceCenterTotalConsentRecordCount =
    raw?.preferenceCenter?.edges?.[0]?.node?.consentStats?.edges?.reduce(
      (acc, curr) => acc + curr?.node?.consentStats?.total,
      0
    ) || 0

  const preferenceCenterCategoryIds =
    raw?.preferenceCenter?.edges?.[0]?.node?.preferenceConfig?.map(
      (preference) => preference?.preferenceCategory?.edges[0]?.node?.id
    ) || []

  raw?.consentStats?.edges?.forEach(({ node }) => {
    const preferenceCategory = node?.preferenceCategory?.edges[0]?.node
    if (preferenceCategory) {
      mappedData.push({
        id: preferenceCategory?.id,
        name: preferenceCategory?.name,
        total: node?.consentStats?.total || 0,
        consentData: [
          {
            label: ConsentManagementConsent.optIn,
            value: node?.consentStats?.optIn || 0
          },
          {
            label: ConsentManagementConsent.optOut,
            value: node?.consentStats?.optOut || 0
          }
        ],
        type: preferenceCategory?.id
      })
    }
  })

  mappedData
    .filter((item) => preferenceCenterCategoryIds.includes(item.id))
    ?.sort((a, b) => b.total - a.total)

  // Handle merging into "Others" if there are more than 5 entries
  if (mappedData.length > 5) {
    const top5 = mappedData?.slice(0, 4)
    const others = mappedData?.slice(4)

    const othersTotal = others?.reduce((acc, item) => acc + item.total, 0)
    const othersEntry: ConsentsByKey = {
      name: 'Others',
      total: othersTotal,
      type: 'Various'
    }

    top5?.push(othersEntry)
    return { preferenceCenterCategoryData: top5 || [], preferenceCenterTotalConsentRecordCount }
  }

  return {
    preferenceCenterCategoryData: mappedData || [],
    preferenceCenterTotalConsentRecordCount
  }
}
