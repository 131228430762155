import {
  URL_INSIGHTS,
  URL_LABEL_MANAGEMENT,
  URL_LABEL_SETS,
  URL_LABELS,
  URL_SETTINGS
} from '../../constants'
import { BreadcrumbItem, TabItems } from '@lightbeamai/design-system'
import * as Yup from 'yup'

export const getLabelBreadcrumbs = (intl): BreadcrumbItem[] => [
  {
    path: URL_INSIGHTS + URL_LABEL_MANAGEMENT,
    title: intl.formatMessage({ id: 'menu.main.insights' })
  },
  {
    path: URL_INSIGHTS + URL_LABEL_MANAGEMENT,
    title: intl.formatMessage({ id: 'labels.labelManagement' })
  }
]

export const BasePaths = {
  LabelManagement: `${URL_INSIGHTS}${URL_LABEL_MANAGEMENT}`,
  LabelSettings: `${URL_INSIGHTS}${URL_LABEL_MANAGEMENT}${URL_SETTINGS}`,
  Labels: `${URL_INSIGHTS}${URL_LABEL_MANAGEMENT}${URL_LABELS}`,
  LabelSets: `${URL_INSIGHTS}${URL_LABEL_MANAGEMENT}${URL_LABEL_SETS}`
}

export const getDocumentLabelingTabs = (intl): TabItems => [
  {
    key: URL_LABEL_SETS,
    label: intl.formatMessage({ id: 'labelSets' })
  },
  {
    key: URL_LABELS,
    label: intl.formatMessage({ id: 'labels' })
  }
]

export enum DOCUMENT_LABEL_PROVIDERS {
  lightBeam = 'LIGHTBEAM',
  mip = 'MICROSOFT_INFORMATION_PROTECTION',
  google = 'GOOGLE_LABELS'
}

export enum DocumentLabelProviderConfigurations {
  mip = 'microsoftInformationProtectionConfiguration',
  google = 'googleLabelsConfiguration'
}

export enum DocumentLabelProviderPrefix {
  mip = 'MIP-'
}

export const DocumentLabelProviderValues = {
  id: 'id',
  name: 'name',
  lbName: 'lbName',
  type: 'type',
  clientId: 'clientId',
  clientSecret: 'clientSecret',
  tenantId: 'tenantId',
  delegatedCredential: 'delegatedCredential',
  accountJson: 'accountJson'
}

export const COLORS = [
  '#3C0FBC',
  '#3776BA',
  '#39ABB8',
  '#52A96F',
  '#D93025',
  '#83ACD4',
  '#DB9015',
  '#D528CD',
  '#0070F5',
  '#8D8D8D',
  '#A4ACB6',
  '#CDD2D7'
]

export const LabelSetCreateEditValidationSchema = Yup.object().shape({
  name: Yup.string().required('field.required.error'),
  description: Yup.string().required('field.required.error')
})

export const LabelCreateEditValidationSchema = Yup.object().shape({
  name: Yup.string().required('field.required.error'),
  description: Yup.string(),
  priority: Yup.number().required('field.required.error').min(0),
  setId: Yup.string().required('field.required.error'),
  suffix: Yup.string(),
  color: Yup.string()
})
