import { Typography } from '@lightbeamai/design-system'
import React, { ErrorInfo } from 'react'
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'
import { useIntl } from 'react-intl'

interface IProps {
  children: React.ReactNode
  fallback?: React.ReactElement
  transId?: string
  onError?: (error: Error, errorInfo: ErrorInfo) => void
}

const ErrorBoundary = (props: IProps) => {
  const { children, fallback, transId, onError } = props
  const intl = useIntl()

  const errorFallback: React.ReactElement = fallback ?? (
    <Typography className="xs-p-16 xs-m-0" color="error" type="strong" variant="h6">
      {intl.formatMessage({ id: transId ?? 'errorBoundary.error' })}
    </Typography>
  )

  return (
    <ReactErrorBoundary fallback={errorFallback} {...(onError ? { onError } : {})}>
      {children}
    </ReactErrorBoundary>
  )
}

export default ErrorBoundary
