import './navbar.less'
import { findSelectedKey } from './util'
import { RootState } from '../../rootReducer'
import { logout } from '../../features/login/loginSlice'
import '@lightbeamai/design-system/dist/style.css'
import {
  FILTER_STATUS_KEY,
  URL_ABOUT,
  URL_ACCESS_CONTROL,
  URL_ALERTS,
  URL_ATTRIBUTES,
  URL_CONSENT_MANAGEMENT,
  URL_COOKIES_CONSENT,
  URL_DASHBOARD,
  URL_DATA_SOURCES,
  URL_DEFINED_PROCESSES,
  URL_DOCUMENTS_ALL,
  URL_DOCUMENTS_CLASSIFIED,
  URL_DSR,
  URL_DSR_REQUEST_MANAGEMENT,
  URL_ENTITIES,
  URL_INSIGHTS,
  URL_LABEL_MANAGEMENT,
  URL_OVERVIEW,
  URL_PIA,
  URL_POLICIES,
  URL_PRIVACY_PARTNERS,
  URL_REQUESTS,
  URL_ROPA,
  URL_RULE_SET,
  URL_SETTINGS,
  URL_TEMPLATES,
  URL_TICKETS,
  UserRoles,
  showPrivacyOps
} from '../../constants'
import {
  useThemeContext,
  DARK_THEME,
  LIGHT_THEME
} from '../../features/dashboards/mainDashboardv2/components/ThemeContext'
import { UserDetails } from '../../interfaces'
import IconBell from '../../assets/inlineSvg/icon-bell-notifications.svg'
// import LightBeamIcon from '../../assets/inlineSvg/light-mode.svg'
// import DarkModeIcon from '../../assets/inlineSvg/dark-mode.svg'
import LightBeamWhiteLogo from '../../assets/images/lightbeam-logo-white.png'
import NotificationsWidget from '../../features/notifications/widgetNotifications'
import GlobalSearch from '../../features/globalSearch/globalSearchNavbar'
import WidgetHelp from '../widgetHelp/help'
import { FeatureFlags } from '../../configs/featureFlagSlice'
import { TopNavMenuItem, TopNav } from '@lightbeamai/design-system'
import { NavLink, useLocation } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { Dropdown } from 'semantic-ui-react'
import { connect } from 'react-redux'
import UserAvatar from 'react-user-avatar'
import React, { useEffect, useMemo, useState } from 'react'
import '../../features/menuRight/menuRight.less'

interface IProps {
  userDetails?: UserDetails
  featureFlags: FeatureFlags
  logout: () => void
}

const Navbar = (props: IProps): React.ReactElement => {
  const { userDetails, featureFlags, logout } = props
  const intl = useIntl()
  const { roles = [] } = userDetails || {}
  const isDSO = !!roles.find(({ name }) => name === UserRoles.dso)
  const isAdmin = !!roles.find(({ name }) => name === UserRoles.admin)
  const isDPO = !!roles.find(({ name }) => name === UserRoles.dpo)
  const isDsrAdmin = isDPO || isAdmin

  const isAlertOwner = !!roles.find(({ name }) => name === UserRoles.alertOwner) || false
  const [selectedKey, setSelectedKey] = useState<string>('')
  const location = useLocation()
  // const history = useHistory()

  const { theme, applyTheme } = useThemeContext()

  // Set the theme based on the query params
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const theme = searchParams.get('theme')

    if (theme) {
      applyTheme(theme)
    } else {
      applyTheme(LIGHT_THEME)
    }
  }, [location.search, applyTheme])

  const urlCookieConsent = URL_COOKIES_CONSENT + URL_OVERVIEW

  const menuItems: TopNavMenuItem[] = useMemo(
    () =>
      isAlertOwner
        ? [
            {
              key: URL_POLICIES + URL_ALERTS,
              to: URL_POLICIES + URL_ALERTS,
              label: intl.formatMessage({ id: 'menu.main.myAlerts' })
            }
          ]
        : [
            {
              key: URL_DASHBOARD,
              to: URL_DASHBOARD,
              label: intl.formatMessage({ id: 'menu.main.dashboard' })
            },
            {
              key: URL_DATA_SOURCES,
              to: URL_DATA_SOURCES,
              label: intl.formatMessage({ id: 'menu.main.dataSources' })
            },
            {
              key: URL_POLICIES + URL_RULE_SET,
              to: URL_POLICIES + URL_RULE_SET,
              label: intl.formatMessage({ id: 'menu.main.playbooks' })
            },
            {
              key: URL_INSIGHTS,
              to: URL_INSIGHTS + URL_ENTITIES,
              label: intl.formatMessage({ id: 'menu.main.insights' }),
              children: [
                {
                  key: URL_ATTRIBUTES,
                  to: URL_INSIGHTS + URL_ATTRIBUTES,
                  label: intl.formatMessage({ id: 'menu.filters.attributes' }),
                  capitalizeLabel: false
                },
                {
                  key: URL_DOCUMENTS_ALL,
                  to: URL_INSIGHTS + URL_DOCUMENTS_ALL,
                  label: intl.formatMessage({ id: 'menu.filters.objects' }),
                  capitalizeLabel: false
                },
                {
                  key: URL_LABEL_MANAGEMENT,
                  to: URL_INSIGHTS + URL_LABEL_MANAGEMENT,
                  label: intl.formatMessage({ id: 'menu.filters.labelManagement' }),
                  capitalizeLabel: false
                },
                {
                  key: URL_DOCUMENTS_CLASSIFIED,
                  to: URL_INSIGHTS + URL_DOCUMENTS_CLASSIFIED,
                  label: intl.formatMessage({ id: 'menu.filters.classification' }),
                  capitalizeLabel: false
                },
                {
                  key: URL_TEMPLATES,
                  to: URL_INSIGHTS + URL_TEMPLATES,
                  label: intl.formatMessage({ id: 'menu.filters.templates' }),
                  capitalizeLabel: false
                },
                {
                  key: URL_ENTITIES,
                  to: URL_INSIGHTS + URL_ENTITIES,
                  label: intl.formatMessage({ id: 'menu.filters.entities' }),
                  capitalizeLabel: false
                }
              ]
            },
            ...(showPrivacyOps
              ? [
                  {
                    key: isDSO
                      ? `${
                          URL_ROPA + URL_REQUESTS
                        }?${URL_REQUESTS}=${FILTER_STATUS_KEY}:sent,resent,draft`
                      : URL_ROPA,
                    to: isDSO
                      ? `${
                          URL_ROPA + URL_REQUESTS
                        }?${URL_REQUESTS}=${FILTER_STATUS_KEY}:sent,resent,draft`
                      : URL_ROPA,
                    label: intl.formatMessage({ id: 'menu.main.privacyOps' }),
                    children: [
                      {
                        key: URL_DSR + (isDsrAdmin ? `${URL_DSR_REQUEST_MANAGEMENT}` : URL_TICKETS),
                        to: URL_DSR + (isDsrAdmin ? `${URL_DSR_REQUEST_MANAGEMENT}` : URL_TICKETS),
                        label: intl.formatMessage({ id: 'menu.filters.dsr' }),
                        capitalizeLabel: false
                      },
                      ...(featureFlags?.piaFeature === false
                        ? []
                        : [
                            {
                              key: isDSO
                                ? `${URL_PIA + URL_TICKETS}`
                                : URL_PIA + URL_DEFINED_PROCESSES,
                              to: isDSO
                                ? `${URL_PIA + URL_TICKETS}`
                                : URL_PIA + URL_DEFINED_PROCESSES,
                              label: intl.formatMessage({ id: 'menu.filters.pia' }),
                              capitalizeLabel: false
                            }
                          ]),
                      ...(isDsrAdmin && featureFlags?.cookieConsent !== false
                        ? [
                            {
                              key: urlCookieConsent,
                              to: urlCookieConsent,
                              label: intl.formatMessage({ id: 'menu.filters.cookieConsent' })
                            }
                          ]
                        : []),
                      {
                        key: isDSO ? `${URL_ROPA + URL_TICKETS}` : URL_ROPA + URL_DEFINED_PROCESSES,
                        to: isDSO ? `${URL_ROPA + URL_TICKETS}` : URL_ROPA + URL_DEFINED_PROCESSES,
                        label: intl.formatMessage({ id: 'ropa.menuItem' }),
                        capitalizeLabel: false
                      },
                      ...(featureFlags?.consentManagement
                        ? [
                            {
                              key: URL_CONSENT_MANAGEMENT + URL_OVERVIEW,
                              to: URL_CONSENT_MANAGEMENT + URL_OVERVIEW,
                              label: intl.formatMessage({ id: 'menu.filters.consentManagement' })
                            }
                          ]
                        : [])
                    ]
                  }
                ]
              : []),
            {
              key: URL_ACCESS_CONTROL,
              to: URL_ACCESS_CONTROL,
              label: intl.formatMessage({ id: 'menu.main.accessControl' }),
              children: [
                ...(featureFlags.accessControl
                  ? [
                      {
                        key: URL_ACCESS_CONTROL,
                        to: URL_ACCESS_CONTROL,
                        label: intl.formatMessage({ id: 'menu.main.accessGovernance' })
                      }
                    ]
                  : []),
                {
                  key: URL_PRIVACY_PARTNERS,
                  to: URL_PRIVACY_PARTNERS,
                  label: intl.formatMessage({ id: 'menu.main.privacyPartners' })
                }
              ]
            }
          ],
    [isAlertOwner, featureFlags, intl, isDSO, isDsrAdmin, isDPO, isAdmin, showPrivacyOps]
  )

  const { roles: [userInfo] = [], emailId } = userDetails || {}
  const { name = '' } = userInfo || {}

  const profileImg = name ? (
    <button style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}>
      <UserAvatar size="30" name={name.substring(0, 2)} />
    </button>
  ) : (
    <></>
  )

  useEffect(() => {
    const currentSelected = findSelectedKey(menuItems, location.pathname)

    if (currentSelected) {
      setSelectedKey(currentSelected.key)
    }
  }, [location.pathname, menuItems])

  const ProfileAvatar = ({ name, emailId }) => {
    /** Commented for LA-11217 */
    // const setThemeQueryParams = (newTheme: string) => {
    //   const searchParams = new URLSearchParams(location.search)

    //   searchParams.set('theme', newTheme)

    //   history.push({
    //     search: searchParams.toString()
    //   })
    // }

    return (
      <div className="user-profile-avatar">
        <Dropdown.Menu>
          <Dropdown.Header className="xs-p-24 xs-m-0">
            <p className="xs-mb-12">
              <FormattedMessage id="navbar.menu.option.account" />
            </p>
            <div className="header-content">
              {profileImg}
              <div className="xs-ml-12">
                <p className="user-name xs-mb-4">{name}</p>
                <p className="user-email xs-mb-0">{emailId}</p>
              </div>
            </div>
          </Dropdown.Header>
          {/* <Dropdown.Item className="cursor-pointer">
            <Tabs
              items={[
                {
                  key: 'light-theme',
                  label: 'Light',
                  icon: <LightBeamIcon style={{ width: '12px' }} />
                },
                {
                  key: 'dark-theme',
                  label: 'Dark',
                  icon: <DarkModeIcon style={{ width: '12px' }} />
                }
              ]}
              variant="button"
              value={theme}
              onChange={setThemeQueryParams}
            ></Tabs>
          </Dropdown.Item> */}
          <Dropdown.Item to="/manage-account" as={NavLink}>
            <p className="user-name xs-mb-4">
              <FormattedMessage id="navbar.menu.option.manage-account" />
            </p>
          </Dropdown.Item>
          <Dropdown.Item onClick={logout}>
            <FormattedMessage id="navbar.menu.option.logout" />
          </Dropdown.Item>
        </Dropdown.Menu>
      </div>
    )
  }

  const lightBeamDarkMoreLogo = theme === DARK_THEME ? LightBeamWhiteLogo : undefined
  return (
    <header className="lb-navbar" data-test-id="navbar">
      <TopNav
        logo={lightBeamDarkMoreLogo}
        logoLink="/dashboard"
        menuConfig={menuItems}
        showSearch={false}
        showSetting={true}
        onSettingClick={() => window.location.replace(URL_SETTINGS + URL_ABOUT)}
        menuIcons={[
          <GlobalSearch key={1} />,
          <NotificationsWidget key="notification-icon" text={<IconBell />} />,
          <WidgetHelp key={3} />
        ]}
        user={{
          name: name,
          children: <ProfileAvatar name={name} emailId={emailId} />
        }}
        selectedKey={selectedKey}
        setSelectedKey={setSelectedKey}
        overflowedIndicatorText={intl.formatMessage({ id: 'menu.main.overflowed' })}
      />
    </header>
  )
}

const mapStateToProps = (state: RootState) => ({
  userDetails: state.user.userInfo,
  featureFlags: state.featureFlags
})

const mapDispatchToProps = {
  logout
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar)

// export default Navbar
