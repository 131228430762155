import { FILTER_SKIP_REASON_KEY, FILTER_TYPES, SkippedFileReason } from '../../constants'
import { IntlShape } from 'react-intl'

export const ENTITY_DETAILS_LIMIT = 10

export const getSkippedFilesFilters = ({ intl }: { intl: IntlShape }) => {
  return {
    title: 'filter.tab.skippedFilesReason',
    key: FILTER_SKIP_REASON_KEY,
    valuesData: [
      {
        key: SkippedFileReason.size,
        name: intl.formatMessage({
          id: `filter.tab.skippedFilesReason.${SkippedFileReason.size}`
        })
      },
      {
        key: SkippedFileReason.type,
        name: intl.formatMessage({
          id: `filter.tab.skippedFilesReason.${SkippedFileReason.type}`
        })
      },
      {
        key: SkippedFileReason.downloadError,
        name: intl.formatMessage({
          id: `filter.tab.skippedFilesReason.${SkippedFileReason.downloadError}`
        })
      },
      {
        key: SkippedFileReason.processingError,
        name: intl.formatMessage({
          id: `filter.tab.skippedFilesReason.${SkippedFileReason.processingError}`
        })
      },
      {
        key: SkippedFileReason.passwordProtected,
        name: intl.formatMessage({
          id: `filter.tab.skippedFilesReason.${SkippedFileReason.passwordProtected}`
        })
      },
      {
        key: SkippedFileReason.unknownFileSize,
        name: intl.formatMessage({
          id: `filter.tab.skippedFilesReason.${SkippedFileReason.unknownFileSize}`
        })
      }
    ],
    values: [
      SkippedFileReason.downloadError,
      SkippedFileReason.processingError,
      SkippedFileReason.passwordProtected,
      SkippedFileReason.unknownFileSize
    ],
    type: FILTER_TYPES.discreet
  }
}
