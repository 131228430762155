interface ICodeToStatusMap {
  [key: number]: API_ERROR_STATUS
}

export enum API_ERROR_STATUS {
  BAD_REQUEST = 'BAD_REQUEST',
  UNAUTHORIZED = 'UNAUTHORIZED',
  PAYMENT_REQUIRED = 'PAYMENT_REQUIRED',
  FORBIDDEN = 'FORBIDDEN',
  NOT_FOUND = 'NOT_FOUND',
  METHOD_NOT_ALLOWED = 'METHOD_NOT_ALLOWED',
  CONFLICT = 'CONFLICT',
  UNPROCESSABLE_ENTITY = 'UNPROCESSABLE_ENTITY',
  SERVER_ERROR = 'SERVER_ERROR',
  NETWORK_ERROR = 'NETWORK_ERROR',
  UNHANDLED_ERROR = 'UNHANDLED_ERROR',
  TOO_MANY_REQUESTS = 'TOO_MANY_REQUESTS'
}

export const codeToStatusMap: ICodeToStatusMap = {
  400: API_ERROR_STATUS.BAD_REQUEST,
  401: API_ERROR_STATUS.UNAUTHORIZED,
  402: API_ERROR_STATUS.PAYMENT_REQUIRED,
  403: API_ERROR_STATUS.FORBIDDEN,
  404: API_ERROR_STATUS.NOT_FOUND,
  405: API_ERROR_STATUS.METHOD_NOT_ALLOWED,
  409: API_ERROR_STATUS.CONFLICT,
  422: API_ERROR_STATUS.UNPROCESSABLE_ENTITY,
  429: API_ERROR_STATUS.TOO_MANY_REQUESTS,
  500: API_ERROR_STATUS.SERVER_ERROR,
  504: API_ERROR_STATUS.NETWORK_ERROR
}

export const apiStatusToMessage = {
  [API_ERROR_STATUS.BAD_REQUEST]: 'error.message.badRequest',
  [API_ERROR_STATUS.UNAUTHORIZED]: 'error.message.unauthorized',
  [API_ERROR_STATUS.PAYMENT_REQUIRED]: 'error.message.paymentRequired',
  [API_ERROR_STATUS.FORBIDDEN]: 'error.message.forbidden',
  [API_ERROR_STATUS.NOT_FOUND]: 'error.message.notFound',
  [API_ERROR_STATUS.METHOD_NOT_ALLOWED]: 'error.message.unhandled',
  [API_ERROR_STATUS.CONFLICT]: 'error.message.conflict',
  [API_ERROR_STATUS.UNPROCESSABLE_ENTITY]: 'error.message.unprocessableEntity',
  [API_ERROR_STATUS.TOO_MANY_REQUESTS]: 'error.message.tooManyRequests',
  [API_ERROR_STATUS.SERVER_ERROR]: 'error.message.unhandled',
  [API_ERROR_STATUS.NETWORK_ERROR]: 'error.message.network',
  [API_ERROR_STATUS.UNHANDLED_ERROR]: 'error.message.unhandled',
  OK: 'success.message.ok'
}

export const getMessageByApiStatus = (error = ''): string => {
  return apiStatusToMessage[error] || error
}

const getErrorText = (code?: number): string => {
  if (code && code in codeToStatusMap) {
    return codeToStatusMap[code]
  }

  return API_ERROR_STATUS.UNHANDLED_ERROR
}

export default getErrorText
