import { randHex } from '../../utils/mathUtils'
import { APIResponseMsg } from '../../interfaces'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const DEFAULT_AUTO_HIDE_SEC = 10

export type INotificationParams = APIResponseMsg & {
  autoHideAfterSec?: number
  isPersistent?: boolean
  skipIntl?: boolean
}

export type INotification = INotificationParams & {
  id: string
  values?: Record<string, string | number>
}

interface INotificationState {
  list: INotification[]
}

export const initialState: INotificationState = {
  list: []
}

type NotificationPayload = {
  error?: string
  success?: string
  values?: Record<string, string | number>
  skipIntl?: boolean
}

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotification: (state, { payload }: PayloadAction<NotificationPayload>) => {
      if (!payload.error && !payload.success) return

      const isAlreadyExists = state.list.find((item) => {
        return item.error === payload.error || item.success === payload.success
      })
      if (!isAlreadyExists) {
        state.list.push({
          id: randHex(5),
          autoHideAfterSec: DEFAULT_AUTO_HIDE_SEC,
          ...payload
        })
      }
    },
    removeNotification: (state, action) => {
      state.list = state.list.filter((item) => item.id !== action.payload)
    },
    clearNotification: (state) => {
      state.list = state.list.filter((notification) => notification.isPersistent)
    }
  }
})

export const { addNotification, removeNotification, clearNotification } = notificationSlice.actions

export default notificationSlice.reducer
