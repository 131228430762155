import {
  AlertAuditLog,
  AlertObjectsGroup,
  AlertObjectsGroupParams,
  AlertRuleSetDetails,
  AlertRuleSetDetailsParams,
  AlertsGroupedParams,
  IAlertObjectsImpactedParams,
  IPolicyAlertEntitiesImpacted,
  IPolicyAlertEntitiesImpactedParams,
  IPolicyAlertExternalUser,
  IPolicyAlertExternalUserGrouped,
  IPolicyAlertExternalUserParams,
  PolicyAlertCard,
  AlertActionParams,
  AlertAuditLogsParams,
  RuleSetFilterList,
  FetchRulsetDataSourcesParams,
  ICreateDurationFilterParams,
  UpdateAlertAssignmentParams,
  AlertAuditLogDetailsParams,
  FetchRuleSetParams,
  ICreateDocumentFiltersParams,
  ICreateAccessFilterParams,
  IUpdateDocumentFiltersParams,
  IUpdateAccessFilterParams,
  AttributesBySensitivity,
  SaveArchivalLocationParams,
  ActionHistoryParams,
  ObjectAlertActionHistory,
  SubAlertsActionParams,
  ApplySubAlertActionParams,
  AlertAuditLogActionHistoryParams,
  AuditLogActionHistory,
  InformationExtractionParams
} from './policiesSlice'

import { AlertStatus, getAccessRulesQueryAccessConditions } from './v2/constants'
import {
  IPolicyFilter,
  IGetFiltersParams,
  IProfileMapData,
  IApplyFilterParams,
  ICreateFilterParams,
  ICreatePolicyParams,
  IUpdatePolicyParams,
  ICreateDatasourceFilterParams,
  IPolicy,
  IUpdateFilter,
  IDeletePolicyParams,
  IPageInfo,
  IPolicyListByTypeParam,
  IPolicyFilterProfile,
  IAlertObjectsImpacted,
  IGetPolicyAlertsParams,
  IPolicyAlert,
  FilterParams,
  RulsetInfo,
  IPolicyType,
  IPolicyFilterState,
  IPolicyTypeForPrint,
  ICreateDocumentLabelSetFilterParams,
  DocumentLabelGroup,
  SORT_ORDER
} from '../../interfaces'
import {
  ALERT_ID,
  FILTER_TYPE,
  LIMIT_DEFAULT,
  API_FILTER_ALERTS,
  ALERT_STATUS_API_MAP,
  SEVERITY_API_MAP,
  DATA_SOURCE_TYPE_API_MAP,
  ALERT_STATUS,
  alertIntermediateStatuses,
  DATA_SOURCE_TYPES,
  MAIL_DATASOURCES,
  ALERT_ASSIGMENT_OPTIONS,
  DATA_SOURCE_ID,
  POLICY_TYPES,
  SENSITIVE_LABEL,
  OBJECT_REVOKE_ACCESS_TYPES,
  AUDIT_LOG_OBJECTS_LIMIT_DEFAULT,
  RETENTION_ATTRIBUTE_TYPES,
  SYSTEM_RETENTION_ATTRIBUTE_TYPES
} from '../../constants'
import gqlast from '../../utils/filtersUtil'
import { getSortedPolicyTypes } from '../../utils/policyUtils'
import { FolderInfo, SubAlertDateFilterKey } from '../../services/graphqlSchemaTypes'
import { formatDateTimeString } from '../../utils/timeUtil'
import {
  calculateSkipAndLimit,
  getAfterCursor,
  parameterizeArrayofObjects
} from '../../utils/graphqlUtil'
import { AttributeSet, AttributeSetAttribute } from '../attributes/attributesSlice'
import { sortByOrder } from '../../utils/sortUtil'
import { sortAttributesByInstanceCountAndSensitivity } from '../../utils/attributesUtil'
import { removeDuplicates } from '../../utils'
import { AccessControlAccessMode } from '../accessControl/types'
import { escapeNewlineandTab } from '../../utils/stringUtil'
import {
  AttributeInstanceCountType,
  AttributeSensitivityType,
  FileClassificationType,
  LabelType,
  RulesetType
} from '../../utils/rulesetUtils'
import { gql } from 'graphql-request'

export const queryProfileFilters = (): string => gql`
  {
    filters(type: PROFILE_FILTERS) {
      count
      edges {
        node {
          id
          name
          owner
          createdAt
          configuration {
            ... on ProfileFilters {
              conditions {
                conditionLabel {
                  edges {
                    node {
                      id
                      labelDisplayName
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const queryPolicyAlertsTotals = (params: IGetPolicyAlertsParams): string => {
  const {
    datasourceId: datasourceIds,
    severity,
    datasourceType,
    policyType,
    partnerIds,
    subAlertAssignees = [],
    ruleSetIds
  } = params

  const basicFilters: FilterParams = { filter: [] }
  if (datasourceType && basicFilters?.filter) {
    basicFilters.filter?.push({
      key: API_FILTER_ALERTS.DATA_SOURCE_TYPE,
      values: datasourceType?.split(',')?.map((ds) => DATA_SOURCE_TYPE_API_MAP[ds]) || ''
    })
  }
  if (severity && basicFilters?.filter) {
    basicFilters.filter?.push({
      key: API_FILTER_ALERTS.SEVERITY,
      values: severity?.split(',')?.map((s) => SEVERITY_API_MAP[s]) || ''
    })
  }
  if (policyType && basicFilters?.filter) {
    basicFilters.filter?.push({
      key: API_FILTER_ALERTS.POLICY_TYPE,
      values: policyType?.split(',') || []
    })
  }
  if (partnerIds && partnerIds?.length && basicFilters?.filter) {
    basicFilters.filter?.push({
      key: API_FILTER_ALERTS.PARTNER_ID_KEY,
      values: partnerIds
    })
  }
  if (ruleSetIds && ruleSetIds?.length && basicFilters?.filter) {
    basicFilters.filter?.push({
      key: API_FILTER_ALERTS.POLICY_INSTANCE,
      values: ruleSetIds?.split(',') || ''
    })
  }
  const activeFilter = {
    key: API_FILTER_ALERTS.STATUS,
    values: [
      ALERT_STATUS_API_MAP[ALERT_STATUS.active],
      ...alertIntermediateStatuses.map((status) => ALERT_STATUS_API_MAP[status])
    ]
  }
  const resolvedFilter = {
    key: API_FILTER_ALERTS.STATUS,
    values: ALERT_STATUS_API_MAP[ALERT_STATUS.resolved]
  }
  const idString = datasourceIds ? `datasourceIds: "${datasourceIds}"` : ''
  let commonAllParamString = idString
  let commonActiveParamString = idString
  let commonResolvedParamString = idString

  const allFiltersString = parameterizeArrayofObjects(basicFilters.filter || [])
  const activeFiltersString = parameterizeArrayofObjects([
    ...(basicFilters.filter || []),
    activeFilter
  ])
  const resolvedFiltersString = parameterizeArrayofObjects([
    ...(basicFilters.filter || []),
    resolvedFilter
  ])

  if (allFiltersString) {
    commonAllParamString += `filter:${allFiltersString}`
  }
  if (activeFiltersString) {
    commonActiveParamString += `filter:${activeFiltersString}`
  }
  if (resolvedFiltersString) {
    commonResolvedParamString += `filter:${resolvedFiltersString}`
  }

  const filterAssignee =
    subAlertAssignees.length > 0 ? `subAlertAssignees: ${JSON.stringify(subAlertAssignees)}` : ''

  return gql`
    {
      alertsActive: alert(${commonActiveParamString}, first: 1, ${filterAssignee}) {
        count
      }
      alertsAll: alert(${commonAllParamString}, first: 1, ${filterAssignee}) {
        count
      }
      alertsResolved: alert(${commonResolvedParamString}, first: 1, ${filterAssignee}) {
        count
      }
    }
  `
}

export const mapQueryPolicyAlertsTotals = (
  raw: any
): { activeCount: number; resolvedCount: number; allCount: number } => {
  return {
    allCount: raw?.alertsAll?.count || 0,
    resolvedCount: raw?.alertsResolved?.count || 0,
    activeCount: raw?.alertsActive?.count || 0
  }
}

export const queryPolicyAlerts = (params: IGetPolicyAlertsParams): string => {
  const {
    page,
    datasourceId: datasourceIds,
    severity,
    datasourceType,
    alertStatus,
    policyType,
    partnerIds,
    subAlertAssignees = [],
    ruleSetIds
  } = params

  const cursor = getAfterCursor(page, LIMIT_DEFAULT)

  const filters: FilterParams = { filter: [] }
  if (datasourceType && filters?.filter) {
    filters.filter?.push({
      key: API_FILTER_ALERTS.DATA_SOURCE_TYPE,
      values: datasourceType?.split(',')?.map((ds) => DATA_SOURCE_TYPE_API_MAP[ds]) || ''
    })
  }
  if (ruleSetIds && filters?.filter) {
    filters.filter?.push({
      key: API_FILTER_ALERTS.POLICY_INSTANCE,
      values: ruleSetIds?.split(',') || ''
    })
  }
  if (severity && filters?.filter) {
    filters.filter?.push({
      key: API_FILTER_ALERTS.SEVERITY,
      values: severity?.split(',')?.map((s) => SEVERITY_API_MAP[s]) || ''
    })
  }
  if (alertStatus && filters?.filter) {
    filters.filter?.push({
      key: API_FILTER_ALERTS.STATUS,
      values: alertStatus?.map((a) => ALERT_STATUS_API_MAP[a]) || ''
    })
  }
  if (policyType && filters?.filter) {
    filters.filter?.push({
      key: API_FILTER_ALERTS.POLICY_TYPE,
      values: policyType?.split(',') || []
    })
  }
  if (partnerIds && partnerIds?.length && filters?.filter) {
    filters.filter?.push({
      key: API_FILTER_ALERTS.PARTNER_ID_KEY,
      values: partnerIds
    })
  }

  let commonParamString = datasourceIds ? `datasourceIds: "${datasourceIds}"` : ''
  const filterString = parameterizeArrayofObjects(filters?.filter || [])
  if (filterString) {
    commonParamString += `,filter:${filterString}`
  }

  const filterAssignee =
    subAlertAssignees?.length > 0 ? `subAlertAssignees: ${JSON.stringify(subAlertAssignees)}` : ''

  return gql`
    {
      alert(${commonParamString}, first: ${LIMIT_DEFAULT}, after: "${cursor}", ${filterAssignee}) {
        count
        edges {
          node {
            id
            name
            severity
            generatedAt
            lastModifiedTime
            status
            subAlertAssignees(limit: 2) {
              totalCount
              assignees
            }
            objectCount
            ${!filterAssignee ? 'entities { count }' : ''}
            datasource {
              edges {
                node {
                  id
                  type
                  name
                }
              }
            }
            policy {
              edges {
                node {
                  policyType {
                    edges {
                      node {
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapQueryPolicyAlerts = (
  raw: any
): {
  list: IPolicyAlert[]
  total: number
} => {
  const { edges, count } = raw?.alert

  const list = edges?.map(({ node: alert }) => ({
    id: alert?.id,
    name: alert?.name,
    severity: alert?.severity,
    status: alert?.status,
    assignees: alert?.subAlertAssignees?.assignees,
    assigneesCount: alert?.subAlertAssignees?.totalCount,
    entitiesCount: alert?.entities?.count ?? 0,
    objects: alert?.objectCount,
    createdAt: alert?.generatedAt ? formatDateTimeString(alert?.generatedAt) : '',
    lastModified: alert?.lastModifiedTime,
    dataSource: alert?.datasource?.edges[0]?.node?.name || '',
    dataSourceId: alert?.datasource?.edges[0]?.node?.id || '',
    dataSourceType: alert?.datasource?.edges[0]?.node?.type || '',
    policyName: alert?.policy?.edges[0]?.node?.policyType?.edges[0]?.node?.name
  }))

  return { list, total: count }
}

export const queryPolicyAlertCards = (params: AlertsGroupedParams): string => {
  const { filters, subAlertAssignees = [] } = params
  let filtersParamString = ''
  const filterString = filters?.filter?.length
    ? parameterizeArrayofObjects(filters?.filter || [])
    : ''
  if (filterString) {
    filtersParamString += `filter:${filterString}`
  }
  const filterAssignee =
    subAlertAssignees?.length > 0 ? `subAlertAssignees: ${JSON.stringify(subAlertAssignees)}` : ''

  return gql`
    {
      alertGroupByDatasource(first: 999, ${filtersParamString}, ${filterAssignee}) {
        edges {
          node {
            datasourceType
            datasource {
              count
            }
            ${!filterAssignee ? 'entitiesImpactedCount' : ''}
            objectCount
            alertCount
            policyViolatedCount
          }
        }
      }
    }
  `
}

export const mapQueryRuleSetFilterList = (raw: any): RuleSetFilterList[] => {
  try {
    return raw?.policy?.edges?.map(({ node }) => ({
      id: node?.id,
      name: node?.name
    }))
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryRuleSetFilterList = () => gql`
  {
    policy {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

export const mapQueryPolicyAlertCards = (raw: any): PolicyAlertCard[] => {
  const cards = raw?.alertGroupByDatasource?.edges?.map(({ node: item }) => ({
    dataSourceType: item?.datasourceType,
    dataSourceName: item?.datasource,
    entitiesCount: item?.entitiesImpactedCount ?? 0,
    documentsCount: item?.objectCount,
    alertsCount: item?.alertCount,
    policiesCount: item?.policyViolatedCount
  }))

  return cards
}

export const queryPolicyFilters = (params: IGetFiltersParams): string => gql`
  {
    filters(type: ${params[FILTER_TYPE]}) {
      count
      edges {
        node {
          id
          name
          owner
          createdAt
        }
      }
    }
  }
`
export const queryPolicyFilterByIds = (ids: string[]): string => {
  const idsStrArr = ids?.map((id) => '"' + id + '"')
  return gql`
  {
    filters(filterIds: [${idsStrArr?.join(',')}]) {
      edges {
        node {
          id
          name
          type
          configuration {
            ... on ProfileFilters {
              conditions {
                conditionLabel {
                  edges {
                    node {
                      id
                      labelDisplayName
                    }
                  }
                }
              }
            }
            ... on DocumentLabelSetFilters {
              documentLabelSets {
                documentLabelSetId
              }
            }
            ... on TimeBasedFilters {
              valueType
              value
              attributeType
              attributeValue {
                ...on MetadataValueOutput{
                  metadataId
                }
              }
            }
            ... on DocumentLabelsFilters {
              operator
              groups {
                operator
                filterLabels: documentLabels {
                  labelId
                  labelSetId
                }
              }
            }
            ... on DatasourceFilters {
              datasource {
                edges {
                  node {
                    id
                    name
                    type
                    createdBy
                  }
                }
              }
              datasourceWithScanConditions {
                datasourceId
                scanConditions {
                  ...on UnstructuredDatasourcePolicyScanConditionOutput {
                    filterType
                    objectType
                    objectValues
                    objectMetadata {
                      id
                      name
                    }
                  }
                }
              }
            }
            ... on DocumentFiltersOutput {
              operator
              groups {
                operator
                type
                attributeInstanceCounts {
                  attributeId
                  minCount
                  maxCount
                }
                documentLabels {
                  labelId
                  labelSetId
                }
                attributeSensitivityCounts {
                  attributeSensitivityLabel
                  minCount
                }
                fileClassificationSet {
                  fileClass
                  fileSubClass
                }
              }
            }
            ... on AccessFiltersOutput {
              accessRules{
                accessType{
                  type
                  subType
                }
                accessConditions{
                  operator
                  conditions {
                    conditionType
                    conditionArg{
                      ... on AccessTypeArgOutput {
                        accessTypeOp
                        accessTypeValues{
                          accessMode
                        }
                      }
                      ... on AccessorAttributeArgOutput {
                        accessorAttributeOp
                        attributeType
                      }
                      ... on AccessorAggregationArgOutput {
                        accessorAggregationConditionOp
                        aggregationOp
                        conditionValues
                      }
                    }
                  }
                }
              }
            }
            ... on AttributeSetFilters {
              attributeSet {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
}

export const queryApplyFilterCondtion = (params: IApplyFilterParams): string => gql`
  {
    profileMaps(id:"${params?.id}", ${
  params?.conditions?.length > 0 ? `conditions: "${params?.conditions}"` : ''
}) {
      count
      edges {
        node {
          profileMapData
          mapping {
            sourceField
            isSensitive
            attribute
            confidence
          }
        }
      }
    }
  }
`

export const queryDataSourcesbyFilterIds = ({
  filterIds = []
}: FetchRulsetDataSourcesParams): string => {
  const idsStrArr = filterIds?.map((id) => '"' + id + '"')
  return gql`
  {
    filters(type: DATA_SOURCE_FILTERS, filterIds: [${idsStrArr?.join(',')}]) {
      edges {
        node {
          id
          configuration {
            ... on DatasourceFilters {
              datasource {
                edges {
                  node {
                    id
                    name
                    type
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
}

export const mapQueryDataSourcesbyFilterIds = (raw: any): any => {
  return (
    raw?.filters?.edges[0]?.node?.configuration?.datasource?.edges?.map(({ node }) => node) || []
  )
}

export const queryProfileMapData = (profileMapId: string): string => gql`
  {
    profileMaps(id:"${profileMapId}") {
      count
      edges {
        node {
          profileMapData
          mapping {
            sourceField
            isSensitive
            attribute
            confidence
            sourceFieldIndex
          }
          fileName
          totalRecordCount
          filteredRecordCount
        }
      }
    }
  }
`

export const queryCreateFilter = (params: ICreateFilterParams): any => {
  const query = gqlast`mutation {
    createFilter( clientMutationId: ${params?.profileMapId}
    filterData: {
      name: ${params?.name}
      owner: "admin"
      description: ""
      type: PROFILE_FILTERS
      configuration: {
        profileFilters: {
          profileMapId: ${params?.profileMapId}
          conditions: ${params?.conditions}
        }
      }
    })
    {
      clientMutationId
    }}
  `

  return gql`
    ${query}
  `
}

export const queryUpdateProfileMap = (params: any): any => {
  const query = gqlast`mutation {
    updateProfileMap( id: ${params?.profileMapId}
    clientMutationId: ${params?.profileMapId}
    mappingInput: ${params?.columns}
  )
    {
      profileMap {
        edges{
          node{
            mapping{
              sourceField
              isSensitive
              attribute
              confidence
            }
          }
        }
      }
    }}
  `
  return gql`
    ${query}
  `
}

export const queryPolicyTypes = (): string => gql`
  {
    policyType {
      edges {
        node {
          name
          id
          description
          classification
          alerts(first: 1, filter: { key: STATUS, values: "${AlertStatus.active}" }) {
            count
          }
          policy {
            count
          }
        }
      }
    }
  }
`

export const queryPoliciesTypeCards = (): string => gql`
  {
    policyType {
      edges {
        node {
          id
          name
          classification
          description
          policy {
            count
          }
          datasources {
            count
          }
          alerts(first: 1, filter: { key: STATUS, values: "Active" }) {
            count
          }
          permittedObjects {
            count
          }
          permittedEntitiesCount
          permittedAttributesCount
        }
      }
    }
  }
`

export const mapQueryPoliciesTypeCards = (raw: any): IPolicyType[] => {
  const { edges } = raw?.policyType
  const policyTypes: IPolicyType[] = []

  edges?.forEach((edge) => {
    const { node } = edge
    policyTypes?.push({
      name: node?.name,
      id: node?.id,
      prefix: node?.classification,
      description: node?.description,
      policiesCount: node?.policy?.count || 0,
      datasourcesCount: node?.datasources?.count || 0,
      alertsCount: node?.alerts?.count || 0,
      permittedObjectsCount: node?.permittedObjects?.count || 0,
      permittedAttributesCount: node?.permittedAttributesCount || 0,
      permittedEntitiesCount: node?.permittedEntitiesCount || 0
    })
  })

  return getSortedPolicyTypes(policyTypes)
}

export const queryRulesets = (params?: FetchRuleSetParams): string => {
  let dataSourceIdFilter = ''
  if (params?.[DATA_SOURCE_ID]) {
    dataSourceIdFilter = `datasourceIds : ${JSON.stringify([params[DATA_SOURCE_ID]])}, `
  }

  return gql`
    {
      policy {
        count
        edges {
          node {
            id
            name
            status
            alert(first: 1, ${dataSourceIdFilter} filter: { key: STATUS, values: "Active" }) {
              count
            }
            policyType {
              edges {
                node {
                  id
                  classification
                  name
                }
              }
            }
            createdAt
            permittedObjects {
              count
            }
            permittedEntitiesCount
            permittedAttributesCount
          }
        }
      }
    }
  `
}

export const mapQueryRulesets = (raw: any): { list: RulsetInfo[]; total: number } => {
  try {
    const list: RulsetInfo[] = raw?.policy?.edges?.map(({ node }) => ({
      id: node?.id,
      name: node?.name,
      status: node?.status,
      type: node?.policyType?.edges[0]?.node?.name,
      prefix: node?.policyType?.edges[0]?.node?.classification,
      policyTypeId: node?.policyType?.edges[0]?.node?.id,
      createdAt: node?.createdAt,
      permittedEntitiesCount: node?.permittedEntitiesCount || 0,
      alertsCount: node?.alert?.count || 0,
      permittedAttributesCount: node?.permittedAttributesCount || 0,
      permittedObjectsCount: node?.permittedObjects?.count || 0
    }))

    return { list, total: raw?.policy?.count || 0 }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryRulesetsForPrint = (): string => gql`
  {
    policy {
      edges {
        node {
          id
          name
          policyFilters {
            filterIds
          }
          policyType {
            edges {
              node {
                name
                classification
              }
            }
          }
          alert(first: 100) {
            edges {
              node {
                objectCount
                entities {
                  count
                }
              }
            }
          }
        }
      }
    }
  }
`
export const mapQueryRulesetsForPrint = (raw: any): IPolicyTypeForPrint[] => {
  try {
    const { edges } = raw?.policy
    const policies: IPolicyTypeForPrint[] = []

    edges?.forEach((edge) => {
      const { node } = edge
      const policyType = node?.policyType?.edges[0]?.node
      policies?.push({
        id: node?.id,
        name: node?.name,
        filterIds: node?.policyFilters ? node?.policyFilters[0]?.filterIds : [],
        prefix: policyType?.classification,
        type: policyType?.name,
        objectsCount:
          node?.alert?.edges?.reduce((acc, { node }) => {
            return acc + node?.objectCount
          }, 0) || 0,
        entitiesCount:
          node?.alert?.edges?.reduce((acc, { node }) => {
            return acc + node?.entities?.count
          }, 0) || 0
      })
    })
    return policies?.filter(({ objectsCount = 0 }) => objectsCount > 0)
  } catch (e) {
    console.error(e)
    return []
  }
}
export const queryPoliciesListByType = ({
  first,
  after = '',
  policyType
}: IPolicyListByTypeParam): string => {
  const paginationParams = first ? `first: ${first}, after: "${after}",` : ''
  const policyTypeParam = policyType ? `type: ${policyType}` : ''
  return gql`
    {
      policy(
        ${paginationParams}
        ${policyTypeParam}
      ) {
        count
        edges {
          node {
            id
            name
            status
            alert(first: 1, filter: { key: STATUS, values: "Active" }) {
            count
          }
            createdAt
            permittedObjects {
              count
            }
            permittedEntitiesCount
            permittedAttributesCount
          }
        }
      }
    }
  `
}

export const queryCreatePolicy = ({
  name,
  description = '',
  policyType,
  regulations,
  policyFilterIds,
  profileIds,
  datasourceActionId,
  severity,
  generateAlertSettings,
  notifyUsers,
  flagOnlySourceOfTruthVerified,
  alertAssignment,
  enableAlerts,
  policyFrequencyConfiguration
}: ICreatePolicyParams): any => {
  const hasConditionsString = generateAlertSettings?.length
  const conditionsString = hasConditionsString
    ? generateAlertSettings?.reduce(
        (conditions, { sensitivity, attributeCount, conditionOperator }, i) =>
          conditions +
          `${i > 0 ? ',' : ''}{sensitivity: ${sensitivity}, attributeCount: ${attributeCount} ${
            conditionOperator ? `, conditionOperator: ${conditionOperator}` : ''
          }}`,
        ''
      )
    : ''

  let alertsAndRegulations =
    policyType == 'DOCUMENT_LABELING_POLICY' || !regulations?.length
      ? ''
      : `regulations: [${regulations?.map((regulation) => regulation)}]
      `
  alertsAndRegulations += `
      notifyAction: {
          raiseAnAlert: ${enableAlerts || false}
        }
    `
  const policyFrequencyConfigurationFragment = policyFrequencyConfiguration
    ? `policyFrequencyConfiguration: {
        type: ${policyFrequencyConfiguration?.type}
        value: ${policyFrequencyConfiguration?.value}
        startFrom: "${policyFrequencyConfiguration?.startFrom}"
        runOn: {
          ${
            policyFrequencyConfiguration?.runOn?.day
              ? `day: ${policyFrequencyConfiguration?.runOn?.day}`
              : ''
          }
          ${
            policyFrequencyConfiguration?.runOn?.type
              ? `type: ${policyFrequencyConfiguration?.runOn?.type}`
              : ''
          }
        }
      }`
    : ''

  return gql`mutation {
    createPolicy( clientMutationId: "1"
      policyData: {
        name: "${name}"
        description: "${description}"
        policyType: ${policyType}
        notifyUsers: ${JSON.stringify(notifyUsers)}
        owner: "admin"
        policyFilters:[{
          filterIds:  ${JSON.stringify(policyFilterIds)}
          profileIds: ${JSON.stringify(profileIds)}
        }]
        policyActions: ${datasourceActionId ? `["${datasourceActionId}"]` : '[]'}
        severity: ${severity}
        attributeCondition: [${conditionsString}]
        flagOnlySourceOfTruthVerified:${flagOnlySourceOfTruthVerified}
        ${alertsAndRegulations}
        ${policyFrequencyConfigurationFragment}
        ${
          alertAssignment
            ? `
            subAlertAssignmentStrategy: {
              globalStrategy: ${alertAssignment}
            }
          `
            : ''
        }

      }
    )
    {
      clientMutationId
    }}
  `
}

export const queryUpdatePolicy = ({
  id,
  policyType,
  description = '',
  severity,
  regulations,
  policyFilterIds,
  profileIds,
  generateAlertSettings,
  notifyUsers,
  datasourceActionId,
  flagOnlySourceOfTruthVerified,
  enableAlerts,
  policyFrequencyConfiguration
}: IUpdatePolicyParams): any => {
  const hasConditionsString = generateAlertSettings?.length
  const conditionsString = hasConditionsString
    ? generateAlertSettings?.reduce(
        (conditions, { sensitivity, attributeCount, conditionOperator }, i) =>
          conditions +
          `${i > 0 ? ',' : ''}{sensitivity: ${sensitivity}, attributeCount: ${attributeCount} ${
            conditionOperator ? `, conditionOperator: ${conditionOperator}` : ''
          }}`,
        ''
      )
    : ''
  let alertsAndRegulations =
    policyType == 'DOCUMENT_LABELING_POLICY' || !regulations?.length
      ? ''
      : `regulations: [${regulations?.map((regulation) => regulation)}]
      `
  alertsAndRegulations += `
      notifyAction: {
          raiseAnAlert: ${enableAlerts || false}
        }
    `

  const policyFrequencyConfigurationFragment = policyFrequencyConfiguration
    ? `policyFrequencyConfiguration: {
          type: ${policyFrequencyConfiguration?.type}
          value: ${policyFrequencyConfiguration?.value}
          startFrom: "${policyFrequencyConfiguration?.startFrom}"
          runOn: {
            ${
              policyFrequencyConfiguration?.runOn?.day
                ? `day: ${policyFrequencyConfiguration?.runOn?.day}`
                : ''
            }
            ${
              policyFrequencyConfiguration?.runOn?.type
                ? `type: ${policyFrequencyConfiguration?.runOn?.type}`
                : ''
            }
          }
        }`
    : ''

  return gql`
  mutation {
    updatePolicy(
      clientMutationId: "updatePolicy"
      id: "${id}"
      policyData: {
        owner: "admin"
        severity: ${severity}
        description: "${description}"
        attributeCondition: [${conditionsString}]
        notifyUsers: ${JSON.stringify(notifyUsers)}
        flagOnlySourceOfTruthVerified:${flagOnlySourceOfTruthVerified}
        policyFilters: [{
          filterIds: ${JSON.stringify(policyFilterIds)}
          profileIds: ${JSON.stringify(profileIds)}
        }]
        policyActions: ${datasourceActionId ? `["${datasourceActionId}"]` : '[]'}
        ${alertsAndRegulations}
        ${policyFrequencyConfigurationFragment}
      }
    ){
      clientMutationId
    }
  }
`
}
export const queryDeletePolicy = (params: IDeletePolicyParams): any => gql`
  mutation {
    deletePolicy(
      clientMutationId: "deletePolicy"
      id: "${params?.id}"
    ){
      isDeleted
    }
  }
`

export const queryCreateDatasourceFilter = ({
  datasourceIds,
  anyDatasource,
  scanConditions
}: ICreateDatasourceFilterParams): any => {
  let scanConditionsFragment = ``
  if (scanConditions?.length) {
    const getConditionString = (condition) => `{
      unstructuredDatasourcePolicyScanConditions: {
        filterType: ${condition?.filterType},
        objectType: ${condition?.objectType},
        objectValues: ${JSON.stringify(condition?.objectValues)}
        objectMetadata: [${condition?.objectMetadata
          ?.map((meta) => `{ id: "${meta?.id}" name: "${meta?.name}" }`)
          ?.join(',')}]
      }
    }`
    scanConditionsFragment = scanConditions
      ?.map(
        (condition) => `
          {
            datasourceId: "${condition?.datasourceId}"
            scanConditions: [
              ${condition?.scanConditions
                ?.map((condition) => getConditionString(condition))
                ?.join(',')}
            ]
          }
        `
      )
      ?.join(',')
  }

  const dataSourcesInfo = anyDatasource
    ? 'conditions: [{filterExpression: "ANY"}]'
    : `datasourceIds: ${JSON.stringify(datasourceIds)}`
  const fragment = scanConditions?.length
    ? `
datasourceWithScanConditions: [${scanConditionsFragment}]
`
    : ''

  return gql`mutation {
    createFilter( clientMutationId: ""
    filterData:{
      name:"Datasource filter-${Math.random()}",
      owner:"Admin",
      description: "This is test filter."
      type:DATA_SOURCE_FILTERS,
      configuration:{
        datasourceFilters: {
          ${fragment}
          ${dataSourcesInfo}
        }
      }
    })
    {
      filter {
        edges {
          node {
            id
          }
        }
      }
    }}
  `
}

export const queryCreateDocumentLabelSetsFilter = ({
  documentLabelSetId
}: ICreateDocumentLabelSetFilterParams): any => {
  return gql`mutation {
    createFilter( clientMutationId: ""
    filterData:{
      name:"Document label set filter-${Math.random()}",
      owner:"Admin",
      description: "This is test filter."
      type:DOCUMENT_LABEL_SET_FILTERS,
      configuration:{
        documentLabelSetFilters: {
          documentLabelSets: [{
            documentLabelSetId: "${documentLabelSetId}",
          }
      ]
    }
      }
    })
    {
      filter {
        edges {
          node {
            id
          }
        }
      }
    }}
  `
}

export const mutationCreateDurationFilter = ({
  valueType,
  value,
  attributeType
}: ICreateDurationFilterParams): any => {
  const attrFragment =
    attributeType && SYSTEM_RETENTION_ATTRIBUTE_TYPES.includes(attributeType)
      ? `attributeType: ${attributeType}`
      : `
    attributeType: METADATA_ID
    metadataValueInput: {
      metadataId: "${attributeType}"
    }
  
  `
  return gql`
      mutation {
        createFilter(
          clientMutationId: ""
          filterData: {
            name: "DeleteTimeBasedFilter-${Math.random()}"
            owner: "Admin"
            description: "This is test filter."
            type: TIME_BASED_FILTERS
            configuration: {
              timeBasedFilters: {
                ${attrFragment}
                retentionType: DELETE_ONLY
                valueType: ${valueType}
                value: "${value}"
              }
            }
          }
        ) {
          filter {
            edges {
              node {
                id
              }
            }
          }
        }
      }
  `
}
const getDocumentLabelsGroupFragment = (groups) => {
  return groups
    ?.map(
      (group) => `{
        operator: ${group?.operator}
        documentLabels: [
          ${group?.documentLabels?.map(
            (label) => `{ labelId: "${label?.labelId}", labelSetId: "${label?.labelSetId}" }`
          )}
        ]
      }`
    )
    ?.join(',')
}
export const mutationCreateDocumentLabelFilter = (params: DocumentLabelGroup): any => {
  return gql`
      mutation {
        createFilter(
          clientMutationId: ""
          filterData: {
            name: "DocumentLabelFilter-${Math.random()}"
            owner: "Admin"
            description: "This is test filter."
            type: DOCUMENT_LABELS_FILTERS
            configuration: {
              documentLabelsFilters: {
                operator: ${params?.operator}
                groups: [${getDocumentLabelsGroupFragment(params?.groups)}]
              }
            }
          }
        ) {
          filter {
            edges {
              node {
                id
              }
            }
          }
        }
      }
  `
}

const getGroupsFragment = (
  group: AttributeSensitivityType | AttributeInstanceCountType | FileClassificationType | LabelType
) => {
  let fragment = ''
  switch (group?.type) {
    case RulesetType.attributeInstanceCount:
      fragment = `attributeInstanceCounts:[${group?.attributeInstanceCounts
        ?.map(
          (item) =>
            `{attributeId:"${item?.attributeId}", minCount:${item?.minCount}, ${
              item?.maxCount ? `maxCount:${item?.maxCount}` : ''
            }}`
        )
        ?.join(',')}]`
      break
    case RulesetType.attributeSensitivity:
      fragment = `attributeSensitivityCounts:[${group?.attributeSensitivityCounts
        ?.map(
          (item) =>
            `{attributeSensitivityLabel:${item?.attributeSensitivityLabel}, minCount:${item?.minCount}}`
        )
        ?.join(',')}]`
      break
    case RulesetType.classification:
      fragment = `fileClassificationSet:[${group?.fileClassificationSet
        ?.map(
          (item) => `{
        fileClass:"${item?.fileClass}",
        fileSubClass:"${item?.fileSubClass}",
      }`
        )
        ?.join(',')}]`
      break
    case RulesetType.label: {
      fragment = `documentLabels:[${group?.documentLabels
        ?.map(
          (set) => `{
          labelId:"${set?.labelId}",
          labelSetId:"${set?.labelSetId}"
        }`
        )
        ?.join(',')}]`
      break
    }
  }
  return fragment
}

const getDocumentFiltersGroupFragment = (groups) => {
  return groups
    ?.map(
      (group) => `{
        operator: ${group.operator}
        type: ${group.type}
        ${getGroupsFragment(group)}
      }`
    )
    ?.join(',')
}

export const mutationCreateDocumentFilter = (params: ICreateDocumentFiltersParams): any => {
  return gql`
    mutation {
      createFilter(
        clientMutationId: ""
        filterData: {
          name: "Document filter-${Math.random()}"
          owner: "Admin"
          description: "This is test filter."
          type: DOCUMENT_FILTERS
          configuration: {
            documentFilters: {
              operator: ${params?.operator}
              groups: [${getDocumentFiltersGroupFragment(params?.groups)}]
            }
          }
        })
        {
          filter {
            edges {
              node {
                id
              }
            }
          }
        }
    }
  `
}

export const mutationUpdateDocumentFilter = (params: IUpdateDocumentFiltersParams): any => {
  return gql`
    mutation {
      updateFilter(
        clientMutationId: ""
        id: "${params?.id}"
        filterData: {
          type: DOCUMENT_FILTERS
          configuration: {
            documentFilters: {
              operator: ${params?.operator}
              groups: [${getDocumentFiltersGroupFragment(params?.groups)}]
            }
          }
        })
        {
          filter {
            edges {
              node {
                id
              }
            }
          }
        }
    }
  `
}

const getAccessRulesForOpenAccess = (params: ICreateAccessFilterParams) => {
  if (!params?.accessSubTypes?.length) return ``

  return params?.accessSubTypes
    ?.map((subType) => {
      return `
      {
        accessType: {
          type: ${params?.accessType}
          subType: ${subType}
        }
        accessConditions: ${getAccessRulesQueryAccessConditions(
          subType,
          params?.excessiveAccessUserCount
        )}
      }
    `
    })
    ?.join('')
}

const accessControlFilterConfigurationFragment = (params: ICreateAccessFilterParams) => {
  return gql`
    configuration: {
      accessFilters: {
        accessRules: [${getAccessRulesForOpenAccess(params)}]
    }
  }`
}

export const mutationCreateAccessFilter = (params: ICreateAccessFilterParams): any => {
  return gql`
    mutation {
      createFilter(
        clientMutationId: ""
        filterData: {
        name: "Access filter-${Math.random()}"
        type: ACCESS_FILTERS
        owner: "Admin"
        ${accessControlFilterConfigurationFragment(params)}
      })
        {
          filter {
            edges {
              node {
                id
              }
            }
          }
        }
    }
  `
}

export const mutationUpdateAccessFilter = (params: IUpdateAccessFilterParams): any => {
  return gql`
    mutation {
      updateFilter(
        clientMutationId: "",
        id: "${params?.id}",
        filterData: {
        type: ACCESS_FILTERS
        ${accessControlFilterConfigurationFragment(params)}
      })
        {
          filter {
            edges {
              node {
                id
              }
            }
          }
        }
    }
  `
}

export const mutationUpdateDocumentLabelFilter = (params: {
  filterData: DocumentLabelGroup
  id: string
}): any => {
  return gql`
      mutation {
        updateFilter(
          clientMutationId: "updateDocumentLabelFilter",
          id: "${params?.id}"
          filterData: {
            type: DOCUMENT_LABELS_FILTERS
            configuration: {
              documentLabelsFilters: {
                operator: ${params?.filterData?.operator}
                groups: [${getDocumentLabelsGroupFragment(params?.filterData?.groups)}]
              }
            }
          }
        ) {
          filter {
            edges {
              node {
                id
              }
            }
          }
        }
      }
  `
}

export const mutationUpdateAlertAssignment = (params: UpdateAlertAssignmentParams): any => {
  return gql`
      mutation {
        modifyPolicyAlertAssignmentStrategy(
          clientMutationId: "mutationUpdateAlertAssignment",
          policyId: "${params?.id}"
          input: {
              globalStrategy: ${params?.alertAssignment}
          }
        ) {
          clientMutationId
        }
      }
  `
}

export const queryUpdateDatasourceFilter = ({
  id,
  dataSourceIds = [],
  anyDatasource = false,
  scanConditions = []
}: IUpdateFilter): any => {
  let scanConditionsFragment = ``
  if (scanConditions?.length) {
    const getConditionString = (condition) => `{
      unstructuredDatasourcePolicyScanConditions: {
        filterType: ${condition?.filterType},
        objectType: ${condition?.objectType},
        objectValues: ${JSON.stringify(condition?.objectValues)}
        objectMetadata: [${condition?.objectMetadata
          ?.map((meta) => `{ id: "${meta?.id}" name: "${meta?.name}" }`)
          ?.join(',')}]
      }
    }`
    scanConditionsFragment = scanConditions
      ?.map(
        (condition) => `
          {
            datasourceId: "${condition?.datasourceId}"
            scanConditions: [
              ${condition?.scanConditions
                ?.map((condition) => getConditionString(condition))
                ?.join(',')}
            ]
          }
        `
      )
      ?.join(',')
  }

  const dataSourcesInfo = anyDatasource
    ? 'conditions: [{filterExpression: "ANY"}]'
    : `datasourceIds: ${JSON.stringify(dataSourceIds)}`
  const fragment = scanConditions?.length
    ? `
datasourceWithScanConditions: [${scanConditionsFragment}]
`
    : ''

  // const dataSourcesInfo = anyDatasource
  //   ? 'conditions: [{filterExpression: "ANY"}]'
  //   : `datasourceIds: ${JSON.stringify(dataSourceIds)}`

  return gql`
    mutation{
      updateFilter(
        clientMutationId: "updateDatasourceFilter",
        id: "${id}"
        filterData: {
          type: DATA_SOURCE_FILTERS
          configuration: {
            datasourceFilters: {
              ${fragment}
              ${dataSourcesInfo}
            }
          }
        }
      ){
        clientMutationId
        filter {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  `
}

export const queryUpdateDocumentLabelSetFilter = ({ id, documentLabelSetId }: IUpdateFilter) => {
  return gql`
    mutation{
      updateFilter(
        clientMutationId: "updateDocumentLabelSetFilter",
        id: "${id}"
        filterData: {
          type: DOCUMENT_LABEL_SET_FILTERS
          configuration: {
            documentLabelSetFilters: {
              documentLabelSets: [{
                  documentLabelSetId: "${documentLabelSetId}",
                }
              ]
            }
          }
        }
      ){
        clientMutationId
        filter {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  `
}

export const queryUpdateDurationFilter = ({
  id,
  value,
  valueType,
  attributeType
}: IUpdateFilter) => {
  const attrFragment =
    attributeType && SYSTEM_RETENTION_ATTRIBUTE_TYPES.includes(attributeType)
      ? `attributeType: ${attributeType}`
      : `
  attributeType: METADATA_ID
  metadataValueInput: {
    metadataId: "${attributeType}"
  }

`
  return gql`
    mutation{
      updateFilter(
        clientMutationId: "updateDurationFilter",
        id: "${id}"
        filterData: {
          type: TIME_BASED_FILTERS
          configuration: {
            timeBasedFilters: {
              ${attrFragment}
              retentionType: DELETE_ONLY
              valueType: ${valueType}
              value: "${value}"
            }
          }
        }
      ){
        clientMutationId
        filter {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  `
}

export const queryCreateAutomationAction = (): any => gql`
  mutation {
    createAction(
      actionData: {
        name: "test-redact-${Math.random()}"
        type: REDACTION
        owner: "Admin"
        configuration: { redactionActionInput: { redactionStrategy: CHARACTER } }
      }
      clientMutationId: ""
    ) {
      clientMutationId
      action {
        edges {
          node {
            id
          }
        }
      }
    }
  }
`

export const mapQueryCreateAutomationAction = (raw: any): { actionId?: string } => {
  let actionId: string | undefined
  const { edges } = raw?.createAction?.action

  if (edges) {
    const [nodeItem] = edges
    if (nodeItem) {
      const { node } = nodeItem
      actionId = node?.id
    }
  }

  return actionId ? { actionId } : {}
}

export const queryUpdateAttributeSetFilter = ({ id, attributeSetIds }: IUpdateFilter) => gql`
  mutation {
    updateFilter (
      clientMutationId: "updateAttributeSetFilter",
      id: "${id}"
      filterData: {
        type: ATTRIBUTE_SET_FILTERS
        configuration: {
          attributeSetFilters: ${JSON.stringify(attributeSetIds)}
        }
      }
    ){
      clientMutationId
      filter{
        edges{
          node{
            id
          }
        }
      }
    }
  }
`

export const queryPolicyById = (id: string): string => gql`
  {
    policy(id: "${id}") {
      edges {
        node {
          id
          name
          description
          severity
          status
          regulations
          notifyUsers
          flagOnlySourceOfTruthVerified
          subAlertAssignmentStrategy {
            globalStrategy
          }
          notifyAction {
            raiseAnAlert
          }
          attributeCondition {
            sensitivity
            attributeCount
            conditionOperator
          }
          policyFilters {
            profileIds
            filterIds
          }
          policyActions
          alert(first: 1, filter: { key: STATUS, values: "Active" }) {
            count
          }
          policyFrequencyConfiguration {
            type
            value
            startFrom
            runOn {
              day
              type
            }
          }
        }
      }
    }
  }
`

export const queryPolicyDetailById = (id: string): string => gql`
  {
    policy(id: "${id}") {
      edges {
        node {
          id
          name
          severity
          status
          regulations
          notifyUsers
          subAlertAssignmentStrategy {
            globalStrategy
          }
          policyType {
            edges {
              node {
                id
                name
                classification
                description
              }
            }
          }
          permittedObjects {
            count
          }
          permittedEntitiesCount
          permittedAttributesCount
          alert(first: 1, filter: { key: STATUS, values: "Active" }) {
            count
          }
          policyFilters {
            profileIds
            filterIds
          }
        }
      }
    }
  }
`

export const mapQueryPolicyDetailById = (raw: any): { policy?: IPolicy } => {
  const { edges } = raw?.policy
  if (edges?.length) {
    const { node } = edges[0]
    const {
      policyType: { edges: policyTypeEdges = [] } = {},
      id,
      name,
      severity,
      regulations,
      status,
      policyFilters,
      alert,
      notifyUsers,
      permittedEntitiesCount = 0,
      permittedAttributesCount = 0,
      permittedObjects,
      subAlertAssignmentStrategy
    } = node || {}
    const [nodeItem] = policyTypeEdges || []
    const {
      node: {
        id: policyTypeId = null,
        name: policyType = null,
        description = null,
        classification: prefix = null
      } = {}
    } = nodeItem || {}
    return {
      policy: {
        id,
        name,
        severity,
        regulations,
        status,
        policyFilters,
        policyType,
        policyTypeId,
        description,
        notifyUsers,
        prefix,
        permittedAttributesCount,
        permittedEntitiesCount,
        permittedObjectsCount: permittedObjects?.count || 0,
        alertsCount: alert?.count || 0,
        alertAssignment:
          subAlertAssignmentStrategy?.globalStrategy || ALERT_ASSIGMENT_OPTIONS.DATASOURCE_OWNER
      }
    }
  }
  return {}
}

export const queryCreateAttributeSetFilter = (attributeSetIds: string[]): any => {
  const query = gqlast`mutation {
    createFilter( clientMutationId: ""
      filterData:{
        name:"Attribute set filter-${Math.random()}",
        owner:"Admin",
        type: ATTRIBUTE_SET_FILTERS,
        configuration:{
          attributeSetFilters:${attributeSetIds}
        }
      })
      {
        filter{
          edges{
            node{
              id
            }
          }
        }
      }}
    `
  return gql`
    ${query}
  `
}

export const queryDataSources = (): string => gql`
  {
    query {
      datasources {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`

export const queryAlertAwsBuckets = (params: IAlertObjectsImpactedParams): string => {
  const { datasourceId, alertId, subAlertAssignees = [] } = params
  const id = alertId ? `, id: "${alertId}"` : ''
  const dataSourceId = datasourceId
    ? `, datasourceIds: ${JSON.stringify([params?.datasourceId])}`
    : ''

  const filterSubAlertAssignees =
    subAlertAssignees?.length > 0 ? `subAlertAssignees: ${JSON.stringify(subAlertAssignees)}` : ''
  const filterAssignee =
    subAlertAssignees?.length > 0 ? `assignees: ${JSON.stringify(subAlertAssignees)}` : ''

  return gql`
  {
    alert(${dataSourceId} ${id}, first: 1, ${filterSubAlertAssignees}) {
      edges {
        node {
          subAlerts(first: 100,
            filter:[{
              key:${API_FILTER_ALERTS.STATUS},
              values:["${ALERT_STATUS_API_MAP[ALERT_STATUS.active]}"]
            }],
            ${filterAssignee}
          ) {
            edges {
              node {
                id
                objects(first: 1) {
                  count
                  edges {
                    node {
                      bucket{
                        edges{
                          node{
                            name
                            displayName
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
}

export const mapQueryAlertAwsBuckets = (raw: any): string[] => {
  const buckets = new Set<string>()
  raw?.alert?.edges[0]?.node?.subAlerts?.edges?.forEach(({ node: subAlert }) => {
    const { name = '', displayName = '' } =
      subAlert?.objects?.edges?.[0]?.node?.bucket?.edges?.[0]?.node || {}
    buckets?.add(displayName || name || '')
  })
  return [...buckets]
}

export const queryAlertDriveFolders = (params: IAlertObjectsImpactedParams): string => {
  const { datasourceId, alertId, subAlertAssignees = [] } = params
  const id = alertId ? `, id: "${alertId}"` : ''
  const dataSourceId = datasourceId
    ? `, datasourceIds: ${JSON.stringify([params?.datasourceId])}`
    : ''
  const filterSubAlertAssignees =
    subAlertAssignees?.length > 0 ? `subAlertAssignees: ${JSON.stringify(subAlertAssignees)}` : ''
  const filterAssignee =
    subAlertAssignees?.length > 0 ? `assignees: ${JSON.stringify(subAlertAssignees)}` : ''

  return gql`
  {
    alert(${dataSourceId} ${id}, first: 1, ${filterSubAlertAssignees}) {
      edges {
        node {
          subAlerts(first: 100,
            filter:[{
              key:${API_FILTER_ALERTS.STATUS},
              values:["${ALERT_STATUS_API_MAP[ALERT_STATUS.active]}"]
            }],
            ${filterAssignee}
          ) {
            edges {
              node {
                objects(first: 1) {
                  edges {
                    node {
                      folder {
                        id
                        path
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
}

export const mapQueryAlertDriveFolders = (raw: any): FolderInfo[] => {
  const foldersInfo: FolderInfo[] = raw?.alert?.edges[0]?.node?.subAlerts?.edges
    ?.filter(
      ({ node: subAlert }) =>
        subAlert?.objects?.edges[0]?.node?.folder &&
        subAlert?.objects?.edges[0]?.node?.folder?.path !== '/'
    )
    ?.map(({ node: subAlert }) => subAlert?.objects?.edges[0]?.node?.folder)

  return [...new Map(foldersInfo?.map((folder) => [folder?.id, folder]))?.values()]
}

export const queryAlertEmailRecipientsWithSenders = (
  params: IAlertObjectsImpactedParams
): string => {
  const { page, alertId, datasourceId = '' } = params
  const cursor = getAfterCursor(page, LIMIT_DEFAULT)

  return gql`
  {
    mail(first: ${LIMIT_DEFAULT}, after: "${cursor}", datasourceId: "${datasourceId}"
      filter: {key: ALERT_IDS, values: ["${alertId}"]}
     ) {
      edges {
        node {
          sender {
            email
          }
          recipients {
            email
          }
        }
      }
    }
  }
  `
}

export const mapQueryAlertEmailRecipientsWithSenders = (
  raw: any
): { recipients: string[]; senders: string[] } => {
  const recipientsList = new Set<string>()
  const sendersList = new Set<string>()
  try {
    if (raw && raw?.mail) {
      const mails = raw?.mail?.edges
      if (mails && Array?.isArray(mails)) {
        mails?.forEach(({ node: { sender, recipients = [] } }) => {
          sendersList?.add(sender?.email)
          recipients?.forEach(({ email }) => {
            recipientsList?.add(email)
          })
        })
      }
    }
    return { recipients: [...recipientsList], senders: [...sendersList] }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const mapQueryCreateDatasourceFilter = (raw: any): { filterId?: string } => {
  let filterId: string | undefined
  const { edges } = raw?.createFilter?.filter

  if (edges) {
    const [nodeItem] = edges
    if (nodeItem) {
      const { node } = nodeItem
      filterId = node?.id
    }
  }

  return filterId ? { filterId } : {}
}

export const mapQueryPoliciesListByType = (
  raw: any
): {
  list: RulsetInfo[]
  total: number
  policyListPageInfo: IPageInfo
} => {
  const { edges, count, pageInfo } = raw?.policy
  const policies: RulsetInfo[] = []

  edges?.forEach((edge) => {
    const { node } = edge

    policies?.push({
      id: node?.id,
      name: node?.name,
      status: node?.status,
      createdAt: node?.createdAt,
      permittedEntitiesCount: node?.permittedEntitiesCount || 0,
      alertsCount: node?.alert?.count || 0,
      permittedAttributesCount: node?.permittedAttributesCount || 0,
      permittedObjectsCount: node?.permittedObjects?.count || 0
    })
  })

  return {
    list: policies,
    total: count,
    policyListPageInfo: pageInfo
  }
}

export const mapQueryPolicyTypes = (raw: any): IPolicyType[] => {
  const { edges } = raw?.policyType
  const policyTypes: IPolicyType[] = []

  edges?.forEach((edge) => {
    const { node } = edge
    policyTypes?.push({
      name: node?.name,
      id: node?.id,
      prefix: node?.classification,
      description: node?.description,
      alertsCount: node?.alerts?.count,
      policiesCount: node?.policy?.count
    })
  })

  return getSortedPolicyTypes(policyTypes)
}

export const mapQueryPolicyFilters = (
  raw: any
): {
  list: IPolicyFilter[]
  total: number
} => {
  const { edges, count } = raw?.filters

  const list: IPolicyFilter[] = edges?.map(({ node }) => {
    const { id, name, createdAt, owner, configuration } = node
    const { conditions = [] } = configuration || {}
    const profiles: IPolicyFilterProfile[] = []
    conditions &&
      conditions?.forEach((cond) => {
        const { conditionLabel: { edges = [] } = {} } = cond
        edges?.forEach((nodeItem) => {
          const { node = null } = nodeItem || {}
          if (node) {
            profiles?.push(node)
          }
        })
      })
    return {
      id,
      name,
      createdAt,
      owner,
      profiles
    }
  })

  return { list, total: count }
}

export const mapApplyFilterCondtion = (
  raw: any
): {
  profileMapData: IProfileMapData[]
} => {
  const { edges } = raw?.profileMaps
  const { profileMapData } = edges[0]?.node

  return { profileMapData: JSON.parse(profileMapData) }
}

export const mapQueryFilter = (
  raw: any
): {
  profileMapId: string
  conditions: Array<{ filterExpression: string }>
} => {
  const { edges } = raw?.filters
  const { configuration } = edges[0]?.node
  const { profileMapId, conditions } = configuration

  return { profileMapId, conditions }
}

export const mapQueryProfileMapData = (
  raw: any
): {
  profileMapData: IProfileMapData[]
  columns: string[]
  profileMapColumnsData: {
    list: any[]
    total: number
  }
  filteredfileName: string
  totalRecordCount: number
  filteredRecordCount: number
} => {
  const { edges } = raw?.profileMaps
  let profileMapColumns = []
  let profileData = []
  let columns: string[] = []
  let filteredfileName = ''
  let totalRecords = 0
  let filteredRecords = 0

  if (edges?.length) {
    const {
      profileMapData = [],
      mapping = [],
      fileName = '',
      totalRecordCount,
      filteredRecordCount
    } = edges[0]?.node
    filteredfileName = fileName
    totalRecords = totalRecordCount
    filteredRecords = filteredRecordCount
    profileMapColumns = mapping
    profileData = JSON.parse(profileMapData)

    if (profileData?.length) {
      columns = Object.keys(profileData[0])
    }
  }
  return {
    columns,
    profileMapData: profileData,
    profileMapColumnsData: {
      list: profileMapColumns,
      total: profileMapColumns?.length
    },
    filteredfileName,
    totalRecordCount: totalRecords,
    filteredRecordCount: filteredRecords
  }
}

export const mapUpdateAttributeSensitivity = (
  raw: any
): {
  list: any[]
  total: number
} => {
  const { edges } = raw?.updateProfileMap?.profileMap
  let profileMapColumns = []

  if (edges?.length) {
    const { mapping = [] } = edges[0]?.node
    profileMapColumns = mapping
  }

  return {
    list: profileMapColumns,
    total: profileMapColumns?.length
  }
}

export const mapQueryPolicyById = (raw: any): { policy?: IPolicy } => {
  const { edges } = raw?.policy
  if (edges?.length) {
    const { node } = edges[0]
    const { policyType: { edges: policyTypeEdges = [] } = {} } = node || {}
    const [nodeItem] = policyTypeEdges || []
    const { node: { name = null } = {} } = nodeItem || {}
    return {
      policy: {
        ...node,
        policyType: name,
        alertAssignment:
          node?.subAlertAssignmentStrategy?.globalStrategy ||
          ALERT_ASSIGMENT_OPTIONS.DATASOURCE_OWNER,
        alertsCount: node?.alert?.count || 0,
        enableAlerts: node?.notifyAction?.raiseAnAlert
      }
    }
  }
  return {}
}

const getProfileIdFromConfigObj = (configuration) => {
  const profileIds: string[] = []
  if (Array?.isArray(configuration?.conditions)) {
    configuration?.conditions?.forEach((configItem) => {
      const { conditionLabel: { edges = [] } = {} } = configItem || {}
      if (Array?.isArray(edges)) {
        edges?.forEach((nodeItem) => {
          const { node: { id } = null } = nodeItem || {}
          id && profileIds?.push(id)
        })
      }
    })
  }
  return profileIds
}

export const mapQueryPolicyFilterByIds = (raw: any): IPolicyFilterState => {
  const { edges } = raw?.filters
  const result: IPolicyFilterState = { profileFilterIds: [], list: [] }
  if (Array?.isArray(edges)) {
    edges?.forEach((nodeItem) => {
      const { node } = nodeItem || {}
      const { type, id } = node || {}
      if (type === 'PROFILE_FILTERS' && id) {
        result?.profileFilterIds?.push(id)
        const { configuration } = node
        const profileIds = getProfileIdFromConfigObj(configuration)
        result.profileIds = profileIds
        result.list?.push(node)
      } else if (type === 'DATA_SOURCE_FILTERS') {
        const { configuration: { datasource = [], datasourceWithScanConditions = [] } = {} } =
          node || {}
        result.datasourceFilterCreateId = id
        result.datasources =
          datasource?.edges?.map(({ node }) => ({
            ...node,
            dataSourceName: node.name,
            dataSourceType: node.type,
            createdBy: node.createdBy || ''
          })) || []
        result.dataSourceIds = datasource?.edges?.map(({ node }) => node.id) || []
        result.datsourceScanConditions = datasourceWithScanConditions
      } else if (type === 'ATTRIBUTE_SET_FILTERS') {
        const { configuration: { attributeSet = {} } = {} } = node || {}
        result.attributeSetFilterCreateId = id
        result.attributeSets = attributeSet?.edges?.map(({ node }) => node) || []
        result.attributeSetIds = attributeSet?.edges?.map(({ node }) => node.id) || []
      } else if (type === 'DOCUMENT_LABEL_SET_FILTERS') {
        const { id, configuration: { documentLabelSets = [] } = {} } = node || {}
        result.documentLabelSetId = documentLabelSets?.[0]?.documentLabelSetId
        result.documentLabelSetFilterCreateId = id
      } else if (type === 'TIME_BASED_FILTERS') {
        const { id, configuration } = node || {}
        result.durationCount = configuration.value
        result.durationType = configuration.valueType
        if (configuration.attributeType === RETENTION_ATTRIBUTE_TYPES.METADATA_ID) {
          result.attributeType = configuration?.attributeValue?.metadataId
        } else {
          result.attributeType = configuration.attributeType
        }
        result.durationFilterCreateId = id
      } else if (type === 'DOCUMENT_LABELS_FILTERS') {
        const { id, configuration } = node || {}
        const groups = configuration?.groups || []
        result.documentLabelGroups = {
          groups: {
            ...groups,
            documentLabels: groups?.filterLabels || []
          },
          operator: configuration.operator
        }
        result.documentLabelFilterCreateId = id
      } else if (type === 'DOCUMENT_FILTERS') {
        const { id, configuration } = node || {}
        const groups = configuration.groups || []
        result.rulesetGroups = {
          groups,
          operator: configuration?.operator
        }
        result.documentFilterCreateId = id
      } else if (type === 'ACCESS_FILTERS') {
        const { id, configuration } = node || {}
        result.accessFilterCreateId = id
        const rule = configuration?.accessRules?.[0] || {}
        result.accessType = rule?.accessType?.type
        result.accessSubTypes =
          configuration?.accessRules?.map((rule) => rule?.accessType?.subType) || []
        result.excessiveAccessUserCount =
          rule?.accessConditions?.conditions?.find(
            (condition) => condition?.conditionType === 'ACCESSOR_AGGREGATION_OPERATOR_CONDITION'
          )?.conditionArg?.conditionValues?.[0] || 0
      }
    })
  }
  return result
}

export const queryExternalUsers = (params: IPolicyAlertExternalUserParams): string => {
  const { page, datasourceId, alertId, externalUser, subAlertAssignees = [] } = params

  const cursor = getAfterCursor(page, LIMIT_DEFAULT)
  const skip = calculateSkipAndLimit(page, LIMIT_DEFAULT)
  const id = alertId ? `, id: "${alertId}"` : ''
  const dataSourceId = datasourceId
    ? `, datasourceIds: ${JSON.stringify([params?.datasourceId])}`
    : ''
  const userFilter = externalUser
    ? `(user: "${externalUser}")`
    : `(${skip ? `skip: ${skip}, ` : ''}limit: ${LIMIT_DEFAULT})`
  const externalUserFilter = externalUser ? `, externalUser: "${externalUser}"` : ''

  const filterSubAlertAssignees =
    subAlertAssignees?.length > 0 ? `subAlertAssignees: ${JSON.stringify(subAlertAssignees)}` : ''
  const filterAssignee =
    subAlertAssignees?.length > 0 ? `assignees: ${JSON.stringify(subAlertAssignees)}` : ''

  return gql`
  {
    alert(${dataSourceId} ${id}, first: 1, ${filterSubAlertAssignees}) {
      edges {
        node {
          externalUserCount
          externalUser${userFilter} {
            user
            objectCount
          }
          subAlerts(first: ${LIMIT_DEFAULT}, after: "${cursor}" ${externalUserFilter}, ${filterAssignee}, booleanFilter: {key: HAS_EXTERNAL_MEMBERS, value: true}) {
            count
            edges {
              node {
                id
                objects {
                  edges {
                    node {
                      id
                      name
                      attributes
                      owner
                      lastModifiedTime
                      messageId
                      entity {
                        count
                      }
                    }
                  }
                }
                channels {
                  edges {
                    node {
                      channelId
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
}

export const mapQueryExternalUsers = (
  raw: any
): {
  list: IPolicyAlertExternalUser[]
  cards: IPolicyAlertExternalUserGrouped[]
  total: number
  externalUserCount: number
} => {
  try {
    const total = raw?.alert?.edges[0]?.node?.subAlerts?.count || 0
    const externalUserCount = raw?.alert?.edges[0]?.node?.externalUserCount
    const list = raw?.alert?.edges[0]?.node?.subAlerts?.edges?.map(({ node: subAlert }) => ({
      id: subAlert?.id,
      objectId: subAlert?.objects?.edges[0]?.node?.id || '',
      messageId: subAlert?.objects?.edges[0]?.node?.messageId || '',
      channelId: subAlert?.channels?.edges[0]?.node?.channelId || '',
      objectName: subAlert?.objects?.edges[0]?.node?.name || '',
      attributesCount: subAlert?.objects?.edges[0]?.node?.attributes?.length || 0,
      entitiesCount: subAlert?.objects?.edges[0]?.node?.entity?.count || 0,
      owner: subAlert?.objects?.edges[0]?.node?.owner || '',
      lastModified: subAlert?.objects?.edges[0]?.node?.lastModifiedTime || null
    }))

    const cards = raw?.alert?.edges[0]?.node?.externalUser?.map(({ user, objectCount }) => ({
      user,
      objectsCount: objectCount
    }))

    return { list, cards, total, externalUserCount }
  } catch (e) {
    console.error(e)
    return { list: [], cards: [], total: 0, externalUserCount: 0 }
  }
}

export const queryEntitiesImpacted = (params: IPolicyAlertEntitiesImpactedParams): string => {
  const { page, datasourceId, subAlertAssignees = [] } = params
  const cursor = getAfterCursor(page, LIMIT_DEFAULT)
  const dataSourceId = datasourceId ? `, datasourceIds: ${JSON.stringify([datasourceId])}` : ''

  const filterSubAlertAssignees =
    subAlertAssignees?.length > 0 ? `subAlertAssignees: ${JSON.stringify(subAlertAssignees)}` : ''

  return gql`
  {
    alert(first: 1, id: "${params[ALERT_ID]}" ${dataSourceId}, ${filterSubAlertAssignees}){
      edges{
        node{
          entityCount: entities {
            count
          }
          entities (first: ${LIMIT_DEFAULT}, after: "${cursor}") {
            edges {
              node {
                name
                id
                objects {
                  count
                }
                attributesCount
              }
            }
          }
        }
      }
    }
  }
`
}

export const mapQueryEntitiesImpacted = (
  raw: any
): { list: IPolicyAlertEntitiesImpacted[]; total: number } => {
  const total = raw?.alert?.edges[0]?.node?.entityCount?.count || 0
  const list = raw?.alert?.edges[0]?.node?.entities?.edges?.map(({ node: entity }) => ({
    entityId: entity?.id,
    entityName: entity?.name?.join(', '),
    objectsCount: entity?.objects?.count,
    attributesCount: entity?.attributesCount
  }))

  return { list, total }
}

export const queryRuleSetDetails = (params: AlertRuleSetDetailsParams): string => {
  const { subAlertAssignees = [], isStructDs } = params
  const id = params?.alertId ? `, id: "${params?.alertId}"` : ''
  const alertId = params?.alertId ? `, alertId: "${params?.alertId}"` : ''
  const filterSubAlertAssignees =
    subAlertAssignees?.length > 0 ? `subAlertAssignees: ${JSON.stringify(subAlertAssignees)}` : ''
  const entitiesFragment = filterSubAlertAssignees
    ? ''
    : `entities {
      count
    }`

  const filterSubAlertAssigneesWithKey =
    subAlertAssignees?.length > 0
      ? `{key: SUB_ALERT_ASSIGNEES, values: ${JSON.stringify(subAlertAssignees)}}`
      : ''

  const externalUserFragment = !isStructDs ? `externalUserCount` : ''
  /**
   * @todo use separate queries based on datasource type
   */

  return gql`
  {
    attributeInstance(first: 1, after: "" ${alertId}, sensitivity: [${params?.sensitivity}], ${filterSubAlertAssignees}) {
      count
    }
    channel(alertId: "${params?.alertId}" ${filterSubAlertAssignees}){
      count
    }
    ticket(filter: [{key: ALERT_IDS, values: ["${params?.alertId}"]}, , ${filterSubAlertAssigneesWithKey}]) {
      count
    }
    alert(first: 1 ${id}, ${filterSubAlertAssignees}) {
      edges {
        node {
          id
          name
          status
          subAlertAssignees(limit: 2) {
            totalCount
            assignees
          }
          severity
          lastOccurred
          objectCount
          mailCount
          ${entitiesFragment}
          ${externalUserFragment}
          actions {
            type
            comment
            updateTimestamp
            resolvedReason
            muteEndTime
            subAlertIds
          }
          datasource {
            edges {
              node {
                id
                type
                name
                createdBy
                policyActionConfiguration {
              actionType
              configuration {
                ... on PolicyActionArchiveConfigurationOutput {
                  archiveLocation
                }
              }
            }
              }
            }
          }
          policy {
            edges {
              node {
                id
                description
                subAlertAssignmentStrategy {
                  globalStrategy
                }
                policyType {
                  edges {
                    node {
                      id
                      name
                      regulations
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
}

export const mapQueryRuleSetDetails = (raw: any): AlertRuleSetDetails => {
  const alert = raw?.alert?.edges[0]?.node
  const details = {
    id: alert?.id || '',
    name: alert?.name || '',
    status: alert?.status || '',
    assignees: alert?.subAlertAssignees?.assignees || [],
    assigneesCount: alert?.subAlertAssignees?.totalCount,
    severity: alert?.severity || '',
    lastOccurred: alert?.lastOccurred || '',
    objectsCount: alert?.objectCount || 0,
    ticketsCount: raw?.ticket?.count || 0,
    entitiesCount: alert?.entities?.count || 0,
    attributesCount: raw?.attributeInstance?.count || 0,
    externalUsersCount: alert?.externalUserCount || 0,
    actions: alert?.actions || [],
    dataSourceId: alert?.datasource?.edges[0]?.node?.id || '',
    dataSourceName: alert?.datasource?.edges[0]?.node?.name || '',
    dataSourceType: alert?.datasource?.edges[0]?.node?.type || '',
    dataSourceOwner: alert?.datasource?.edges[0]?.node?.createdBy,
    dataSourceArchiveLocation:
      alert?.datasource?.edges[0]?.node?.policyActionConfiguration?.[0]?.configuration
        ?.archiveLocation || '',
    policyName: alert?.policy?.edges[0]?.node?.policyType?.edges[0]?.node?.name || '',
    policyTypeId: alert?.policy?.edges[0]?.node?.policyType?.edges[0]?.node?.id || '',
    policyId: alert?.policy?.edges[0]?.node?.id || '',
    alertAssignment:
      alert?.policy?.edges[0]?.node?.subAlertAssignmentStrategy?.globalStrategy || '',
    regulations: alert?.policy?.edges[0]?.node?.policyType?.edges[0]?.node?.regulations || '',
    description: alert?.policy?.edges[0]?.node?.description || '',
    mailCount: alert?.mailCount || 0,
    channelsCount: raw?.channel?.count || 0
  }

  return details
}

export const queryAlertAuditLogs = (params: AlertAuditLogsParams): string => {
  const { alertId } = params

  return gql`
    {
      alert(first: 1, id: "${alertId}") {
        edges {
          node {
            id
            history {
              edges {
                node {
                  id
                  action
                  triggeredBy
                  comment
                  completionTimestamp
                  triggeredTimestamp
                  numObjectsPending
                  numObjectsComplete
                  config {
                    ... on ReassignConfig {
                      newAssignee
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}
export const mapQueryAlertAuditLogs = (raw: any): AlertAuditLog[] => {
  const list = raw?.alert?.edges[0]?.node?.history?.edges?.map(({ node: log }) => ({
    id: log?.id,
    action: log?.action,
    triggeredBy: log?.triggeredBy,
    comment: log?.comment || '',
    completionTimestamp: log?.completionTimestamp || '',
    triggeredTimestamp: log?.triggeredTimestamp || '',
    numObjectsPending: log?.numObjectsPending || 0,
    numObjectsComplete: log?.numObjectsComplete || 0,
    newAssignee: log?.newAssignee,
    muteTillTimestamp: log?.muteTillTimestamp
  }))

  return list
}

export const queryAlertAuditLogDetails = (params: AlertAuditLogDetailsParams): string => {
  const { alertId, auditLogId } = params
  const cursor = getAfterCursor(Number(params?.page), AUDIT_LOG_OBJECTS_LIMIT_DEFAULT)

  return gql`
    {
      alert(first: 1, id: "${alertId}") {
        edges {
          node {
            id
            history(first: 1, id: "${auditLogId}") {
              edges {
                node {
                  id
                  action
                  triggeredBy
                  comment
                  triggeredTimestamp
                  numObjectsComplete
                  config {
                    ... on ReassignConfig {
                      newAssignee
                    }
                    ... on MuteConfig {
                      muteTillTimestamp
                    }
                  }
                  actionMetadata {
                    ... on PolicyActionConfigurationOutput {
                      actionType
                      configuration {
                        ... on PolicyActionArchiveConfigurationOutput {
                          archiveLocation
                        }
                      }
                    }
                  }
                  subAlertHistory(first: ${AUDIT_LOG_OBJECTS_LIMIT_DEFAULT}, after: "${cursor}") {
                    count
                    edges {
                      node {
                        updateTimestamp
                        subAlertId
                        workflowId
                        objects(first: 1) {
                          edges {
                            node {
                              id
                              name
                              owner
                              datasource {
                                edges {
                                  node {
                                    type
                                  }
                                }
                              }
                              auditAttributeData {
                                attributes {
                                  internalName
                                  name
                                }
                                count
                              }
                              classification
                              objectLink
                              drive {
                                edges{
                                  node{
                                    driveName
                                  }
                                }
                              }
                              entity {
                                count
                                edges {
                                  node {
                                    id
                                    name
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}
export const mapQueryAlertAuditLogDetails = (raw: any): AlertAuditLog => {
  const auditLog = raw?.alert?.edges[0]?.node?.history?.edges[0]?.node

  const objectsList = auditLog?.subAlertHistory?.edges?.map((subAlert) => {
    const obj = subAlert?.node?.objects?.edges[0]?.node
    return {
      id: obj?.id,
      name: obj?.name,
      owner: obj?.owner,
      classification: obj?.classification,
      objectLink: obj?.objectLink,
      updateTimestamp: subAlert?.node?.updateTimestamp,
      workflowId: subAlert?.node?.workflowId,
      subAlertId: subAlert?.node?.subAlertId,
      attributes: obj?.auditAttributeData?.attributes?.map((attr) => attr),
      datasourceType: obj?.datasource?.edges?.[0]?.node?.type,
      entities: obj?.entity?.edges?.map((entity) => entity?.node)
    }
  })

  return {
    id: auditLog?.id,
    action: auditLog?.action,
    triggeredBy: auditLog?.triggeredBy,
    comment: auditLog?.comment || '',
    completionTimestamp: auditLog?.completionTimestamp || '',
    triggeredTimestamp: auditLog?.triggeredTimestamp || '',
    numObjectsPending: auditLog?.numObjectsPending || 0,
    numObjectsComplete: auditLog?.numObjectsComplete || 0,
    totalObjects: auditLog?.subAlertHistory?.count,
    newAssignee: auditLog?.config?.newAssignee,
    archivalLocation: auditLog?.actionMetadata?.configuration?.archiveLocation,
    muteTillTimestamp: auditLog?.config?.muteTillTimestamp,
    objectsList: objectsList
  }
}

export const queryAuditLogActionHistory = (params: AlertAuditLogActionHistoryParams): any => {
  return gql`
    query subAlertHistoryData {
      subAlertHistory(
        first: ${params?.page * LIMIT_DEFAULT}
        subAlertIds: ["${params?.subAlertId}"]
        anchor: { anchorWorkflowId: "${params?.anchorWorkflowId}", nearestNeighbours: ${
    params?.nearestNeighbours
  } }
      ) {
        edges {
          node {
            subAlertId
            workflowId
            alertHistory {
              edges {
                node {
                  id
                  triggeredTimestamp
                  triggeredBy
                  action
                  comment
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapAuditLogActionHistory = (raw: any): AuditLogActionHistory | undefined => {
  const { edges } = raw?.subAlertHistory

  const items: any[] = []
  if (edges?.length) {
    edges?.forEach((edge) => {
      const { node = {} } = edge || {}
      items.push({
        ...node?.alertHistory?.edges[0]?.node,
        subAlertId: node?.subAlertId,
        workflowId: node?.workflowId
      })
    })

    return {
      alertHistory: items as any[]
    }
  }
  return
}

// sub alert actions
export const queryResolveAlert = (params: AlertActionParams): any => {
  return gql`
    mutation {
      resolveSubAlert(
        actionInput: {
          clientMutationId: "1"
          alertId: "${params?.alertIds[0]}"
          triggeredBy: "${params?.triggeredBy}"
          comment: "${escapeNewlineandTab(params?.comment || '')}"
          subAlertIds: ${JSON.stringify(params?.subAlertIds || [])}
        }
      ) { status }
    }
  `
}
export const queryReassignAlert = (params: AlertActionParams): any => {
  return gql`
      mutation {
        reassignSubAlert(
          actionInput: {
            clientMutationId: "1"
            alertId: "${params?.alertIds[0]}"
            triggeredBy: "${params?.triggeredBy}"
            comment: "${escapeNewlineandTab(params?.comment || '')}"
            ${params?.newAssignee ? `newAssignee: "${params?.newAssignee || ''}"` : ''}
            subAlertIds: ${JSON.stringify(params?.subAlertIds || [])}
            assignees: ${JSON.stringify(params?.assignees || [])}
            ${
              params?.subAlertAssignmentStrategy
                ? `subAlertAssignmentStrategy: {globalStrategy: ${params?.subAlertAssignmentStrategy}}`
                : ''
            }
          }
        ) { status }
      }
    `
}
export const queryNoScanAlert = (params: AlertActionParams): any => {
  const folderIdsFragment = params?.folderIds?.length
    ? `folderIds: ${JSON.stringify(params?.folderIds || [])}`
    : ''

  const subalertIdsFragment = params?.subAlertIds?.length
    ? `subAlertIds: ${JSON.stringify(params?.subAlertIds || [])}`
    : ''

  return gql`
      mutation {
        noScanSubAlert(
          actionInput: {
            clientMutationId: "1"
            alertId: "${params?.alertIds[0]}"
            triggeredBy: "${params?.triggeredBy}"
            comment: "${escapeNewlineandTab(params?.comment || '')}"
            ${folderIdsFragment}
            ${subalertIdsFragment}
          }
        ) { status }
      }
    `
}
export const queryPermitAlert = (params: AlertActionParams): any => {
  const folderIdsFragment = params?.folderIds?.length
    ? `folderIds: ${JSON.stringify(params?.folderIds || [])}`
    : ''

  const subalertIdsFragment = params?.subAlertIds?.length
    ? `subAlertIds: ${JSON.stringify(params?.subAlertIds || [])}`
    : ''

  return gql`
    mutation {
      permitSubAlert(
        actionInput: {
          clientMutationId: "1"
          alertId: "${params?.alertIds[0]}"
          triggeredBy: "${params?.triggeredBy}"
          comment: "${escapeNewlineandTab(params?.comment || '')}"
          ${folderIdsFragment}
          ${subalertIdsFragment}
        }
      ) {
        status
      }
    }
  `
}
export const queryMuteAlert = (params: AlertActionParams): any => {
  return gql`
    mutation {
      muteSubAlert(
        actionInput: {
          clientMutationId: "1"
          alertId: "${params?.alertIds[0]}"
          triggeredBy: "${params?.triggeredBy}"
          comment: "${escapeNewlineandTab(params?.comment || '')}"
          subAlertIds: ${JSON.stringify(params?.subAlertIds || [])}
          muteTillTimestamp: "${params?.muteTillTimestamp}"
        }
      ) { status }
    }
  `
}
export const queryUnmuteAlert = (params: AlertActionParams): any => {
  return gql`
    mutation {
      unmuteSubAlert(
        actionInput: {
          clientMutationId: "1"
          alertId: "${params?.alertIds[0]}"
          triggeredBy: "${params?.triggeredBy}"
          comment: "${escapeNewlineandTab(params?.comment || '')}"
          subAlertIds: ${JSON.stringify(params?.subAlertIds || [])}
        }
      ) { status }
    }
  `
}
export const queryDeleteAlertObject = (params: AlertActionParams): any => {
  return gql`
    mutation {
      deleteSubAlertObject(
        actionInput: {
          clientMutationId: "1"
          alertId: "${params?.alertIds[0]}"
          triggeredBy: "${params?.triggeredBy}"
          comment: "${escapeNewlineandTab(params?.comment || '')}"
          subAlertIds: ${JSON.stringify(params?.subAlertIds || [])}
        }
      ) { status }
    }
  `
}

export const mutationRevokeObjectAccess = (params: AlertActionParams): any => {
  const mapRevokeAccessTypeToApiParam = {
    [OBJECT_REVOKE_ACCESS_TYPES.all]: OBJECT_REVOKE_ACCESS_TYPES.all,
    [OBJECT_REVOKE_ACCESS_TYPES.allWrite]: OBJECT_REVOKE_ACCESS_TYPES.all,
    [OBJECT_REVOKE_ACCESS_TYPES.external]: OBJECT_REVOKE_ACCESS_TYPES.external,
    [OBJECT_REVOKE_ACCESS_TYPES.externalWrite]: OBJECT_REVOKE_ACCESS_TYPES.external,
    [OBJECT_REVOKE_ACCESS_TYPES.link]: OBJECT_REVOKE_ACCESS_TYPES.link
  }
  const getRevokeAccessArgParams = {
    [OBJECT_REVOKE_ACCESS_TYPES.all]: '',
    [OBJECT_REVOKE_ACCESS_TYPES.allWrite]: `
      revokeAllAccessArgs: {
        accessPermissions: [WRITE]
      }
    `,
    [OBJECT_REVOKE_ACCESS_TYPES.external]: '',
    [OBJECT_REVOKE_ACCESS_TYPES.externalWrite]: `
      revokeExternalAccessArgs: {
        accessPermissions: [WRITE]
      }
    `,
    [OBJECT_REVOKE_ACCESS_TYPES.link]: ''
  }
  return gql`
    mutation revoke {
      revokeObjectAccess(
        actionInput: {
          clientMutationId: "1"
          alertId: "${params.alertIds[0]}"
          subAlertIds: ${JSON.stringify(params.subAlertIds || [])}
          triggeredBy: "${params.triggeredBy}"
          revokeAccessAction: {
            revokeAccessType: ${
              mapRevokeAccessTypeToApiParam[params?.revokeAccessType as OBJECT_REVOKE_ACCESS_TYPES]
            }
            subAlertActionType: RESOLVE
            ${getRevokeAccessArgParams[params?.revokeAccessType as OBJECT_REVOKE_ACCESS_TYPES]}
          }
        }
      ) {
        clientMutationId
        status
      }
    }
  `
}

export const mutationArchiveSubAlerts = (params: SubAlertsActionParams): any => {
  return gql`
    mutation archiveObject {
      archiveSubAlertObject(actionInput: {
        clientMutationId: "1",
        alertId: "${params?.alertId}",
        subAlertIds: ${JSON.stringify(params?.subAlertIds)},
        triggeredBy: "${params?.triggeredBy}",
        comment: "${params?.comment}"
      }) {
        clientMutationId
        status
      }
    }
  `
}

export const mutationApplySubAlertLegalHold = (params: ApplySubAlertActionParams): any => {
  return gql`
    mutation applyLegalHold {
      applyLegalHold(actionInput: {
        clientMutationId: "1",
        alertId: "${params?.alertId}",
        subAlertIds: ${JSON.stringify(params?.subAlertIds)},
        triggeredBy: "${params?.triggeredBy}",
        comment: "${params?.comment}",
        muteTillTimestamp: "${params?.muteTillTimestamp}"
      }) {
        clientMutationId
        status
      }
    }
  `
}

// updated queries
export const queryAlertProjectsImpacted = (params: IAlertObjectsImpactedParams): string => {
  const { page, datasourceId, alertId, subAlertAssignees = [] } = params

  const cursor = getAfterCursor(page, LIMIT_DEFAULT)

  const id = alertId ? `, id: "${alertId}"` : ''
  const dataSourceId = datasourceId
    ? `, datasourceIds: ${JSON.stringify([params?.datasourceId])}`
    : ''
  const filterSubAlertAssignees =
    subAlertAssignees?.length > 0 ? `subAlertAssignees: ${JSON.stringify(subAlertAssignees)}` : ''
  const filterAssignee =
    subAlertAssignees?.length > 0 ? `assignees: ${JSON.stringify(subAlertAssignees)}` : ''
  return gql`
  {
    alert(${dataSourceId} ${id}, first: 1, ${filterSubAlertAssignees}) {
      edges {
        node {
          subAlerts(
            first: ${LIMIT_DEFAULT},
            after: "${cursor}",
            filter:[{
              key:${API_FILTER_ALERTS.STATUS},
              values:["${ALERT_STATUS_API_MAP[ALERT_STATUS.active]}"]
            }],
            ${filterAssignee}
          ) {
            count
            edges {
              node {
                id
                objects(first: 1) {
                  count
                  edges {
                    node {
                      id
                      name
                      attribute {
                        count
                        edges {
                          node {
                            name
                          }
                        }
                      }
                      lastModifiedTime
                      entity {
                        count
                      }
                      project {
                        edges{
                          node {
                            name
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
}

export const mapQueryAlertProjectsImpacted = (
  raw: any
): { list: IAlertObjectsImpacted[]; total: number } => {
  try {
    const list = raw?.alert?.edges[0]?.node?.subAlerts?.edges?.map(({ node: subAlert }) => {
      const entities =
        subAlert?.objects?.edges[0]?.node?.entities
          ?.filter(({ name }) => !!name)
          ?.map(({ name }) => name) || []

      return {
        id: subAlert?.id,
        documentId: subAlert?.objects?.edges[0]?.node?.id || '',
        documentName: subAlert?.objects?.edges[0]?.node?.name || '',
        attributes:
          subAlert?.objects?.edges[0]?.node?.attribute?.edges?.map(
            ({ node }) => node?.name || ''
          ) || [],
        attributesCount: subAlert?.objects?.edges[0]?.node?.attribute?.count || 0,
        entitiesCount: entities?.length || 0,
        lastModified: subAlert?.objects?.edges[0]?.node?.lastModifiedTime || null,
        projectName: subAlert?.objects?.edges[0]?.node?.project?.edges[0]?.node?.name || ''
      }
    })
    return { list, total: raw?.alert?.edges[0]?.node?.subAlerts?.count }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryMailboxFilterValues = (datasourceId: string): string => {
  return gql`{
    members(filter: [{key: DATASOURCE_IDS, values: ["${datasourceId}"]}, {key: MEMBER_TYPE, values: ["INTERNAL"]}]) {
      edges {
        node {
          email
        }
      }
    }
  }
  `
}

export const mapQueryMailboxFilterValues = (raw: any): { list: string[] } => {
  let list = []
  if (raw && raw?.members) {
    if (raw?.members?.edges && Array?.isArray(raw?.members?.edges)) {
      list = raw?.members?.edges?.map(({ node: { email } }) => email)
    }
  }

  return { list }
}

export const queryAlertObjectsImpacted = (params: IAlertObjectsImpactedParams): string => {
  const {
    page,
    datasourceId,
    datasourceType,
    alertId,
    subAlertAssignees = [],
    mailboxFolderType,
    mailbox
  } = params

  const cursor = getAfterCursor(page, LIMIT_DEFAULT)

  const id = alertId ? `, id: "${alertId}"` : ''
  const dataSourceId = datasourceId
    ? `, datasourceIds: ${JSON.stringify([params?.datasourceId])}`
    : ''

  const filterSubAlertAssignees =
    subAlertAssignees?.length > 0 ? `subAlertAssignees: ${JSON.stringify(subAlertAssignees)}` : ''
  const filterAssignee =
    subAlertAssignees?.length > 0 ? `assignees: ${JSON.stringify(subAlertAssignees)}` : ''

  const filterMailboxFolderTpe = mailboxFolderType
    ? `{
      key:MAIL_FOLDER_TYPE,
      values: ${JSON.stringify(mailboxFolderType)}
      }`
    : ''

  const filterMailbox = mailbox?.length
    ? `{
      key:MAILBOX,
      values: ${JSON.stringify(mailbox)}
      }`
    : ''

  const isTickets = datasourceType === DATA_SOURCE_TYPES?.jira
  const isBuckets =
    datasourceType === DATA_SOURCE_TYPES?.aws ||
    datasourceType === DATA_SOURCE_TYPES?.azureBlob ||
    datasourceType === DATA_SOURCE_TYPES?.googleCloudStorage
  const isMails = MAIL_DATASOURCES?.find((type) => type == datasourceType)
  const isChannels =
    datasourceType === DATA_SOURCE_TYPES?.slack || datasourceType === DATA_SOURCE_TYPES?.teams
  const isGeneric = datasourceType === DATA_SOURCE_TYPES?.generic

  const FRAGMENT_BUCKETS = `
    bucket {
      edges{
        node{
          name
          displayName
          ownerName
        }
      }
    }`

  const FRAGMENT_TICKETS = `
    ticket {
      count
      edges {
        node {
          id
          ticketId
          ticketName
          lastModifiedTime
          ticketLink
          reporter {
            name
          }
          assignee {
            name
          }
          ticketStoreProject(first: 1) {
            edges {
              node {
                id
                projectName
              }
            }
          }
          hasAttachments
          attachments {
            id
            name
            type
          }
        }
      }
    }`

  const FRAGMENT_MAILS = `
    mail {
      count
      edges{
        node{
          id
          type
          refMailboxIds
          messageId
          timestamp
          subject
          sender {
            email
          }
          recipients {
            email
          }
          attachments: objects(category: FILE) {
            edges {
              node {
                id
                name
                type
              }
            }
          }
        }
      }
    }`

  const FRAGMENT_CHANNELS = `
    channels {
      count
      edges {
        node {
          channelId
          channelName
          piiConversationsCount
          membersCount
          type
        }
      }
    }`

  const FRAGMENT_PROJECTS = `
    project {
      edges{
        node {
          name
        }
      }
    }`

  const FRAGMENT_ACCESS_CONTROL = `
      userAccess {
        count
        edges {
          node {
            id
            name
          }
        }
      }
      writeAccess: userAccess(accessModes: [${AccessControlAccessMode.Write}]) {
        count
        edges {
          node {
            id
            name
          }
        }
      }
      readAccess: userAccess(accessModes: [${AccessControlAccessMode.Read}]) {
        count
        edges {
          node {
            id
            name
          }
        }
      }
      groupAccess {
        count
        edges {
          node {
            id
            name
          }
        }
      }
    `

  const isPolicyAccessControl = params?.policyType === POLICY_TYPES.ACCESS_POLICY

  return gql`
  {
    alert(${dataSourceId} ${id}, first: 1, ${filterSubAlertAssignees}) {
      edges {
        node {
          datasource {
            edges {
              node {
                type
              }
            }
          }
          subAlerts(
            first: ${LIMIT_DEFAULT},
            after: "${cursor}",
            filter:[{
              key:${API_FILTER_ALERTS.STATUS},
              values:["${ALERT_STATUS_API_MAP[ALERT_STATUS.active]}"]
            }, ${filterMailboxFolderTpe}, ${filterMailbox}],
            ${filterAssignee}
          ) {
            count
            edges {
              node {
                id
                assignee
                generatedAt
                ${isChannels ? FRAGMENT_CHANNELS : ''}
                objects(first: 1) {
                  count
                  edges {
                    node {
                      id
                      name
                      attributes
                      tableClusterName
                      classification
                      owner
                      lastModifiedTime
                      createdAt
                      objectLink
                      folder {
                        id
                        path
                        name
                        webUrl
                      }
                      entity {
                        count
                        edges {
                          node {
                            id
                            name
                          }
                        }
                      }
                      attribute {
                        count
                        edges {
                          node {
                            name
                            internalName
                          }
                        }
                      }
                      ${isPolicyAccessControl ? FRAGMENT_ACCESS_CONTROL : ''}
                      ${isBuckets ? FRAGMENT_BUCKETS : ''}
                      ${isTickets ? FRAGMENT_TICKETS : ''}
                      ${isMails ? FRAGMENT_MAILS : ''}
                      ${isGeneric ? FRAGMENT_PROJECTS : ''}
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
}
export const mapQueryAlertObjectsImpacted = (
  raw: any
): { list: IAlertObjectsImpacted[]; total: number } => {
  try {
    const list = raw?.alert?.edges[0]?.node?.subAlerts?.edges?.map(({ node: subAlert }) => {
      const attributesList =
        subAlert?.objects?.edges[0]?.node?.attribute?.edges?.map(({ node: attr }) => ({
          name: attr?.name,
          internalName: attr?.internalName
        })) || []

      const entitiesList =
        subAlert?.objects?.edges[0]?.node?.entity?.edges
          ?.filter(({ node }) => !!node?.name)
          ?.map(({ node }) => ({
            id: node?.id,
            name: Array?.isArray(node?.name) ? node?.name[0] : node?.name
          })) || []

      const groupsList =
        subAlert?.objects?.edges[0]?.node?.groupAccess?.edges?.map(({ node }) => ({
          id: node?.id,
          name: node?.name
        })) || []

      const usersList =
        subAlert?.objects?.edges[0]?.node?.userAccess?.edges?.map(({ node }) => ({
          id: node?.id,
          name: node?.name
        })) || []
      const writeAccess =
        subAlert?.objects?.edges[0]?.node?.writeAccess?.edges?.map(({ node }) => ({
          id: node?.id,
          name: node?.name
        })) || []

      const readAccess = subAlert?.objects?.edges[0]?.node?.readAccess?.edges?.map(({ node }) => ({
        id: node?.id,
        name: node?.name
      }))

      const bucket = subAlert?.objects?.edges[0]?.node?.bucket?.edges[0]?.node
      const ticket = subAlert?.objects?.edges[0]?.node?.ticket?.edges[0]?.node
      const mail = subAlert?.objects?.edges[0]?.node?.mail?.edges[0]?.node
      const channel = subAlert?.channels?.edges[0]?.node
      const project = subAlert?.objects?.edges[0]?.node?.project?.edges[0]?.node

      let attachments = []
      if (ticket) {
        attachments = ticket?.attachments
      }
      if (mail) {
        attachments = mail?.attachments?.edges?.map(({ node }) => node) || []
      }

      const result: IAlertObjectsImpacted = {
        id: subAlert?.id,
        assignee: subAlert?.assignee || '',
        generatedAt: subAlert?.generatedAt || '',
        documentId: subAlert?.objects?.edges[0]?.node?.id || '',
        documentName: subAlert?.objects?.edges[0]?.node?.name || '',
        attributesList,
        entitiesList,
        groupsList,
        usersList,
        readAccessList: readAccess,
        writeAccessList: writeAccess,
        classification: subAlert?.objects?.edges[0]?.node?.classification || null,
        createdBy: subAlert?.objects?.edges[0]?.node?.owner || '',
        lastModified: subAlert?.objects?.edges[0]?.node?.lastModifiedTime || null,
        createdAt: subAlert?.objects?.edges[0]?.node?.createdAt || null,
        tableClusterName: subAlert?.objects?.edges[0]?.node?.tableClusterName || '',
        owner: subAlert?.objects?.edges[0]?.node?.owner || '',
        folder: subAlert.objects?.edges[0]?.node?.folder || {},
        objectLink: subAlert.objects?.edges[0]?.node?.objectLink || '',
        // common fields
        sharedOn: mail ? mail?.timestamp : ticket ? ticket?.lastModifiedTime : '',
        attachments,
        messageId: mail?.messageId,
        // buckets
        bucketName: bucket?.displayName || bucket?.name || '',
        bucketOwner: bucket?.ownerName || '',
        // tickets
        jiraTicketId: ticket?.ticketId || '',
        ticketId: ticket?.id || '',
        ticketName: ticket?.ticketName || '',
        ticketLink: ticket?.ticketLink || '',
        ticketReporter: ticket?.reporter?.name || '',
        ticketAssignee: ticket?.assignee?.name || '',
        projectName: (ticket || project)?.ticketStoreProject?.edges[0]?.node?.projectName || '',
        projectId: (ticket || project)?.ticketStoreProject?.edges[0]?.node?.id || '',
        // emails
        mailSubject: mail?.subject,
        mailFrom: mail?.sender?.email || '',
        mailTo: mail?.recipients?.map(({ email }) => email),
        type: mail?.type || '',
        refMailboxIds: mail?.refMailboxIds || [],
        // channels
        channelId: channel?.channelId || '',
        channelName: channel?.channelName || '',
        channelPiiCount: channel?.piiConversationsCount || 0,
        channelMembersCount: channel?.membersCount || 0,
        channelType: channel?.type,
        // access control
        users: subAlert?.objects?.edges[0]?.node?.userAccess?.edges?.map(({ node }) => node) || [],
        groups:
          subAlert?.objects?.edges[0]?.node?.groupAccess?.edges?.map(({ node }) => node) || [],
        userAccessCount: subAlert?.objects?.edges[0]?.node?.userAccess?.count || 0,
        groupAccessCount: subAlert?.objects?.edges[0]?.node?.groupAccess?.count || 0
      }

      return result
    })
    return { list, total: raw?.alert?.edges[0]?.node?.subAlerts?.count }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryAlertObjectsGroups = (params: AlertObjectsGroupParams): string => {
  const { alertId, user } = params

  const userFilter = user ? `user: "${user}"` : ''
  return gql`
    {
      alertGroupByUser(alertId: "${alertId}" ${userFilter}) {
        edges {
          node {
            user {
              email
            }
            alertMeta {
              id
              numActiveSubalerts
            }
          }
        }
      }
    }
  `
}
export const mapQueryAlertObjectsGroups = (raw: any): AlertObjectsGroup[] => {
  try {
    return raw?.alertGroupByUser?.edges?.map(({ node: group }) => ({
      owner: group?.user?.email,
      objectsCount: group?.alertMeta[0]?.numActiveSubalerts || 0
    }))
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryRulesetAttributes = (id?: string): string => {
  const idFragment = id ? `id: "${id}"` : ''

  return gql`
    {
      attributeSet(first: 999, ${idFragment}) {
        edges {
          node {
            id
            name
            type
            enabled
            systemDefined
            attributes {
              count
              edges {
                node {
                  id
                  name
                  sensitivityLabel
                }
              }
            }
          }
        }
      }
    }
  `
}
export const mapQueryRulesetAttributes = (
  raw: any
): { attributeSets: AttributeSet[]; attributesBySensitivity: AttributesBySensitivity } => {
  try {
    const attributesBySensitivity: AttributesBySensitivity = {}
    const attributeSets = raw?.attributeSet?.edges?.map(({ node: attrSet }) => {
      const attributes =
        attrSet?.attributes?.edges?.map(({ node: attr }) => {
          const attribute = {
            id: attr?.id,
            name: attr?.name,
            setId: attrSet?.id,
            sensitivityLabel: attr?.sensitivityLabel
          }

          return attribute
        }) || []

      return {
        id: attrSet?.id,
        name: attrSet?.name,
        enabled: attrSet?.enabled,
        description: attrSet?.description || '',
        type: attrSet?.type || '',
        attributesCount: attrSet?.attributes?.count || 0,
        attributes: sortAttributesByInstanceCountAndSensitivity(attributes, SORT_ORDER?.DESC)
      }
    })
    const sortedAttributeSets = sortByOrder<AttributeSet>(
      attributeSets,
      'instanceCount',
      SORT_ORDER?.DESC
    )?.sort((a, b) => +b?.enabled - +a?.enabled)

    const activeAttributeSets = attributeSets?.filter((set) => set?.enabled)
    const allAttributes = removeDuplicates<AttributeSetAttribute>(
      activeAttributeSets?.reduce((acc, set) => acc?.concat(set?.attributes), []),
      'id'
    )

    attributesBySensitivity[SENSITIVE_LABEL?.HIGH] = allAttributes?.filter(
      (attr) => attr?.sensitivityLabel === SENSITIVE_LABEL?.HIGH
    )
    attributesBySensitivity[SENSITIVE_LABEL?.MEDIUM] = allAttributes?.filter(
      (attr) => attr?.sensitivityLabel === SENSITIVE_LABEL?.MEDIUM
    )
    attributesBySensitivity[SENSITIVE_LABEL?.LOW] = allAttributes?.filter(
      (attr) => attr?.sensitivityLabel === SENSITIVE_LABEL?.LOW
    )

    return {
      attributeSets: sortedAttributeSets,
      attributesBySensitivity
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const mutationSaveArchivalLocation = (params: SaveArchivalLocationParams) => {
  return gql`
    mutation policyActionConfiguration {
      updatePolicyActionConfiguration(
        clientMutationId: "1"
        datasourceId: "${params?.id}"
        input: {
          polciyActionConfiguration: [
            {
              actionType: ARCHIVE_OBJECT
              configuration: { archiveConfiguration: { archiveLocation: "${params?.archiveLocation}" } }
            }
          ]
        }
      ) {
        clientMutationId
      }
    }
  `
}

export const queryAlertActionHistory = ({
  alertId,
  generatedAtStart,
  generatedAtEnd
}: ActionHistoryParams) => {
  const query = gql`
  {
    subAlert(id: "${alertId}", first: 1) {
      count
      edges {
        node {
          id
          assignee
          objects(first: 1) {
            edges {
              node {
                id
                name
              }
            }
          }
          triggeredTime
          generatedAt
          subAlertHistory(first: 10, dateFilter: {key: ${SubAlertDateFilterKey?.TriggeredTime}, start: ${generatedAtStart}, end: ${generatedAtEnd}}) {
            count
            edges {
              cursor
              node {
                alertHistory {
                  edges {
                    cursor
                    node {
                      id
                      triggeredBy
                      triggeredTimestamp
                      action
                      comment
                      config {
                        __typename
                        ... on ReassignConfig {
                          newAssignee
                          subAlertAssignmentStrategy {
                            globalStrategy
                          }
                        }
                        ... on MuteConfig {
                          muteTillTimestamp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }`

  return query
}
export const mapQueryAlertActionHistory = (data: any): ObjectAlertActionHistory => {
  return {
    object: data?.subAlert?.edges[0]?.node?.objects?.edges[0]?.node,
    alertHistory: data?.subAlert?.edges[0]?.node?.subAlertHistory?.edges?.map(
      (edge) => edge?.node?.alertHistory?.edges[0]?.node
    )
  }
}

export const queryInformationExtractionParams = () => {
  return gql`
    query IEM {
      attribute(isInformationExtraction: true, filter: { key: DATA_TYPE, values: ["DATE"] }) {
        edges {
          node {
            id
            name
            internalName
            enabled
          }
        }
      }
    }
  `
}

export const mapQueryInformationExtractionParams = (data: any): InformationExtractionParams[] => {
  if (!data?.attribute) return []

  const params = data?.attribute?.edges?.map((edge) => {
    return {
      id: edge?.node?.id,
      name: edge?.node?.name,
      enabled: edge?.node?.enabled
    }
  })

  return params
}
