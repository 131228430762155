import { PrivacyAssessmentsWidget, PrivacyRightsWidget } from './privacyOpsWidgetsSlice'
import { parameterizeArrayofObjects } from '../../../../../utils/graphqlUtil'
import { mapDsrStatusToApi } from '../../../../dsr/requestList/dsrRequestSlice'
import { PiaProcessFilter, ProcessReviewStatus } from '../../../../../constants'
import { gql } from 'graphql-request'

export const queryPrivacyOpsWidgetDsrSummary = (): string => {
  const dsrOpenedParams = parameterizeArrayofObjects([
    { key: 'STATUS', values: mapDsrStatusToApi.inProgress.split(',') }
  ])
  return gql`
    {
      dsrTotal: dsrRequest {
        count
      }
      dsrOpen: dsrRequest(filter: ${dsrOpenedParams}){
        count
      }
    }
  `
}
export const mapQueryPrivacyOpsWidgetDsrSummary = (raw: any): PrivacyRightsWidget => {
  return {
    dsrTotal: raw.dsrTotal?.count || 0,
    dsrOpen: raw.dsrOpen?.count || 0
  }
}

export const queryPrivacyOpsWidgetSummary = (): string => {
  return gql`
    {
      piaTotal: process(booleanFilter: [{ key: IS_SYSTEM_DEFINED, value: false }]) {
        count
      }
      piaNeedReview: process(
          filter: [{key: ASSESSMENT_REVIEW, values: ["${PiaProcessFilter.reviewPending}"]}],
          booleanFilter: [{ key: IS_SYSTEM_DEFINED,value: false }]
      ) {
        count
      }
      ropaTotal: ropaProcess(booleanFilter: [{ key: IS_SYSTEM_DEFINED, value: false }]) {
        count
      }
      ropaNeedReview: ropaProcess(
        filter: [{key: REVIEW_STATUS, values: ["${ProcessReviewStatus.reviewPastDue}", "${ProcessReviewStatus.reviewDue}"]}],
        booleanFilter: [{ key: IS_SYSTEM_DEFINED,value: false }]
      ) {
        count
      }
    }
  `
}
export const mapQueryPrivacyOpsWidgetSummary = (raw: any): PrivacyAssessmentsWidget => {
  return {
    piaAssessmentsTotal: raw.piaTotal?.count || 0,
    piaAssessmentsNeedReview: raw.piaNeedReview?.count || 0,
    ropaProcessesTotal: raw.ropaTotal?.count || 0,
    ropaProcessesNeedReview: raw.ropaNeedReview?.count || 0
  }
}

export const queryConsentManagementWidgetSummary = (): string => {
  return gql`
    query consentManagementTopSummary {
      consentStats(
        filter: { key: SOURCE, values: ["DATASOURCE_PREFERENCE", "PREFERENCE_CENTER"] }
      ) {
        edges {
          node {
            id
            consentStats {
              total
              optIn
              optOut
            }
          }
        }
      }
    }
  `
}

export const mapConsentManagementWidgetSummary = (raw: any) => {
  const edges = raw?.consentStats?.edges || []

  let optInConsentsCount = 0
  let optOutConsentsCount = 0
  let totalConsentsCount = 0

  edges.forEach((edge: any) => {
    const stats = edge?.node?.consentStats || {}
    optInConsentsCount += stats.optIn || 0
    optOutConsentsCount += stats.optOut || 0
    totalConsentsCount += stats.total || 0
  })

  return {
    optInConsentsCount,
    optOutConsentsCount,
    totalConsentsCount
  }
}
