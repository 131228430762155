import './print.less'
import PrintContentsPage, { TableContentsItem } from './components/pageContents'
import PrintOverviewPage from './pages/pageOverview'
import PrintAttributeListPage from './pages/pageAttributeList'
import PrintClassifications from './pages/pageClassifications'
import PrintPoliciesPage from './pages/pagePolicies'
import PrintMonitoredDataSources from './pages/pageMonitoredDataSources'
import { ACTION_PRINT_DS_SUMMARY, ACTION_PRINT_OVERVIEW } from './printSlice'
import { getActionState } from '../reducers/requestReducer'
import { IPolicyTypeForPrint, STATES, UserDetails } from '../interfaces'
import { RootState } from '../rootReducer'
import apiService from '../services/api/apiService'
import {
  ACTION_ATTRIBUTES_TABLE_FETCH_SENSITIVITY_SORTED,
  ACTION_TOP_ATTRIBUTES_FETCH
} from '../features/attributes/attributesSlice'
import { ACTION_DATABASES_BY_PII_TABLES } from '../features/databases/databasesSlice'
import {
  ACTION_CLASSIFICATIONS_GROUPED,
  ACTION_CLASSIFICATION_PRINT
} from '../features/classifications/classificationsSlice'
import {
  ACTION_FETCH_RULESET_DATASOURCES,
  ACTION_RULE_SET_SUMMARY
} from '../features/policies/policiesSlice'
import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { parse } from 'query-string'
import { connect } from 'react-redux'

interface IProps {
  userInfo?: UserDetails
  fetchDataSourcesOverviewState?: STATES
  fetchPrintDataSourcesSummaryState?: STATES
  fetchTopAttributesState?: STATES
  fetchDatabasesByPiiTablesState?: STATES
  fetchClassificationsGroupedState?: STATES
  fetchAttributesTableSensitivitySortedState?: STATES
  fetchClassificationsPrintState?: STATES
  fetchRulsetSummaryForPrintState?: STATES
  fetchRulsetDataSourcesState?: STATES
  policyTypes?: IPolicyTypeForPrint[]
}
const Print = ({
  userInfo,
  fetchDataSourcesOverviewState,
  fetchPrintDataSourcesSummaryState,
  fetchTopAttributesState,
  fetchDatabasesByPiiTablesState,
  fetchClassificationsGroupedState,
  fetchAttributesTableSensitivitySortedState,
  fetchClassificationsPrintState,
  fetchRulsetSummaryForPrintState,
  fetchRulsetDataSourcesState,
  policyTypes
}: IProps): React.ReactElement => {
  const intl = useIntl()
  const { requestId: pdfRequestId = '' } = parse(window.location.search) as {
    requestId: string
  }

  const tableContents: TableContentsItem[] = [
    { urlAnchor: '#overview', title: intl.formatMessage({ id: 'print.contents.overview' }) },
    { urlAnchor: '#data-sources', title: intl.formatMessage({ id: 'print.contents.datasources' }) },
    { urlAnchor: '#pii', title: intl.formatMessage({ id: 'print.contents.piiData' }) },
    {
      urlAnchor: '#classifications',
      title: intl.formatMessage({ id: 'print.contents.classifications' })
    },
    { urlAnchor: '#policy', title: intl.formatMessage({ id: 'print.contents.policies' }) }
  ]

  useEffect(() => {
    const isAuthenticated = userInfo && Object.keys(userInfo).length !== 0
    const statesToCheck: any = [
      fetchDataSourcesOverviewState,
      fetchPrintDataSourcesSummaryState,
      fetchTopAttributesState,
      fetchDatabasesByPiiTablesState,
      fetchClassificationsGroupedState,
      fetchAttributesTableSensitivitySortedState,
      fetchClassificationsPrintState,
      fetchRulsetSummaryForPrintState,
      ...(policyTypes?.length ? [fetchRulsetDataSourcesState] : [])
    ]

    if (statesToCheck.every((state) => state === STATES.success) && isAuthenticated) {
      /* Share success pdf status */
      apiService.postPdfDownloadError(pdfRequestId, {}, 'success')
    }
  }, [
    userInfo,
    fetchDataSourcesOverviewState,
    fetchPrintDataSourcesSummaryState,
    fetchTopAttributesState,
    fetchDatabasesByPiiTablesState,
    fetchClassificationsGroupedState,
    fetchAttributesTableSensitivitySortedState,
    fetchClassificationsPrintState,
    fetchRulsetSummaryForPrintState,
    fetchRulsetDataSourcesState
  ])

  return (
    <div className="report" data-test-id="print">
      <div className="page-break" />

      <PrintContentsPage tableContents={tableContents} />
      <div className="page-break" />

      <PrintOverviewPage />
      <div className="page-break" />

      <PrintMonitoredDataSources />
      <div className="page-break" />

      <PrintAttributeListPage />
      <div className="page-break" />

      <PrintClassifications />
      <div className="page-break" />

      <PrintPoliciesPage />
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  /* States for PrintOverviewPage */
  userInfo: state.user.userInfo,
  fetchDataSourcesOverviewState: getActionState(ACTION_PRINT_OVERVIEW),

  /* States for PrintMonitoredDataSources */
  fetchPrintDataSourcesSummaryState: getActionState(ACTION_PRINT_DS_SUMMARY),
  fetchTopAttributesState: getActionState(ACTION_TOP_ATTRIBUTES_FETCH),
  fetchDatabasesByPiiTablesState: getActionState(ACTION_DATABASES_BY_PII_TABLES),
  fetchClassificationsGroupedState: getActionState(ACTION_CLASSIFICATIONS_GROUPED),

  /* States for PrintAttributeListPage */
  fetchAttributesTableSensitivitySortedState: getActionState(
    ACTION_ATTRIBUTES_TABLE_FETCH_SENSITIVITY_SORTED
  ),

  /* States for PrintClassifications */
  fetchClassificationsPrintState: getActionState(ACTION_CLASSIFICATION_PRINT),

  /* States for PrintPoliciesPage */
  fetchRulsetSummaryForPrintState: getActionState(ACTION_RULE_SET_SUMMARY),
  fetchRulsetDataSourcesState: getActionState(ACTION_FETCH_RULESET_DATASOURCES),
  policyTypes: state.policies.policyTypeForPrint
})

export default connect(mapStateToProps, null)(Print)
