import {
  mapQueryPrivacyOpsProcessGroups,
  mapQueryPrivacyOpsProcesses,
  mapQueryPrivacyOpsReportGroups,
  mapQueryPrivacyOpsReports,
  queryPrivacyOpsProcessGroups,
  queryPrivacyOpsProcesses,
  queryPrivacyOpsReportGroups,
  queryPrivacyOpsReports
} from './queries'
import graphqlService from '../../services/graphqlService'
import { DATA_SOURCE_TYPES, PAGE, PrivacyFeatureType } from '../../constants'
import { FilterParams } from '../../interfaces'
import { NameIdSummary } from '../pia/piaSlice'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export interface PrivacyOpsReport {
  id: string
  name: string
  status?: string
  createdAt: string
  generatedOn?: string
  processName?: string
  processOwner?: string
  processGroups?: string[]
  dataSources?: {
    dataSourceName: string
    dataSourceType: DATA_SOURCE_TYPES
    status: string
    isRemote: boolean
  }[]
  dpo?: string
}

export type PrivacyOpsReportsParams = {
  [PAGE]?: number
  processId?: string
  filters?: FilterParams
}
export const ACTION_FETCH_PRIVACY_OPS_REPORTS = 'privacyOpsReports/list'
export const fetchPrivacyOpsReports = createAsyncThunk(
  ACTION_FETCH_PRIVACY_OPS_REPORTS,
  async (params: PrivacyOpsReportsParams) => {
    const raw = await graphqlService.execute(queryPrivacyOpsReports(params))
    return mapQueryPrivacyOpsReports(raw)
  }
)

// Report cards
export type PrivacyOpsReportGroup = {
  processGroupId: string
  processGroupName: string
  totalReports: number
}
export const ACTION_FETCH_PRIVACY_OPS_REPORT_GROUPS = 'privacyOpsReports/groups'
export const fetchPrivacyOpsReportGroups = createAsyncThunk(
  ACTION_FETCH_PRIVACY_OPS_REPORT_GROUPS,
  async (params: PrivacyOpsReportsParams) => {
    const raw = await graphqlService.execute(queryPrivacyOpsReportGroups(params))
    return mapQueryPrivacyOpsReportGroups(raw)
  }
)

// filters
export type PrivacyOpsFilterParams = {
  type: PrivacyFeatureType
}
export const ACTION_PRIVACY_OPS_PROCESS_GROUPS = 'privacyOpsReports/process-groups'
export const fetchPrivacyOpsProcessGroups = createAsyncThunk(
  ACTION_PRIVACY_OPS_PROCESS_GROUPS,
  async (params: PrivacyOpsReportsParams) => {
    const raw = await graphqlService.execute(queryPrivacyOpsProcessGroups(params))
    return mapQueryPrivacyOpsProcessGroups(raw)
  }
)

export const ACTION_PRIVACY_OPS_PROCESSES = 'privacyOpsReports/processes'
export const fetchPrivacyOpsProcesses = createAsyncThunk(
  ACTION_PRIVACY_OPS_PROCESSES,
  async (props: PrivacyOpsFilterParams) => {
    const result = await graphqlService.execute(queryPrivacyOpsProcesses(props))
    return mapQueryPrivacyOpsProcesses(result, props)
  }
)

interface PrivacyOpsReportsState {
  list?: PrivacyOpsReport[]
  total?: number
  groups?: PrivacyOpsReportGroup[]
  groupsTotal?: number
  filters: {
    processGroups?: PrivacyOpsReportGroup[]
    processOwners?: string[]
    processes?: NameIdSummary[]
  }
}

export const initialState: PrivacyOpsReportsState = {
  filters: {}
}

const privacyOpsReportsSlice = createSlice({
  name: 'privacyOpsReports',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPrivacyOpsReports.fulfilled, (state, { payload }) => {
      state.list = payload.list
      state.total = payload.total
    })
    builder.addCase(fetchPrivacyOpsReportGroups.fulfilled, (state, { payload }) => {
      state.groups = payload.list
      state.groupsTotal = payload.total
    })
    builder.addCase(fetchPrivacyOpsProcessGroups.fulfilled, (state, { payload }) => {
      state.filters.processGroups = payload.list
    })
    builder.addCase(fetchPrivacyOpsProcesses.fulfilled, (state, { payload }) => {
      state.filters.processes = payload.processes
      state.filters.processOwners = payload.owners
    })
  }
})

export default privacyOpsReportsSlice.reducer
