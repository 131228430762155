import { ChartColors } from '../../dashboards/mainDashboardv2/constants'

import SvgAzureIdp from '../../../assets/inlineSvg/icon-access-control-azure-idp.svg'
import SvgCSVIdp from '../../../assets/inlineSvg/icon-access-control-csv-idp.svg'
import SvgGoogleIdp from '../../../assets/inlineSvg/icon-google.svg'
import { DATA_SOURCE_TYPES } from '../../../constants'

const ENTITY_TYPE_MAP = {
  Human: 'icon-human',
  Organization: 'icon-organisation'
}

export const getEntityTypeImage = (type: string, basepath: string): string => {
  const iconVal = ENTITY_TYPE_MAP[type]
  if (iconVal) {
    return basepath + iconVal + '.svg'
  } else {
    return basepath + ENTITY_TYPE_MAP.Human + '.svg'
  }
}

export const ENTITY_TYPES_PIE_COLORS = [
  ChartColors.Purple,
  ChartColors.Blue,
  ChartColors.Green,
  ChartColors.Yellow,
  ChartColors.DarkGreen,
  ChartColors.Indigo
]

export enum DirectoryOptions {
  OnPremise = 'ACTIVE_DIRECTORY',
  Hosted = 'AZURE_ACTIVE_DIRECTORY',
  CSV = 'CSV_IMPORT_BASED',
  Google = 'GOOGLE_WORKSPACE'
}

export const CreateDirectoryServiceOptios = [
  {
    id: DirectoryOptions.OnPremise,
    icon: SvgAzureIdp
  },
  {
    id: DirectoryOptions.Hosted,
    icon: SvgAzureIdp
  },
  {
    id: DirectoryOptions.Google,
    icon: SvgGoogleIdp
  },
  {
    id: DirectoryOptions.CSV,
    icon: SvgCSVIdp,
    disabled: true
  }
]

export enum DirectoryCreationSteps {
  selectDirectory = 0,
  connection,
  scanSettings
}

export enum FREQUENCY_TYPES {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH'
}

export enum DAY_OF_WEEK {
  SUNDAY = 'SUN',
  MONDAY = 'MON',
  TUESDAY = 'TUE',
  WEDNESDAY = 'WED',
  THURSDAY = 'THU',
  FRIDAY = 'FRI',
  SATURDAY = 'SAT'
}

export const FREQUENCY_OPTIONS: Array<{
  key: string
  label: string
  value: FREQUENCY_TYPES
}> = [
  { key: 'day', value: FREQUENCY_TYPES.DAY, label: 'Day(s)' },
  { key: 'week', value: FREQUENCY_TYPES.WEEK, label: 'Week(s)' },
  { key: 'month', value: FREQUENCY_TYPES.MONTH, label: 'Month(s)' }
]

export const DAY_OF_MONTH_OPTIONS = Array.from({ length: 30 }, (_, i) => {
  const value = i + 1

  return {
    key: value,
    value: value,
    label: `Day ${value}`
  }
})

export const SECRET_PLACEHOLDER = '**********'
export const SECRET_ENDPOINT = '**.**.**.**'
export const SECRET_USERNAME = '****@***.***'

export const DATASOURCE_TYPES_MAPPER = {
  [DirectoryOptions.OnPremise]: [DATA_SOURCE_TYPES.smb],
  [DirectoryOptions.Hosted]: [
    DATA_SOURCE_TYPES.smb,
    DATA_SOURCE_TYPES.oneDrive,
    DATA_SOURCE_TYPES.sharePoint
  ],
  [DirectoryOptions.Google]: [DATA_SOURCE_TYPES.gdrive, DATA_SOURCE_TYPES.gmail]
}
