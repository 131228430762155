import { DSRRequestDetails, FetchDsrDataSourcesParams } from './requestDetailsSlice'
import { AssigTicketParams } from './components/assignTicket/assignTicket'
import {
  attributeDetailsParams,
  MarkValidatedParams
} from './components/markAsValidated/markAsValidatedModal'
import { DataSource } from '../../../services/api/apiTypes'
import { getAfterCursor, parameterizeArrayofObjects } from '../../../utils/graphqlUtil'
import { DSR_DATASOURCES_LIMIT_DEFAULT } from '../../../constants'
import { AddAttributeManuallyParams, DsrAttributeActionTypes } from '../tickets/dsrTicketsSlice'
import { DsrRequestType } from '../../../services/graphqlSchemaTypes'
import { stringEscape } from '../../../utils/stringUtil'
import { gql } from 'graphql-request'

export const queryDSRRequestDetails = (dsrId: string): string => {
  return gql`
  {
    dsrDetails(dsrId: ${dsrId}) {
      id
      requestType
      admin {
        id
        firstName
        lastName
        email
      }
      location
      lastUpdate
      dataSubject {
        id
        firstName
        lastName
        email
        residency
        address
        phoneNumber
        uniqueID
        dob
      }
      dueDate
      assignee
      idVerification {
        verificationEmailSentOn
        verificationCompletedOn
        idVerificationStatus
        approvedBy {
          id
          firstName
          lastName
          email
        }
        approvedOn
        attachments {
          id
          name
          type
        }
      }
    }
  }
`
}

export const mapQueryRequestDetails = (raw: any): DSRRequestDetails => {
  return raw
}

export const queryDsrDataSourcesList = ({
  filters,
  entityId
}: FetchDsrDataSourcesParams): string => {
  let commonParamString = ''
  const filterString = parameterizeArrayofObjects(filters?.filter || [])
  if (filterString) {
    commonParamString += `,filter:${filterString}`
  }
  const count360Query = entityId
    ? `attributeInstance(entityId: "${entityId}"${commonParamString}){
    count
  }`
    : ''

  return gql`
    {
      datasources(first: 999) {
        count
        edges {
          node {
            id
            name
            type
            createdBy
            ${count360Query}
          }
        }
      }
    }
  `
}

export const mapQueryDsrDataSourcesList = (raw: any): { list: DataSource[]; total: number } => {
  const datasourcesList = raw.datasources?.edges?.map((edge) => {
    const ds = edge.node
    return {
      id: ds.id,
      createdBy: ds.createdBy,
      dataSourceType: ds.type || '',
      dataSourceName: ds.name || '',
      instancesDetectedCount: ds.attributeInstance?.count || 0
    }
  })

  return {
    list: datasourcesList,
    total: raw.datasources.count || 0
  }
}

export const queryDefaultDataSources = ({
  filters,
  entityId,
  page,
  dsrRequestId,
  dsrDataSourceFilters,
  searchQuery
}: FetchDsrDataSourcesParams) => {
  let commonParamString = ''
  const filterString = parameterizeArrayofObjects(filters?.filter || [])
  if (filterString) {
    commonParamString += `,filter:${filterString}`
  }
  const count360Query = entityId
    ? `attributeInstance(entityId: "${entityId}"${commonParamString}){
    count
  }`
    : ''

  const cursor = getAfterCursor(page || 1, DSR_DATASOURCES_LIMIT_DEFAULT)
  return gql`
    {
      dsrSetting(
        dsrRequestId: "${dsrRequestId}"
        ${
          dsrDataSourceFilters?.attributeDetected !== undefined
            ? `attributeDetected: ${dsrDataSourceFilters?.attributeDetected}`
            : ''
        }
        ${
          dsrDataSourceFilters?.attributeValidated !== undefined
            ? `attributeValidated: ${dsrDataSourceFilters?.attributeValidated}`
            : ''
        }
        ${
          dsrDataSourceFilters?.assignee
            ? `assignee: ${JSON.stringify(dsrDataSourceFilters?.assignee)}`
            : ''
        }
        ${
          dsrDataSourceFilters?.dataValidationStatus
            ? `dataValidationStatus: ${JSON.stringify(dsrDataSourceFilters?.dataValidationStatus)}`
            : ''
        }
      ) {
        edges {
          node {
            defaultDsrDatasource(first: ${DSR_DATASOURCES_LIMIT_DEFAULT}, after: "${cursor}" ${
    searchQuery ? `, searchName: "${searchQuery}"` : ``
  }) {
              count
              edges {
                node {
                  id
                  name
                  type
                  createdBy
                  ${count360Query}
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapQueryDsrDefaultDataSources = (
  raw: any
): { defaultDatasources: DataSource[]; defaultDatasourcesCount: number } => {
  const defaultdatasourcesList = raw.dsrSetting?.edges[0].node.defaultDsrDatasource

  const datasourcesList = defaultdatasourcesList?.edges?.map((edge) => {
    const ds = edge.node
    return {
      id: ds.id,
      createdBy: ds.createdBy,
      dataSourceType: ds.type || '',
      dataSourceName: ds.name || '',
      instancesDetectedCount: ds.attributeInstance?.count || 0
    }
  })

  return {
    defaultDatasources: datasourcesList,
    defaultDatasourcesCount: defaultdatasourcesList.count || 0
  }
}

export const getDatasourceOptionForManualAttributes = (requestId: string) => {
  return gql`
    {
      dsrSetting(dsrRequestId: "${requestId}") {
        edges {
          node {
            defaultDsrDatasource{
              count
              edges {
                node {
                  id
                  name
                  type
                  createdBy
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapDatasourceOptionsForManualAttributes = (raw: any) => {
  const dsrSetting = raw.dsrSetting.edges[0].node.defaultDsrDatasource
  if (dsrSetting) {
    const datasourceOptions: DataSource[] = dsrSetting.edges.map((dsr: any) => {
      return {
        id: dsr.node.id,
        name: dsr.node.name,
        type: dsr.node.type,
        createdBy: dsr.node.createdBy
      }
    })
    return datasourceOptions
  }
  return []
}

export const mutationSelfServeAssignNewTicket = (params: AssigTicketParams): string => {
  return gql`
    mutation {
      selfServeAssignNewTicket(clientMutationId: "assign-self-serve-ticket", input: {
        attributeInstanceIds: ${JSON.stringify(params.attributeInstanceIds)}
        dueDate: ${params.dueDate}
        emailBody: ${JSON.stringify(params.emailBody)}
      }) {
        clientMutationId
      }
    }
  `
}

export const mutationSelfServeMarkAsValidated = (params: MarkValidatedParams): string => {
  return gql`
    mutation {
      validateDsrSelectedAttributes(clientMutationId: "mark-self-serve-validated", input: {
        dsrRequestId: "${params.dsrRequestId}"
        attributeInstanceId: ${JSON.stringify(params.attributeInstanceIds)}
        action: ${params.action}
        ${params?.remark ? `remark: "${stringEscape(params.remark || '')}"` : ''}
        ${params.reasons.length > 0 ? `reason: ${JSON.stringify(params.reasons)}` : ''}
        assignee: "${params.assignee}"
        ${
          params.action === DsrAttributeActionTypes.valueNotFound
            ? `dataMismatch: ${params.dataMismatch}`
            : ''
        }
      }) {
        clientMutationId
      }
    }
  `
}

export const queryAttributeDetailsforWarning = ({
  dsrRequestId,
  attributeInstanceIds
}: attributeDetailsParams) => {
  return gql`
    {
      dsrRequestAttribute(filter: [{ key: DSR_REQUEST_ID, values: "${dsrRequestId}" },{key:ID,values:${JSON.stringify(
    attributeInstanceIds
  )}}]) {
        edges {
          node {
            attributeValue
            attributeTypeName
            dsrTicket(filter: [{key: STATUS, values: "ticketAssigned"}]) {
              count
            }
          }
        }
      }
    }
  `
}

export const mapAttributeDetailsforWarning = (raw: any): number => {
  if (!raw || !raw.dsrRequestAttribute || !raw.dsrRequestAttribute.edges) {
    return 0
  }

  let attributesWithTicketsCount = 0

  raw.dsrRequestAttribute.edges.forEach((attributeEdge: any) => {
    const dsrTicketCount = attributeEdge.node.dsrTicket.count

    // If at least one ticket exists for this attribute, increment the count
    if (dsrTicketCount > 0) {
      attributesWithTicketsCount++
    }
  })

  return attributesWithTicketsCount
}

export const queryAttributeDetailsforAssignTicketWarning = ({
  dsrRequestId,
  attributeInstanceIds
}: attributeDetailsParams) => {
  return gql`
    {
      dsrRequestAttribute(filter: [{ key: DSR_REQUEST_ID, values: "${dsrRequestId}" },{key:ID,values:${JSON.stringify(
    attributeInstanceIds
  )}}]) {
        edges {
          node {
            attributeValidatedId
            dsrTicket(filter: [{key: STATUS, values: "ticketAssigned"}]) {
              count
            }
          }
        }
      }
    }
  `
}

export const mapAttributeDetailsforAssignTicketWarning = (raw: any): number => {
  if (!raw || !raw.dsrRequestAttribute || !raw.dsrRequestAttribute.edges) {
    return 0
  }

  let assignedOrValidatedAttributesCount = 0

  raw.dsrRequestAttribute.edges.forEach((attributeEdge: any) => {
    const dsrTicketCount = attributeEdge.node.dsrTicket.count

    // If at least one ticket exists for this attribute, increment the count
    if (dsrTicketCount > 0 || attributeEdge.node.attributeValidatedId) {
      assignedOrValidatedAttributesCount++
    }
  })

  return assignedOrValidatedAttributesCount
}

export const queryMutationSelfServeAddAttributesManually = ({
  ticketData,
  requestType,
  requestId,
  assignee,
  isUpdate = false
}: AddAttributeManuallyParams) => {
  const isAccess = requestType === DsrRequestType.Access
  const isOther = requestType === DsrRequestType.Other
  const isDoNotSell = requestType === DsrRequestType.DoNotSell
  const isRectification = requestType === DsrRequestType.Rectification

  const getMismatchString = (action) =>
    isAccess || isOther || isDoNotSell ? `dataMismatch: ${action.dataMismatch || 'false'},` : ''

  try {
    const actionsFragment = ticketData
      .map((ticketAction) => {
        const {
          reason = [],
          attributeId,
          action,
          origin,
          remark,
          attributeInstanceId,
          attributeValidatedId,
          attributeValue,
          previousValue,
          manualValue,
          datasourceId
        } = ticketAction
        const reasonsArr = reason.map((reasonValue) => '"' + reasonValue + '"') || []
        return `{
          attributeId: "${attributeId}",
          action: ${action},
          origin: ${origin || 'system'},
          ${remark ? `remark: "${stringEscape(remark || '')}"` : ''},
          datasourceId: "${datasourceId}",
          ${getMismatchString(ticketAction)}
          ${isUpdate && attributeInstanceId ? `attributeInstanceId: "${attributeInstanceId}",` : ''}
          ${
            isUpdate && attributeValidatedId
              ? `attributeValidatedId: "${attributeValidatedId}",`
              : ''
          }
          ${
            attributeValue
              ? `attributeValue: "${attributeValue?.replace(/(\r\n|\n|\r)/gm, '') || ''}"`
              : 'attributeValue: ""'
          }
          ${previousValue?.trim() ? `previousValue: "${previousValue}"` : ''}
          ${
            origin == 'manual' && manualValue
              ? `attributeValue: "${manualValue?.replace(/(\r\n|\n|\r)/gm, '') || ''}",`
              : ''
          }
          ${isRectification && !previousValue?.trim() ? `previousValue: ""` : ''}
          ${reason && reason.length > 0 ? `reason: [${reasonsArr.join(',')}]` : ''}
        }`
      })
      .join()

    return gql`
    mutation {
        updateDsrReportAttributeRemark(
          clientMutationId: "dsr-self-serve-add-attributes-manually"
          input: {
            dsrRequestId: "${requestId}"
            attributeActions: [
              ${actionsFragment}
            ]
            assignee: "${assignee}"
          }
        ){
        clientMutationId
        }
      }

    `
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const fetchDsrSelfServeAssignTicketDsList = (params?: any) => {
  const { ...listFilters } = params

  const filterString = parameterizeArrayofObjects(listFilters.filters?.filter || [])

  let commonParamString = ''
  if (filterString) {
    commonParamString += `filter:${filterString}`
  }
  return gql`
    {
      dsrRequestAttributeDatasource(${commonParamString}) {
        count
        edges {
          node {
            datasource {
              edges {
                node {
                  id
                  name
                  type
                  createdBy
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapDsrSelfServeAssignTicketDsList = (raw: any): DataSource[] => {
  const datasourceList = raw.dsrRequestAttributeDatasource.edges
  if (datasourceList) {
    const datasources: DataSource[] = datasourceList.map((ds: any) => {
      const selectedDs = ds.node.datasource.edges[0].node
      return {
        id: selectedDs.id,
        name: selectedDs.name,
        type: selectedDs.type,
        createdBy: selectedDs.createdBy
      }
    })
    return datasources
  }
  return []
}

export const getDSRRequestValidatedDataAndReportCount = (dsrRequestId: string) => {
  return gql`
    {
      dsrRequestAttribute(filter: [{ key: VALIDATION_STATUS, values: "VALIDATED" },{ key: DSR_REQUEST_ID, values: "${dsrRequestId}"}]) {
        count
      }
      dsrReport(filter:[{key:REQUEST_ID,values:"${dsrRequestId}"}]) {
        count
      }
    }
  `
}

export const mapDSRRequestValidatedDataAndReportCount = (
  raw: any
): { validatedDatacount: number; reportCount: number } => {
  return {
    validatedDatacount: raw?.dsrRequestAttribute?.count || 0,
    reportCount: raw?.dsrReport?.count || 0
  }
}
