import './main.less'
import PrintOverviewPage from './pages/overview'
import SensitiveFiles from './pages/sensitiveFiles'
import DataSourcesList, { datasourceTypeEnum } from './pages/datasourcesList'
import SensitiveAttributes from './pages/highSensitiveAttributes'
import AttributesHighTotal from './pages/attributesHighTotal'
import SensitiveTables from './pages/sensitiveTables'
import SensitiveColumns from './pages/sensitiveColumns'
import DatasourceOverview from './pages/datasourceOverview'
import PrintClassifications from '../pages/pageClassifications'
import PrintPoliciesPage from '../pages/pagePolicies'
import PrintAttributeListPage from '../pages/pageAttributeList'
import PrintContentsPage from '../components/multiPageContents'
import { HistoricalStats, fetchDeltaPrintDataSourcesSummary } from '../printSlice'
import { RootState } from '../../rootReducer'
import { DataSourcesParams, fetchDataSources } from '../../features/dataSources/dataSourcesSlice'
import { getDataSourcesAllListSorted } from '../../features/dataSources/dataSourceSelectors'
import { DataSource } from '../../services/api/apiTypes'
import {
  DATA_SOURCE_TYPES,
  FILTER_DATA_SOURCE_TYPE_KEY,
  STRUCTURED_DATASOURCES,
  URL_DATA_SOURCES
} from '../../constants'
import useUrlContext from '../../hooks/useUrlContext'
import { formatFiltersToApiUrlParams } from '../../utils/urlUtil'
import { TableContentsItem } from '../components/pageContents'
import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'

interface IProps {
  historicalStats?: HistoricalStats
  fetchDeltaPrintDataSourcesSummary: (datasourceIds: string[]) => void
  fetchDataSources: (filters: DataSourcesParams) => void
  list?: DataSource[]
}

const Main = ({
  historicalStats,
  fetchDeltaPrintDataSourcesSummary,
  fetchDataSources,
  list
}: IProps): React.ReactElement => {
  const intl = useIntl()
  useEffect(() => {
    if (list && list.length) {
      const unstructuredDatasourcesList = getFilteredList(datasourceTypeEnum.UNSTRUCTURED)
      fetchDeltaPrintDataSourcesSummary(unstructuredDatasourcesList.map((ds) => ds.id || ''))
    }
  }, [list, fetchDeltaPrintDataSourcesSummary])
  const context = useUrlContext({ pageName: URL_DATA_SOURCES })
  const pageFilters = formatFiltersToApiUrlParams(context.filters)
  const dataSourceType = pageFilters[FILTER_DATA_SOURCE_TYPE_KEY]
  useEffect(() => {
    fetchDataSources({ dataSourceType })
  }, [dataSourceType, fetchDataSources])

  const tableContentsCommonParams = {
    className: 'delta-contents-item'
  }
  const tableSubmenuCommonParams = {
    className: 'delta-submenu-item'
  }
  const dataSourcePagesAnchor = (list || []).reduce(
    (acc: { urlAnchor: string; title: string }[], ds) => {
      if (
        ds.dataSourceType !== DATA_SOURCE_TYPES.generic &&
        ds.dataSourceType !== DATA_SOURCE_TYPES.remote
      ) {
        if (STRUCTURED_DATASOURCES.includes(ds.dataSourceType as DATA_SOURCE_TYPES)) {
          return [
            ...acc,
            {
              urlAnchor: '#datasource-overview' + ds.id + 'Structured',
              title: 'Datasource - ' + ds.dataSourceName,
              subMenu: [
                {
                  urlAnchor: '#sensitive-tables' + ds.id,
                  title: 'Tables with sensitive data',
                  ...tableSubmenuCommonParams
                },
                {
                  urlAnchor: '#sensitive-columns' + ds.id,
                  title: 'Columns with sensitive data',
                  ...tableSubmenuCommonParams
                }
              ],
              ...tableContentsCommonParams
            }
          ]
        } else {
          return [
            ...acc,
            {
              urlAnchor: '#datasource-overview' + ds.id + 'Unstructured',
              title: 'Datasource - ' + ds.dataSourceName,
              subMenu: [
                {
                  urlAnchor: '#sensitive-files' + ds.id,
                  title: 'Total Files',
                  ...tableSubmenuCommonParams
                },
                {
                  urlAnchor: '#sensitive-attributes' + ds.id,
                  title: 'Sensitive Data Count: High Sensitivity',
                  ...tableSubmenuCommonParams
                },
                {
                  urlAnchor: '#attribute-high-total' + ds.id,
                  title: 'Sensitive Data Count: Total v/s High',
                  ...tableSubmenuCommonParams
                }
              ],
              ...tableContentsCommonParams
            }
          ]
        }
      }
      return acc
    },
    []
  )
  const getFilteredList = (datasourceType?: string) => {
    return (list || []).filter((ds) => {
      if (
        ds.dataSourceType !== DATA_SOURCE_TYPES.generic &&
        ds.dataSourceType !== DATA_SOURCE_TYPES.remote
      ) {
        if (datasourceType === datasourceTypeEnum.STRUCTURED) {
          if (STRUCTURED_DATASOURCES.includes(ds.dataSourceType as DATA_SOURCE_TYPES)) {
            return true
          } else {
            return false
          }
        } else if (datasourceType === datasourceTypeEnum.UNSTRUCTURED) {
          if (!STRUCTURED_DATASOURCES.includes(ds.dataSourceType as DATA_SOURCE_TYPES)) {
            return true
          } else {
            return false
          }
        } else {
          return true
        }
      }
      return false
    })
  }
  const allDSIndex = () => {
    if (getFilteredList()?.length) {
      return [
        {
          urlAnchor: '#ds-listUnstructured',
          title: 'Unstructured datasources',
          subMenu: [
            { urlAnchor: '#sensitive-files', title: 'Total Files', ...tableSubmenuCommonParams },
            {
              urlAnchor: '#sensitive-attributes',
              title: 'Sensitive Data Count: High Sensitivity',
              ...tableSubmenuCommonParams
            },
            {
              urlAnchor: '#attribute-high-total',
              title: 'Sensitive Data Count: Total v/s High',
              ...tableSubmenuCommonParams
            }
          ],
          ...tableContentsCommonParams
        },
        {
          urlAnchor: '#ds-listStructured',
          title: 'Structured datasources',
          subMenu: [
            {
              urlAnchor: '#sensitive-tables',
              title: 'Tables with sensitive data',
              ...tableSubmenuCommonParams
            },
            {
              urlAnchor: '#sensitive-columns',
              title: 'Columns with sensitive data',
              ...tableSubmenuCommonParams
            }
          ],
          ...tableContentsCommonParams
        }
      ]
    }
    return []
  }

  const tableContents: TableContentsItem[] = [
    {
      urlAnchor: '#overview',
      title: intl.formatMessage({ id: 'print.contents.overview' }),
      ...tableContentsCommonParams
    },
    ...allDSIndex(),
    {
      urlAnchor: '#pii',
      title: intl.formatMessage({ id: 'print.contents.piiData' }),
      ...tableContentsCommonParams
    },
    {
      urlAnchor: '#classifications',
      title: intl.formatMessage({ id: 'print.contents.classifications' }),
      ...tableContentsCommonParams
    },
    {
      urlAnchor: '#policy',
      title: intl.formatMessage({ id: 'print.contents.policies' }),
      ...tableContentsCommonParams
    },
    ...dataSourcePagesAnchor
  ]

  const unstructuredCommonParams = {
    subText: 'Unstructured datasources'
  }
  const structuredCommonParams = {
    subText: 'Structured datasources'
  }

  return (
    <div className="report delta-report">
      <div className="page-break" />
      <PrintContentsPage tableContents={tableContents} />
      <div className="page-break" />
      <PrintOverviewPage historicalStats={historicalStats} />
      {getFilteredList(datasourceTypeEnum.UNSTRUCTURED).length ? (
        <>
          <div className="page-break" />
          <DataSourcesList datasourcesList={list} />
          <div className="page-break" />
          <SensitiveFiles historicalStats={historicalStats} {...unstructuredCommonParams} />
          <div className="page-break" />
          <SensitiveAttributes
            {...unstructuredCommonParams}
            unstructDatasourcesList={getFilteredList(datasourceTypeEnum.UNSTRUCTURED)}
          />
          <div className="page-break" />
          <AttributesHighTotal
            historicalStats={historicalStats}
            {...unstructuredCommonParams}
            unstructDatasourcesList={getFilteredList(datasourceTypeEnum.UNSTRUCTURED)}
          />
        </>
      ) : (
        ''
      )}
      {getFilteredList(datasourceTypeEnum.STRUCTURED).length ? (
        <>
          <div className="page-break" />
          <DataSourcesList datasourceType={datasourceTypeEnum.STRUCTURED} datasourcesList={list} />
          <div className="page-break" />
          <SensitiveTables {...structuredCommonParams} />
          <div className="page-break" />
          <SensitiveColumns {...structuredCommonParams} />
        </>
      ) : (
        ''
      )}
      <div className="page-break" />
      <PrintAttributeListPage />
      <div className="page-break" />
      <PrintClassifications />
      <PrintPoliciesPage />
      {list?.map((ds, index) => {
        if (
          ds.dataSourceType !== DATA_SOURCE_TYPES.generic &&
          ds.dataSourceType !== DATA_SOURCE_TYPES.remote
        ) {
          const commonParams = {
            datasourceId: ds.id,
            datasourceName: ds.dataSourceName,
            datasourceType: ds.dataSourceType || DATA_SOURCE_TYPES.generic,
            datasourceOwner: ds.createdBy,
            risky: (ds.alertsCount || 0) > 0,
            status: ds.status
          }
          if (STRUCTURED_DATASOURCES.includes(ds.dataSourceType as DATA_SOURCE_TYPES)) {
            return (
              <div key={index}>
                <div className="page-break" />
                <DatasourceOverview datasourceGroup={'Structured'} {...commonParams} />
                <div className="page-break" />
                <SensitiveTables datasourceId={ds.id} datasourceName={ds.dataSourceName} />
                <div className="page-break" />
                <SensitiveColumns datasourceId={ds.id} datasourceName={ds.dataSourceName} />
              </div>
            )
          } else {
            return (
              <div key={index}>
                <div className="page-break" />
                <DatasourceOverview datasourceGroup={'Unstructured'} {...commonParams} />
                <div className="page-break" />
                <SensitiveFiles
                  historicalStats={historicalStats}
                  datasourceId={ds.id}
                  datasourceName={ds.dataSourceName}
                  datasourceType={ds.dataSourceType || DATA_SOURCE_TYPES.generic}
                />
                <div className="page-break" />
                <SensitiveAttributes
                  datasourceId={ds.id}
                  datasourceName={ds.dataSourceName}
                  datasourceType={ds.dataSourceType || DATA_SOURCE_TYPES.generic}
                />
                <div className="page-break" />
                <AttributesHighTotal
                  historicalStats={historicalStats}
                  datasourceId={ds.id}
                  datasourceName={ds.dataSourceName}
                  datasourceType={ds.dataSourceType || DATA_SOURCE_TYPES.generic}
                />
              </div>
            )
          }
        }
        return ''
      })}
    </div>
  )
}
const mapStateToProps = (state: RootState) => ({
  historicalStats: state.print.historicalStats,
  list: getDataSourcesAllListSorted(state)
})

const mapDispatchToProps = {
  fetchDataSources,
  fetchDeltaPrintDataSourcesSummary
}

export default connect(mapStateToProps, mapDispatchToProps)(Main)
