import * as React from 'react'
import { Route } from 'react-router-dom'

interface IProps {
  exact?: boolean
  path: string
  date?: string
  children?: React.ReactNode
  component: React.ComponentType<any>
}

const LoggedOutRoute = ({
  component: Component,
  date,
  children,
  ...otherProps
}: IProps): JSX.Element => {
  return (
    <Route
      {...otherProps}
      component={(props) => (
        <Component {...props} {...(date ? { date } : {})}>
          {children}
        </Component>
      )}
    />
  )
}

export default LoggedOutRoute
