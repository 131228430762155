import {
  AccessControlEmployeeType,
  AccessControlObject,
  AccessControlObjectSummary,
  AccessControlGroupsWidget,
  AccessControlIdpWidget,
  AccessControlObjectsWithImpactedAccess,
  AccessControlUserDatasource,
  AccessControlUserDetailsOverview,
  AccessControlUserEntities,
  AccessControlUserGroupByDepartmentItem,
  AccessControlUsersSummary,
  AccessControlUsersWidget,
  IdpStatus,
  IGetAccessControlUsersFilterParams,
  ObjectsImpactedAccessTypeFilter,
  DataSensitivityLevels,
  AccessControlObjectCard,
  AccessControlEntityWidget,
  Access360Widget,
  AccessControlGlobalObjectCard,
  AccessControlUserEntityCard,
  AccessControlEntityAccessWidget,
  AccessControlObjectsWithImpactedAccessByDept,
  AccessControlUserAttributesWidget,
  AccessControlUserAttributeCard,
  AccessControlCrossDepartmentAccessWidget,
  AccessControlAccessibleWidget,
  AccessControlFilters,
  AccessControlObjectCardBySensitivity,
  IGetAccessControlGroupsFilterParams,
  IdentityProviderParams,
  DirectoryService,
  AccessControlGroupDetailsOverview,
  AlertsOverviewWidget,
  TestConnectionParams
} from './types'
import { AccessControlObjectsParams, AccessControlUserAttributesParams } from './accessControlSlice'
import { DirectoryOptions } from './utils/constants'
import {
  ALERT_STATUS,
  ALERT_STATUS_API_MAP,
  LIMIT_DEFAULT,
  POLICY_TYPES,
  SENSITIVE_LABEL,
  SEVERITY_LEVEL
} from '../../constants'
import { getAfterCursor } from '../../utils/graphqlUtil'
import { DataSource } from '../../services/api/apiTypes'
import { gql } from 'graphql-request'

export const getUserFiltersString = (
  params: IGetAccessControlUsersFilterParams,
  { hasPagination = false }: { hasPagination?: boolean } = {}
) => {
  const {
    page = 1,
    datasourceId,
    datasourceIds,
    dataPrivilegeLevels,
    employeeType,
    departmentName,
    entityTypes,
    attributeIds,
    objectIds,
    hasEntityAccess,
    isDirectMembership
  } = params
  let filterPagination = ''
  if (hasPagination) {
    const cursor = getAfterCursor(page, LIMIT_DEFAULT)
    filterPagination = `first: ${LIMIT_DEFAULT}, after: "${cursor}", `
  }
  let filterStr = ``
  if (dataPrivilegeLevels?.length) {
    filterStr += `sensitivity: [${dataPrivilegeLevels}], `
  }
  if (employeeType) {
    filterStr += `employeeType: ${employeeType}`
  }
  if (departmentName) {
    filterStr += ` ,departmentName: "${departmentName}"`
  }
  if (datasourceId) {
    filterStr += ` ,datasourceId: "${datasourceId}"`
  }
  if (datasourceIds?.length) {
    filterStr += ` ,datasourceIds: ${JSON.stringify(datasourceIds)}`
  }
  if (entityTypes?.length) {
    filterStr += ` ,entityTypeIds: ${JSON.stringify(entityTypes)}`
  }
  if (attributeIds?.length) {
    filterStr += ` ,attributeTypeIds: ${JSON.stringify(attributeIds)}`
  }
  if (objectIds?.length) {
    filterStr += ` ,objectIds: ${JSON.stringify(objectIds)}`
  }
  if (hasEntityAccess !== undefined) {
    filterStr += ` ,hasEntityAccess: ${hasEntityAccess}`
  }
  if (isDirectMembership !== undefined) {
    filterStr += ` ,isDirectMembership: ${isDirectMembership}`
  }

  if (!filterStr && !filterPagination) {
    return ''
  }

  return `${filterPagination} ${filterStr}`
}

export const getGroupFiltersString = (
  params: IGetAccessControlGroupsFilterParams,
  { hasPagination = false }: { hasPagination?: boolean } = {}
) => {
  const { page = 1, isDirect } = params

  let filterPagination = ''
  if (hasPagination) {
    const cursor = getAfterCursor(page, LIMIT_DEFAULT)
    filterPagination = `first: ${LIMIT_DEFAULT}, after: "${cursor}", `
  }

  let filterStr = ``
  if (isDirect !== undefined) {
    filterStr += ` ,isDirect: ${isDirect}`
  }

  if (!filterStr && !filterPagination) {
    return ''
  }

  return `${filterPagination} ${filterStr}`
}

export const getObjectFiltersString = (
  params: AccessControlObjectsParams,
  { hasPagination = false }: { hasPagination?: boolean } = {}
) => {
  const {
    page = 1,
    hasOpenAccess,
    hasExcessiveAccess,
    hasCrossDepartmentsAccess,
    sensitivity,
    datasourceIds,
    attributeIds,
    accessControlUserIds,
    accessControlGroupIds,
    isOwner,
    departmentName
  } = params

  let filterPagination = ''
  if (hasPagination) {
    const cursor = getAfterCursor(page, LIMIT_DEFAULT)
    filterPagination = `first: ${LIMIT_DEFAULT}, after: "${cursor}", `
  }

  let filterStr = ``

  if (hasOpenAccess) {
    filterStr += `, hasOpenAccess: ${hasOpenAccess}`
  }
  if (hasExcessiveAccess) {
    filterStr += `, hasExcessiveAccess: ${hasExcessiveAccess}`
  }
  if (hasCrossDepartmentsAccess) {
    filterStr += `, hasCrossDepartmentAccess: ${hasCrossDepartmentsAccess}`
  }
  if (sensitivity) {
    filterStr += `, sensitivity: [${sensitivity.map((lvl) => lvl.toUpperCase())}]`
  }
  if (datasourceIds?.length) {
    filterStr += `, datasourceIds: ${JSON.stringify(datasourceIds)}`
  }
  if (attributeIds?.length) {
    filterStr += `, attributeTypeIds: ${JSON.stringify(attributeIds)}`
  }
  if (accessControlUserIds?.length) {
    filterStr += `, accessControlUserIds: ${JSON.stringify(accessControlUserIds)}`
  }
  if (accessControlGroupIds?.length) {
    filterStr += `, accessControlGroupIds: ${JSON.stringify(accessControlGroupIds)}`
  }
  if (isOwner) {
    filterStr += `, isOwner: ${isOwner}`
  }
  if (departmentName) {
    filterStr += `, departmentName: "${params.departmentName}"`
  }

  if (!filterStr && !filterPagination) {
    return ''
  }

  return `${filterPagination} ${filterStr}`
}

export const queryUserCardsGroupedByDepartments = (params: IGetAccessControlUsersFilterParams) => {
  const filters = getUserFiltersString(params, { hasPagination: true })

  return gql`
    {

      accessControlUsersGroupedByDept(${filters}) {
        count
        edges {
          node {
            departmentName
            memberUsers {
              count
            }
            dataPrivilegedMembers: memberUsers(sensitivity:[${DataSensitivityLevels.High}]) {
              count
            }
          }
        }
      }
    }
  `
}

export const mapQueryUserCardsGroupedByDepartments = (
  raw: any
): { cards: AccessControlUserGroupByDepartmentItem[]; total: number } => {
  const { accessControlUsersGroupedByDept } = raw
  const cards = accessControlUsersGroupedByDept.edges.map(({ node }) => ({
    departmentName: node?.departmentName,
    memberCount: node?.memberUsers?.count || 0,
    dataPrivilegeMemberCount: node?.dataPrivilegedMembers?.count || 0
  }))

  return {
    cards,
    total: accessControlUsersGroupedByDept?.count || 0
  }
}

export const queryUsers = (params: IGetAccessControlUsersFilterParams) => {
  const { entityId, datasourceId } = params
  const filters = getUserFiltersString(params, { hasPagination: true })

  const filterDatasource = datasourceId ? ` ,datasourceId: "${datasourceId}"` : ``
  const accessModesFilter = params.accessModes?.length
    ? `, accessModes: [${params.accessModes}]`
    : ''
  const query = entityId
    ? gql`
    query accessControlUsers {
      userEntities(id: "${entityId}" ${filterDatasource}) {
          edges {
              node {
                  groupAccess(${filters}) {
                    count
                  }
                  userAccess(${filters}) {
                      count
                      edges {
                          node {
                              id
                              name
                              employeeType
                              departmentName
                              dataPrivilegeLevel
                              datasources {
                                  count
                              }
                              memberOfGroups {
                                count
                              }
                          }
                      }
                  }
              }
          }
      }
  }
  `
    : params.objectIds?.length
    ? gql`
  query accessControlUsers {
    objects(id: "${params.objectIds[0]}") {
          edges {
              node {

                  userAccess(first: 50 ${accessModesFilter}) {
                      count
                      edges {
                          node {
                              id
                              name
                              employeeType
                              departmentName
                              dataPrivilegeLevel
                              datasources {
                                  count
                              }
                              memberOfGroups {
                                count
                              }
                          }
                      }
                  }
              }
          }
      }
  }

  `
    : gql`
  {
    accessControlUsers(${filters}) {
        count
        edges {
            node {
                id
                name
                employeeType
                departmentName
                objects {
                    count
                }
                datasources {
                    count
                }
                entities {
                  count
                }
                memberOfGroups {
                  count
                }
                dataPrivilegeLevel
            }
        }
    }
  }
  `
  return query
}

export const mapQueryUsers = (
  raw: any
): { list: AccessControlUserDetailsOverview[]; total: number; groupsCount?: number } => {
  const { accessControlUsers } = raw
  const list = accessControlUsers.edges.map(({ node }) => ({
    id: node?.id,
    name: node?.name,
    employeeType: node?.employeeType,
    departmentName: node?.departmentName,
    objectsCount: node?.objects?.count || 0,
    datasourcesCount: node?.datasources?.count || 0,
    entitiesCount: node?.entities?.count || 0,
    groupsCount: node?.memberOfGroups?.count || 0,
    dataPrivilegeLevel: node?.dataPrivilegeLevel
  }))

  return { list, total: accessControlUsers.count }
}

export const mapQueryUsersForEntities = (
  raw: any
): { list: AccessControlUserDetailsOverview[]; total: number; groupsCount: number } => {
  const { userEntities } = raw
  const accessControlUsers = userEntities?.edges?.[0]?.node?.userAccess
  const groups = userEntities?.edges?.[0]?.node?.groupAccess
  const list = accessControlUsers.edges.map(({ node }) => ({
    id: node?.id,
    name: node?.name,
    employeeType: node?.employeeType,
    departmentName: node?.departmentName,
    datasourcesCount: node?.datasources?.count || 0,
    dataPrivilegeLevel: node?.dataPrivilegeLevel,
    groupsCount: node?.memberOfGroups?.count || 0
  }))

  return { list, total: accessControlUsers.count, groupsCount: groups?.count || 0 }
}

export const mapQueryUsersForObjects = (
  raw: any
): { list: AccessControlUserDetailsOverview[]; total: number; groupsCount: number } => {
  const { objects } = raw
  const accessControlUsers = objects?.edges?.[0]?.node?.userAccess
  const groups = objects?.edges?.[0]?.node?.groupAccess
  const list = accessControlUsers.edges.map(({ node }) => ({
    id: node?.id,
    name: node?.name,
    employeeType: node?.employeeType,
    departmentName: node?.departmentName,
    datasourcesCount: node?.datasources?.count || 0,
    dataPrivilegeLevel: node?.dataPrivilegeLevel,
    groupsCount: node?.memberOfGroups?.count || 0
  }))

  return { list, total: accessControlUsers.count, groupsCount: groups?.count || 0 }
}

export const mapQueryGroupsForEntities = (raw: any) => {
  const { userEntities } = raw
  const accessControlGroups = userEntities?.edges?.[0]?.node?.groupAccess
  const list = accessControlGroups.edges.map(({ node }) => ({
    id: node?.id,
    name: node?.name,
    employeeType: node?.employeeType,
    departmentName: node?.departmentName,
    usersCount: node?.memberUsers?.count || 0,
    groupsCount: node?.memberGroups?.count || 0,
    attributesCount: node?.attributesGroupedByType?.count || 0,
    datasourcesCount: node?.datasources?.count || 0,
    dataPrivilegeLevel: node?.dataPrivilegeLevel
  }))

  return {
    list,
    total: accessControlGroups.count,
    groupsCount: accessControlGroups?.count || 0,
    highSensitiveCount: 0
  }
}

export const queryUsersSummary = (params: IGetAccessControlUsersFilterParams) => {
  const filterStr = params.datasourceId ? ` ,datasourceId: "${params.datasourceId}"` : ``
  return gql`
    query accessControlUsersSummary {
        contractors: accessControlUsers(sensitivity:[${DataSensitivityLevels.High}], employeeType: ${AccessControlEmployeeType.Contractor} ${filterStr}) {
            count
        }

        employees: accessControlUsers(sensitivity:[${DataSensitivityLevels.High}], employeeType: ${AccessControlEmployeeType.Employee}, ${filterStr}) {
            count
        }

        totalContractors: accessControlUsers(employeeType: ${AccessControlEmployeeType.Contractor} ${filterStr}) {
            count
        }

        totalEmployees: accessControlUsers(employeeType: ${AccessControlEmployeeType.Employee}, ${filterStr}) {
            count
        }
    }
  `
}

export const mapQueryUsersSummary = (raw: any): AccessControlUsersSummary => {
  return {
    employeesCount: raw?.employees?.count || 0,
    contractorsCount: raw?.contractors?.count || 0,
    totalEmployeesCount: raw?.totalEmployees?.count || 0,
    totalContractorsCount: raw?.totalContractors?.count || 0
  }
}

export const queryUserOverview = (params: IGetAccessControlUsersFilterParams) => {
  const { id, datasourceId } = params
  const filterStr = datasourceId ? ` ,datasourceId: "${datasourceId}"` : ``
  const dsIdsFilter = datasourceId ? `, datasourceIds: "${datasourceId}"` : ``
  const dsKeyfilterStr = datasourceId ? ` ,{ key: DATASOURCE_ID, values: "${datasourceId}"}` : ``
  const filterAccessUserIds = id ? ` ,accessControlUserIds: "${id}"` : ``
  const filterIsOwner = datasourceId ? ` ,isOwner: true` : ``
  return gql`
    query userDetailsOverview {
      accessControlObjects(accessControlUserIds: "${id}" ${dsIdsFilter} sensitivity: ${
    DataSensitivityLevels.High
  } ${filterIsOwner}) {
        count
      }
      entitiesCount: userEntities(
        filter: [{ key: ACCESS_CONTROL_USER_IDS, values: ["${id}"]} ${dsKeyfilterStr}]
      ) {
        count
      }
      ${
        dsIdsFilter
          ? `openAccessObjects: accessControlObjects(
        first: 1,
        hasOpenAccess: true,
        ${filterIsOwner}
        ${filterAccessUserIds}
        ${dsIdsFilter}
      ) {
        count
      }
      excessiveAccessObjects: accessControlObjects(
        first: 1,
        hasExcessiveAccess: true
        ${filterIsOwner}
        ${filterAccessUserIds}
        ${dsIdsFilter}
      ) {
        count
      }
      crossDeptAccessObjects: accessControlObjects(
        first: 1,
        hasCrossDepartmentAccess: true,
        ${filterIsOwner}
        ${filterAccessUserIds}
        ${dsIdsFilter}
      ) {
        count
      }`
          : ''
      }
      accessControlUsers(id: "${id}" ${filterStr}) {
        edges {
          node {
            id
            name
            departmentName
            userType
            region
            dataPrivilegeLevel
            employeeType
            memberOfGroups {
              count
            }
            objects {
              count
            }
            datasources {
              count
            }
            email
            attributesGroupedBySensitivity {
              edges {
                node {
                  count
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapQueryUserOverview = (raw: any): AccessControlUserDetailsOverview => {
  const {
    accessControlUsers,
    accessControlObjects,
    entitiesCount,
    openAccessObjects,
    excessiveAccessObjects,
    crossDeptAccessObjects
  } = raw

  const user = accessControlUsers?.edges?.[0]?.node
  let attributesCount = 0
  user?.attributesGroupedBySensitivity.edges.forEach(({ node }) => {
    attributesCount += node?.count || 0
  })

  return {
    id: user?.id,
    name: user?.name,
    departmentName: user?.departmentName,
    userType: user?.userType,
    region: user?.region,
    dataPrivilegeLevel: user?.dataPrivilegeLevel,
    employeeType: user?.employeeType,
    groupsCount: user?.memberOfGroups?.count,
    objectsCount: user?.objects?.count,
    datasourcesCount: user?.datasources?.count,
    entitiesCount: entitiesCount?.count || 0,
    email: user?.email,
    attributesCount,
    sensitiviteObjects: accessControlObjects?.count || 0,
    openAccessObjectsCount: openAccessObjects?.count || 0,
    excessiveAccessObjectsCount: excessiveAccessObjects?.count || 0,
    crossDepartmentAccessObjectsCount: crossDeptAccessObjects?.count || 0
  }
}

export const queryUserDatasources = (params: IGetAccessControlUsersFilterParams) => {
  const {
    id,
    page = 1,
    entityTypes,
    datasourceId,
    sensitivityLevels,
    attributeIds,
    groupId
  } = params

  const cursor = getAfterCursor(page, LIMIT_DEFAULT)

  let filterObjectsStr = ``
  let filterEntitiesStr = ``
  if (id) {
    filterObjectsStr += ` ,accessControlUserIds: ["${id}"]`
    filterEntitiesStr += ` ,accessControlUserIds: ["${id}"]`
  }
  if (groupId) {
    filterObjectsStr += ` ,accessControlGroupIds: ["${groupId}"]`
    filterEntitiesStr += ` ,accessControlGroupIds: ["${groupId}"]`
  }
  if (datasourceId) {
    filterObjectsStr += ` ,datasourceIds: "${datasourceId}"`
    filterEntitiesStr += ` ,datasourceIds: "${datasourceId}"`
  }
  if (entityTypes?.length) {
    filterObjectsStr += ` ,entityTypeId: "${entityTypes[0]}"`
    filterEntitiesStr += ` ,entityTypeId: "${entityTypes[0]}"`
  }
  if (sensitivityLevels) {
    filterObjectsStr += ` ,sensitivity: [${sensitivityLevels.join(',')}]`
  }

  if (attributeIds?.length) {
    filterObjectsStr += ` ,attributeTypeIds: ${JSON.stringify(attributeIds)}`
  }

  const entityGroupNode = groupId
    ? `accessControlGroupsEntityGroupedByDatasource`
    : `accessControlUsersEntityGroupedByDatasource`

  return gql`
    query userDetailsDatasources {
      accessControlObjectGroupByDatasource(first: ${LIMIT_DEFAULT}, after: "${cursor}" ${filterObjectsStr}){
        count
        edges {
          node {
            count
            datasource {
              id
              name
              type
            }
          }
        }
      }
      ${entityGroupNode}(first: 999, ${filterEntitiesStr}) {
        edges {
          node {
            id
            entitiesCount
          }
        }
      }
    }
  `
}
export const mapQueryUserDatasources = (
  raw: any
): { list: AccessControlUserDatasource[]; total: number } => {
  const {
    accessControlObjectGroupByDatasource,
    accessControlUsersEntityGroupedByDatasource,
    accessControlGroupsEntityGroupedByDatasource
  } = raw

  const entityRawNode =
    accessControlGroupsEntityGroupedByDatasource || accessControlUsersEntityGroupedByDatasource

  const list = accessControlObjectGroupByDatasource?.edges?.map(({ node }) => {
    const dataSource = node?.datasource || {}
    const objectsCount = node?.count || 0
    const entitiesCount =
      entityRawNode?.edges?.find(({ node }) => node.id === dataSource?.id)?.node?.entitiesCount || 0
    return {
      id: dataSource?.id,
      name: dataSource?.name,
      type: dataSource?.type,
      entitiesCount,
      objectsCount
    }
  })
  return {
    total: accessControlObjectGroupByDatasource?.count || 0,
    list
  }
}

export const queryUserEntities = (params: IGetAccessControlUsersFilterParams) => {
  const { id, page = 1, datasourceId, risky, entityTypes, groupId } = params
  const filterStr = datasourceId ? ` ,{ key: DATASOURCE_ID, values: "${datasourceId}"}` : ``
  const cursor = getAfterCursor(page, LIMIT_DEFAULT)
  const booleanFilter = risky ? ` booleanFilter: [{ key: IS_RISKY, value: ${risky} }]` : ``

  const entityTypeFilter = entityTypes?.length
    ? `, { key: TYPE, values: ${JSON.stringify(entityTypes)} }`
    : ''

  const groupIdFilter = groupId ? `, { key: ACCESS_CONTROL_GROUP_IDS, values: ["${groupId}"] }` : ''
  const userIdFilter = id ? `, { key: ACCESS_CONTROL_USER_IDS, values: ["${id}"] }` : ''

  const idFilter = groupId ? groupIdFilter : userIdFilter

  const queryName = groupId ? `groupDetailsEntities` : `userDetailsEntities`

  return gql`
    query ${queryName} {
      entitiesCount: userEntities(
        first: ${LIMIT_DEFAULT} after: "${cursor}"
        filter: [${idFilter} ${entityTypeFilter} ${filterStr}]
        ${booleanFilter}
      ) {
        count
      }
      userEntities(
        first: ${LIMIT_DEFAULT} after: "${cursor}"
        filter: [${idFilter} ${entityTypeFilter} ${filterStr}]
        ${booleanFilter}
      ) {
          # count
          edges {
            node {
              id
              name
              riskStatus
              type {
                edges {
                  node {
                    name
                  }
                }
              }
              attributesCount
              datasources {
                count
              }
              objects {
                count
              }
            }
        }
      }
    }
  `
}
export const mapQueryUserEntities = (
  raw: any
): { list: AccessControlUserEntities[]; total: number } => {
  const { userEntities, entitiesCount } = raw

  const list = userEntities.edges?.map(({ node }) => {
    return {
      id: node?.id,
      name: node?.name?.[0] || '',
      type: node?.type?.edges?.[0]?.node?.name,
      riskStatus: node?.riskStatus || false,
      attributesCount: node?.attributesCount || 0,
      objectsCount: node?.objects?.count || 0,
      datasourcesCount: node?.datasources?.count || 0
    }
  })
  return {
    total: entitiesCount?.count,
    list
  }
}

export const queryUsersWidget = (params: IGetAccessControlUsersFilterParams) => {
  const filterStr = getUserFiltersString(params)
  return gql`
    query accessControlUsersWidget {
      totalUsers: accessControlUsers ${filterStr ? `(${filterStr})` : ''} {
        count
      }
      privilegeUsers: accessControlUsers(sensitivity:[${DataSensitivityLevels.High}] ${filterStr}) {
        count
      }
      employeeUsers: accessControlUsers(employeeType: EMPLOYEE ${filterStr}) {
        count
      }
      contractorUsers: accessControlUsers(employeeType: CONTRACTOR ${filterStr}) {
        count
      }
    }
  `
}

export const mapQueryUsersWidget = (raw: any): AccessControlUsersWidget => {
  const { totalUsers, privilegeUsers, employeeUsers, contractorUsers } = raw
  return {
    totalUsers: totalUsers?.count || 0,
    privilegeUsers: privilegeUsers?.count || 0,
    employeeUsers: employeeUsers?.count || 0,
    contractorUsers: contractorUsers?.count || 0
  }
}

export const queryGroupsWidget = (params: IGetAccessControlUsersFilterParams) => {
  const filterStr = getUserFiltersString(params)
  return gql`
    query accessControlGroupsWidget {
      totalGroups: accessControlGroups ${filterStr ? `(${filterStr})` : ''}{
        count
      }
      privilegeGroups: accessControlGroups(sensitivity:[${
        DataSensitivityLevels.High
      }] ${filterStr}) {
        count
      }
    }
  `
}

export const mapQueryGroupsWidget = (raw: any): AccessControlGroupsWidget => {
  const { totalGroups, privilegeGroups } = raw
  return {
    totalGroups: totalGroups?.count || 0,
    privilegeGroups: privilegeGroups?.count || 0
  }
}

export const queryIdpWidget = () => {
  return gql`
    query accessControlIdentityProvider {
      identityProvider {
        count
        edges {
          node {
            id
            name
            idpType
          }
        }
      }
    }
  `
}

export const mapQueryIdpWidget = (raw: any): AccessControlIdpWidget => {
  const { identityProvider } = raw
  return {
    totalProviders: identityProvider?.count || 0,
    providers: identityProvider?.edges?.map(({ node }) => ({
      name: node?.name || '',
      type: node?.idpType || '',
      id: node?.id || '',
      status: IdpStatus.Active // TODO
    }))
  }
}

export const queryObjectsWithImpactedAccess = (
  filter: ObjectsImpactedAccessTypeFilter,
  sensitivityLevel: DataSensitivityLevels
) => {
  return gql`
    query accessControlObjectWithImpactedAccess {
      impactedObjects: accessControlObjectGroupByDatasource(${filter}: true, sensitivity: ${sensitivityLevel}) {
        edges {
          node {
            datasource {
              id
            }
            count
          }
        }
      }
      totalObjects: accessControlObjectGroupByDatasource(sensitivity: ${sensitivityLevel}) {
        edges {
          node {
            datasource {
              type
              id
              name
            }
            count
          }
        }
      }
    }
  `
}

export const mapQueryObjectsWithImpactedAccess = (
  raw: any
): AccessControlObjectsWithImpactedAccess => {
  const totalObjects = raw?.totalObjects?.edges || []
  const impactedObjects = raw?.impactedObjects?.edges || []

  const payload: AccessControlObjectsWithImpactedAccess = {
    totalObjects: 0,
    impactedObjects: 0,
    datasources: []
  }

  for (let i = 0; i < totalObjects.length; i++) {
    const totalNode = totalObjects[i]?.node
    const impactedNode = impactedObjects.find(
      ({ node }) => node?.datasource?.id === totalNode?.datasource?.id
    )?.node
    payload.totalObjects += +(totalNode?.count || 0)
    payload.impactedObjects += +(impactedNode?.count || 0)
    const datasourceNode = totalNode?.datasource
    if (datasourceNode?.id) {
      payload.datasources.push({
        id: datasourceNode?.id || '',
        type: datasourceNode?.type || '',
        name: datasourceNode?.name || '',
        totalObjects: +(totalNode?.count || 0),
        impactedObjects: +(impactedNode?.count || 0)
      })
    }
  }

  return payload
}

export const queryObjectsWithImpactedAccessByDept = (
  filter: ObjectsImpactedAccessTypeFilter,
  params: IGetAccessControlUsersFilterParams
) => {
  const { sensitivityLevel, datasourceId } = params
  const filterDataSourceIds = `datasourceIds: ["${datasourceId}"]`
  const accessFilter =
    filter === ObjectsImpactedAccessTypeFilter.OpenAccess
      ? `hasOpenAccess: true`
      : `hasExcessiveAccess: true`
  return gql`
    query accessControlObjectGroupByDepartmentWithImpactedAccessWidget {
      impactedObjects :accessControlObjectGroupByDepartment(
        ${accessFilter}
        sensitivity: ${sensitivityLevel}
        ${filterDataSourceIds}
    ) {
        edges {
            node {
                departmentName
                objects {
                    count
                }
            }
        }
    }
    totalDepartments: accessControlObjectGroupByDepartment(
      ${accessFilter}
      sensitivity: ${sensitivityLevel}
      ${filterDataSourceIds}
    ) {
      count
    }
    impactedAccessOwners: accessControlObjectGroupByUserOwner(
        ${accessFilter}
        ${filterDataSourceIds}
        sensitivity: ${sensitivityLevel}
    ) {
        count
    }
    totalObjects: accessControlObjects(
      ${accessFilter}
      sensitivity: ${sensitivityLevel}
      ${filterDataSourceIds}) {
        count
      }
    }
  `
}

export const mapQueryObjectsWithImpactedAccessByDept = (
  raw: any
): AccessControlObjectsWithImpactedAccessByDept => {
  const { totalObjects, impactedAccessOwners, impactedObjects, totalDepartments } = raw

  return {
    totalObjects: totalObjects?.count || 0,
    totalDepartments: totalDepartments?.count || 0,
    totalOwners: impactedAccessOwners?.count || 0,
    departments: impactedObjects?.edges?.map(({ node }) => ({
      name: node?.departmentName || '',
      totalObjects: +(node?.objects?.count || 0)
    }))
  }
}

export const queryEntitiesWithAccess = () => {
  return gql`
    query accessControlEntityWidget {
      employeesCount: accessControlEntities(employeeType: EMPLOYEE) {
        count
      }
      contractorsCount: accessControlEntities(employeeType: CONTRACTOR) {
        count
      }
      employeesUsersCount: accessControlUsers(hasEntityAccess: true, employeeType: EMPLOYEE) {
        count
      }
      contractorsUsersCount: accessControlUsers(hasEntityAccess: true, employeeType: CONTRACTOR) {
        count
      }
      userEntities {
        count
      }
    }
  `
}

export const mapQueryUserEntitiesWithAccess = (raw: any): AccessControlEntityWidget => {
  const {
    employeesCount,
    contractorsCount,
    userEntities,
    employeesUsersCount,
    contractorsUsersCount
  } = raw
  return {
    employeesCount: employeesCount?.count || 0,
    contractorsCount: contractorsCount?.count || 0,
    totalEntities: userEntities?.count || 0,
    employeesUsersCount: employeesUsersCount?.count || 0,
    contractorsUsersCount: contractorsUsersCount?.count || 0
  }
}

// User Attributes
export const queryAccessControlUserAttributesSummary = (
  params: AccessControlUserAttributesParams
) => {
  const filters = params.datasourceIds?.length
    ? `(datasourceIds: ${JSON.stringify(params.datasourceIds)})`
    : ''

  const filterSensitivity = params.sensitivity
    ? ` ,sensitivity: [${params.sensitivity.join(',')}]`
    : ''

  const { userId, groupId } = params

  const node = groupId ? `accessControlGroups` : `accessControlUsers`
  const idFilter = groupId ? groupId : userId

  return gql`
    {
      ${node}(first: 1, id: "${idFilter}" ${filterSensitivity}) {
        edges {
          node {
            attributesGroupedBySensitivity${filters} {
              edges {
                node {
                  attributeSensitivity
                  count
                }
              }
            }
          }
        }
      }
    }
  `
}
export const mapQueryAccessControlUserAttributesSummary = (
  raw: any
): AccessControlUserAttributesWidget[] => {
  try {
    const { accessControlUsers, accessControlGroups } = raw || {}
    const rawNode = accessControlGroups || accessControlUsers
    return rawNode?.edges[0]?.node?.attributesGroupedBySensitivity?.edges?.map(({ node }) => ({
      attributeSensitivity: node.attributeSensitivity,
      count: node.count
    }))
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryAccessControlObjectsCount = (params: AccessControlUserAttributesParams) => {
  let filtersStr = ``
  if (params.datasourceIds?.length) {
    filtersStr += `,datasourceIds: ${JSON.stringify(params.datasourceIds)}`
  }
  if (params.userId) {
    filtersStr += `,accessControlUserIds: ["${params.userId}"]`
  }
  if (params.groupId) {
    filtersStr += `,accessControlGroupIds: ["${params.groupId}"]`
  }
  if (params.sensitivity) {
    filtersStr += ` ,sensitivity: [${params.sensitivity.join(',')}]`
  }

  return gql`
  {
    accessControlObjects(first: 1 ${filtersStr}) {
      count
    }
  }
  `
}

export const mapQueryAccessControlObjectsCount = (raw: any): number => {
  const { accessControlObjects } = raw
  return accessControlObjects?.count || 0
}

export const queryAccessControlUserAttributeCards = (params: AccessControlUserAttributesParams) => {
  const filterDsIds = params.datasourceIds?.length
    ? `datasourceIds: ${JSON.stringify(params.datasourceIds)},`
    : ''

  const filterSensitivity = params.sensitivity
    ? `attributeSensitivity: [${params.sensitivity.join(',')}],`
    : ''

  const filterAttributeIds = params.attributeIds?.length
    ? `attributeTypeIds: ${JSON.stringify(params.attributeIds)},`
    : ''

  const filtersString = `${filterDsIds}${filterSensitivity}${filterAttributeIds}`

  const { userId, groupId } = params

  const node = groupId ? `accessControlGroups` : `accessControlUsers`
  const idFilter = groupId ? groupId : userId
  return gql`
    {
      ${node}(first: 999, id: "${idFilter}") {
        edges {
          node {
            attributesGroupedByType(first: ${LIMIT_DEFAULT} ${filtersString}) {
              count
              edges {
                node {
                  attributeInstanceCount
                  objectCount
                  datasources {
                    edges {
                      node {
                        id
                        name
                        type
                      }
                    }
                  }
                  attribute(first: 1) {
                    edges {
                      node {
                        id
                        name
                        sensitivityLabel
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}
export const mapQueryAccessControlUserAttributeCards = (
  raw: any
): { cards: AccessControlUserAttributeCard[]; total: number } => {
  try {
    const { accessControlUsers, accessControlGroups } = raw || {}
    const rawNode = accessControlGroups || accessControlUsers
    const rawCards = rawNode?.edges[0]?.node?.attributesGroupedByType?.edges?.map(({ node }) => ({
      objectsCount: node?.objectCount || 0,
      attributeInstanceCount: node?.attributeInstanceCount || 0,
      dataSourcesCount: node?.datasources?.count || 0,
      attributeId: node?.attribute?.edges[0]?.node?.id,
      attributeName: node?.attribute?.edges[0]?.node?.name,
      sensitivityLabel: node?.attribute?.edges[0]?.node?.sensitivityLabel,
      dataSources: node?.datasources?.edges.map(({ node: ds }) => ({
        id: ds.id,
        name: ds.name,
        type: ds.type
      }))
    }))

    const aggregationByAttributeId: { [attributeId: string]: AccessControlUserAttributeCard } = {}

    rawCards.forEach((card) => {
      if (!aggregationByAttributeId[card.attributeId]) {
        aggregationByAttributeId[card.attributeId] = card
      } else {
        aggregationByAttributeId[card.attributeId].objectsCount =
          (aggregationByAttributeId[card.attributeId].objectsCount || 0) + (card.objectCount || 0)
        aggregationByAttributeId[card.attributeId].attributeInstanceCount =
          (aggregationByAttributeId[card.attributeId].attributeInstanceCount || 0) +
          (card.attributeInstanceCount || 0)
        aggregationByAttributeId[card.attributeId].dataSourcesCount =
          (aggregationByAttributeId[card.attributeId].dataSourcesCount || 0) +
          (card.dataSourcesCount || 0)
        aggregationByAttributeId[card.attributeId].dataSourcesCount =
          (aggregationByAttributeId[card.attributeId].dataSourcesCount || 0) +
          (card.dataSourcesCount || 0)
        aggregationByAttributeId[card.attributeId].dataSources = [
          ...(aggregationByAttributeId[card.attributeId].dataSources || []),
          ...(card.dataSources || [])
        ]
      }
    })

    const cards = Object.values(aggregationByAttributeId)

    return { cards, total: cards.length }
  } catch (error) {
    console.error(error)
    throw error
  }
}

// Datasource accessible  Objects
export const queryAccessControlObjectsSummary = (params: AccessControlObjectsParams) => {
  const filterStringWithOwner = getObjectFiltersString(params, { hasPagination: false })
  delete params.isOwner
  const filters = getObjectFiltersString(params, { hasPagination: false })

  return gql`
    {
      openAccessObjects: accessControlObjects(
        first: 1,
        hasOpenAccess: true,
        ${filterStringWithOwner}
      ) {
        count
      }
      openAccessOwners: accessControlObjectGroupByUserOwner (
        first: 1,
        hasOpenAccess: true,
        ${filters}
      ) {
        count
      }
      excessiveAccessObjects: accessControlObjects(
        first: 1,
        hasExcessiveAccess: true
        ${filterStringWithOwner}
      ) {
        count
      }
      excessiveAccessOwners: accessControlObjectGroupByUserOwner (
        first: 1,
        hasExcessiveAccess: true,
        ${filters}
      ) {
        count
      }
      crossDeptAccessObjects: accessControlObjects(
        first: 1,
        hasCrossDepartmentAccess: true,
        ${filterStringWithOwner}
      ) {
        count
      }
      crossDeptAccessOwners: accessControlObjectGroupByUserOwner (
        first: 1,
        hasCrossDepartmentAccess: true,
        ${filters}
      ) {
        count
      }
    }
  `
}
export const mapQueryAccessControlObjectsSummary = (
  raw: any
): {
  openAccess: AccessControlObjectSummary
  excessiveAccess: AccessControlObjectSummary
  crossDepartmentsAccess: AccessControlObjectSummary
} => {
  try {
    const openAccess: AccessControlObjectSummary = {
      objectsCount: raw.openAccessObjects?.count || 0,
      ownersCount: raw.openAccessOwners?.count || 0
    }
    const excessiveAccess: AccessControlObjectSummary = {
      objectsCount: raw.excessiveAccessObjects?.count || 0,
      ownersCount: raw.excessiveAccessOwners?.count || 0
    }

    const crossDepartmentsAccess: AccessControlObjectSummary = {
      objectsCount: raw.crossDeptAccessObjects?.count || 0,
      ownersCount: raw.crossDeptAccessOwners?.count || 0
    }

    return { openAccess, excessiveAccess, crossDepartmentsAccess }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryAccessControlObjectsList = (params: AccessControlObjectsParams) => {
  const filters = getObjectFiltersString(params, { hasPagination: true })

  return gql`
    {
      accessControlObjects(${filters}) {
        count
        edges {
          node {
            sensitivityLevel
            object {
              id
              name
              owner
              groupAccess(first: 1) { count }
              userAccess(first: 1) { count }
              attribute(first: 1) { count }
              entity { count }
            }
          }
        }
      }
    }
  `
}
export const mapQueryAccessControlObjectsList = (
  raw: any
): { list: AccessControlObject[]; total: number } => {
  try {
    const list = raw.accessControlObjects?.edges?.map(({ node: item }) => {
      const object = item.object || {}
      return {
        id: object.id || '',
        name: object.name || '',
        owner: object.owner || '',
        sensitivity: item.sensitivityLevel?.toUpperCase() || '',
        groupAccessCount: object.groupAccess?.count || 0,
        userAccessCount: object.userAccess?.count || 0,
        attributesCount: object.attribute?.count || 0,
        entitiesCount: object.entity?.count || 0
      }
    })

    return { list, total: raw.accessControlObjects?.count || 0 }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryAccessControlObjectDetails = (params: IGetAccessControlUsersFilterParams) => {
  const filters = getUserFiltersString(params, { hasPagination: false })

  return gql`
    {
      accessControlObjects(first: 1, ${filters}) {
        count
        edges {
          node {
            sensitivityLevel
            object {
              id
              name
              owner
              groupAccess(first: ${LIMIT_DEFAULT}) {
                count
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              userAccess(first: ${LIMIT_DEFAULT}) {
                count
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              entities {
                id
                name
              }
              attribute(first: ${LIMIT_DEFAULT}) {
                count
                edges {
                  node {
                    id
                    name
                    internalName
                  }
                }
              }
              labels(first: ${LIMIT_DEFAULT}) {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}
export const mapQueryAccessControlObjectDetails = (raw: any): AccessControlObject => {
  try {
    const item = raw.accessControlObjects?.edges[0]?.node
    const object = raw.accessControlObjects?.edges[0]?.node?.object

    const result: AccessControlObject = {
      sensitivity: item?.sensitivityLevel?.toUpperCase() || '',
      id: object.id || '',
      name: object.name || '',
      owner: object.owner || '',
      userAccess: object.userAccess?.edges?.map(({ node }) => ({
        id: node.id,
        name: node.name
      })),
      groupAccess: object.groupAccess?.edges?.map(({ node }) => ({
        id: node.id,
        name: node.name
      })),
      attributes: object.attribute?.edges?.map(({ node }) => node),
      labels: object.labels?.edges?.map(({ node }) => node),
      entities: object.entities?.map(({ id, name }) => ({ id, name: name ? name[0] : '' })),
      isDetailsReceived: true
    }

    return result
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryAccessControlCounts = (params: AccessControlObjectsParams) => {
  const filterDataSourceIds = params.datasourceIds?.length
    ? `datasourceIds: ${JSON.stringify(params.datasourceIds)},`
    : ''

  const filterOpenAccess = params.hasOpenAccess ? `hasOpenAccess: ${params.hasOpenAccess},` : ''
  const filterExcessiveAccess = params.hasExcessiveAccess
    ? `hasExcessiveAccess: ${params.hasExcessiveAccess},`
    : ''
  const filterCrossDeptAccess = params.hasCrossDepartmentsAccess
    ? `hasCrossDepartmentAccess: ${params.hasCrossDepartmentsAccess},`
    : ''
  const filterSensitivity = params.sensitivity
    ? `sensitivity: [${params.sensitivity.map((lvl) => lvl.toUpperCase())}],`
    : ''
  const filterAttributeIds = params.attributeIds?.length
    ? `attributeTypeIds: ${JSON.stringify(params.attributeIds)},`
    : ''
  const filterAccessUserIds = params.accessControlUserIds?.length
    ? `accessControlUserIds: ${JSON.stringify(params.accessControlUserIds)},`
    : ''

  const filterAccessGroupIds = params.accessControlGroupIds?.length
    ? `accessControlGroupIds: ${JSON.stringify(params.accessControlGroupIds)},`
    : ''

  const filterIsOwner = params.isOwner ? `isOwner: ${params.isOwner},` : ''

  const filterDepartment = params.departmentName
    ? `departmentName: "${params.departmentName}",`
    : ''

  return gql`
    {
      accessControlObjects(
        first: 1,
        ${filterOpenAccess}
        ${filterExcessiveAccess}
        ${filterCrossDeptAccess}
        ${filterSensitivity}
        ${filterDataSourceIds}
        ${filterAttributeIds}
        ${filterAccessUserIds}
        ${filterAccessGroupIds}
        ${filterIsOwner}
        ${filterDepartment}
      ) {
        count
      }
      accessControlObjectGroupByUserOwner(
        first: 1,
        ${filterOpenAccess}
        ${filterExcessiveAccess}
        ${filterCrossDeptAccess}
        ${filterSensitivity}
        ${filterAccessUserIds}
        ${filterAccessGroupIds}
        ${filterDataSourceIds}
        ${filterAttributeIds}
        ${filterDepartment}
      ) {
        count
      }
    }
  `
}
export const mapQueryAccessControlCounts = (
  raw: any
): { ownersCount: number; objectsCount: number } => {
  try {
    return {
      ownersCount: raw.accessControlObjectGroupByUserOwner?.count || 0,
      objectsCount: raw.accessControlObjects?.count || 0
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryAccessControlObjectsCards = (params: AccessControlObjectsParams) => {
  const filterDataSourceIds = params.datasourceIds?.length
    ? `datasourceIds: ${JSON.stringify(params.datasourceIds)},`
    : ''

  const cursor = getAfterCursor(params.page || 1, LIMIT_DEFAULT)
  const filterOpenAccess = params.hasOpenAccess ? `hasOpenAccess: ${params.hasOpenAccess},` : ''
  const filterExcessiveAccess = params.hasExcessiveAccess
    ? `hasExcessiveAccess: ${params.hasExcessiveAccess},`
    : ''
  const filterCrossDeptAccess = params.hasCrossDepartmentsAccess
    ? `hasCrossDepartmentAccess: ${params.hasCrossDepartmentsAccess},`
    : ''
  const filterSensitivity = params.sensitivity
    ? `sensitivity: [${params.sensitivity.map((lvl) => lvl.toUpperCase())}],`
    : ''
  const filterAttributeIds = params.attributeIds?.length
    ? `attributeTypeIds: ${JSON.stringify(params.attributeIds)},`
    : ''

  const filterDepartment = params.departmentName
    ? `departmentName: "${params.departmentName}",`
    : ''

  return gql`
    {
      accessControlObjectGroupByUserOwner(
        first: ${LIMIT_DEFAULT},
        after: "${cursor}",
        ${filterOpenAccess}
        ${filterExcessiveAccess}
        ${filterCrossDeptAccess}
        ${filterSensitivity}
        ${filterDataSourceIds}
        ${filterDepartment}
        ${filterAttributeIds}
      ) {
        count
        edges {
          node {
            user {
              id
              email
              name
            }
            object {
              count
            }
          }
        }
      }
    }
  `
}

export const mapQueryAccessControlObjectsCards = (
  raw: any
): { cards: AccessControlObjectCard[]; total: number } => {
  try {
    const cards = raw.accessControlObjectGroupByUserOwner?.edges?.map(({ node: item }) => ({
      ownerId: item.user?.id || '',
      ownerEmail: item.user?.email || '',
      ownerName: item.user?.name || '',
      objectsCount: item.object?.count || 0
    }))

    return { cards, total: raw.accessControlObjectGroupByUserOwner?.count || 0 }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryAccessControlObjectsCardBySensitivity = (params: AccessControlObjectsParams) => {
  const filterDataSourceIds = params.datasourceIds?.length
    ? `datasourceIds: ${JSON.stringify(params.datasourceIds)},`
    : ''

  const filterAttributeIds = params.attributeIds?.length
    ? `attributeTypeIds: ${JSON.stringify(params.attributeIds)},`
    : ''

  const filterAccessUserIds = params.accessControlUserIds?.length
    ? `accessControlUserIds: ${JSON.stringify(params.accessControlUserIds)},`
    : ''

  const filterAccessGroupIds = params.accessControlGroupIds?.length
    ? `accessControlGroupIds: ${JSON.stringify(params.accessControlGroupIds)},`
    : ''

  const filterOpenAccess = params.hasOpenAccess ? `hasOpenAccess: ${params.hasOpenAccess},` : ''
  const filterExcessiveAccess = params.hasExcessiveAccess
    ? `hasExcessiveAccess: ${params.hasExcessiveAccess},`
    : ''

  const filterCrossDepartmentsAccess = params.hasCrossDepartmentsAccess
    ? `hasCrossDepartmentAccess
        : ${params.hasCrossDepartmentsAccess},`
    : ''

  const filterIsOwner = params.isOwner ? `isOwner: ${params.isOwner},` : ''

  const filters = `${filterOpenAccess}${filterExcessiveAccess}${filterCrossDepartmentsAccess}${filterDataSourceIds}${filterAttributeIds}${filterAccessUserIds}${filterAccessGroupIds}${filterIsOwner}`

  return gql`
    {
    totalObjects: accessControlObjects(${filterAccessUserIds}${filterDataSourceIds}${filterIsOwner}) {
      count
    }
    totalHighObjects: accessControlObjects(
        ${filters}
        sensitivity: HIGH
    ) {
        count
    }
    totalMedObjects: accessControlObjects(
        ${filters}
        sensitivity: MEDIUM
    ) {
        count
    }
    totalLowObjects: accessControlObjects(
        ${filters}
        sensitivity: LOW
    ) {
        count
    }
}

  `
}

export const mapQueryAccessControlObjectsCardsBySensitivity = (
  raw: any
): AccessControlObjectCardBySensitivity => {
  const { totalHighObjects, totalMedObjects, totalLowObjects, totalObjects } = raw
  return {
    [DataSensitivityLevels.High]: totalHighObjects?.count || 0,
    [DataSensitivityLevels.Medium]: totalMedObjects?.count || 0,
    [DataSensitivityLevels.Low]: totalLowObjects?.count || 0,
    totalObjects: totalObjects?.count || 0
  }
}

// Access control global objects
export const queryAccessControlGlobalObjectsSummary = () => {
  const filterSensitivity = `sensitivity: [${SENSITIVE_LABEL.HIGH}, ${SENSITIVE_LABEL.MEDIUM}, ${SENSITIVE_LABEL.LOW}],`

  return gql`
    {
      openAccess: accessControlObjectGroupByDatasource(
        first: 1,
        hasOpenAccess: true,
        ${filterSensitivity}
      ) {
        count
      }
      excessiveAccess: accessControlObjectGroupByDatasource(
        first: 1,
        hasExcessiveAccess: true
        ${filterSensitivity}
      ) {
        count
      }
      crossDepartmentAccess: accessControlObjectGroupByDatasource(
        first: 1,
        hasCrossDepartmentAccess: true
        ${filterSensitivity}
      ) {
        count
      }
    }
  `
}
export const mapQueryAccessControlGlobalObjectsSummary = (
  raw: any
): {
  openAccess: AccessControlObjectSummary
  excessiveAccess: AccessControlObjectSummary
  crossDepartmentsAccess: AccessControlObjectSummary
} => {
  try {
    const openAccess: AccessControlObjectSummary = {
      dataSourcesCount: raw.openAccess?.count || 0
    }
    const excessiveAccess: AccessControlObjectSummary = {
      dataSourcesCount: raw.excessiveAccess?.count || 0
    }
    const crossDepartmentsAccess: AccessControlObjectSummary = {
      dataSourcesCount: raw.crossDepartmentAccess?.count || 0
    }

    return { openAccess, excessiveAccess, crossDepartmentsAccess }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryAccessControlGlobalObjectsCards = (params: AccessControlObjectsParams) => {
  const cursor = getAfterCursor(params.page || 1, LIMIT_DEFAULT)
  const filterOpenAccess = params.hasOpenAccess ? `hasOpenAccess: ${params.hasOpenAccess},` : ''
  const filterExcessiveAccess = params.hasExcessiveAccess
    ? `hasExcessiveAccess: ${params.hasExcessiveAccess},`
    : ''
  const filterCrossDepartmentsAccess = params.hasCrossDepartmentsAccess
    ? `hasCrossDepartmentAccess: ${params.hasCrossDepartmentsAccess},`
    : ''
  const filterSensitivity = params.sensitivity
    ? `sensitivity: [${params.sensitivity.map((lvl) => lvl.toUpperCase())}],`
    : ''

  return gql`
    {
      accessControlObjectGroupByDatasource(
        first: ${LIMIT_DEFAULT},
        after: "${cursor}",
        ${filterOpenAccess}
        ${filterExcessiveAccess}
        ${filterCrossDepartmentsAccess}
        ${filterSensitivity}
      ) {
        count
        edges {
          node {
            count
            datasource {
              id
              name
              type
              coOwners {
                email
                name
              }
            }
          }
        }
      }
    }
  `
}
export const queryAccessControlEntitiesGroupByType = (
  params: IGetAccessControlUsersFilterParams
) => {
  const { id, datasourceId, groupId } = params

  const filterDataSourceIds = datasourceId ? `datasourceIds: ["${datasourceId}"],` : ''

  const groupIdFilter = groupId ? `accessControlGroupIds: ["${groupId}"],` : ''
  const userIdFilter = id ? `accessControlUserIds: ["${id}"],` : ''

  const idFilter = groupId ? groupIdFilter : userIdFilter

  const node = groupId
    ? `accessControlGroupsEntityGroupedByType`
    : `accessControlUsersEntityGroupedByType`

  return gql`
    query accessControl${groupId ? 'Group' : 'User'}EntitiesEntityGroupedByType {
      ${node}(${idFilter} ${filterDataSourceIds}) {
        count
        edges {
          node {
            name
            id
            entities {
              count
            }
          }
        }
      }
      atRisk:  ${node}(${idFilter} ${filterDataSourceIds}, isRisky: true) {
        edges {
          node {
            name
            id
            entities {
              count
            }
          }
        }
      }
    }
  `
}
export const mapQueryAccessControlGlobalObjectsCards = (
  raw: any
): { cards: AccessControlGlobalObjectCard[]; total: number } => {
  try {
    const cards: AccessControlGlobalObjectCard[] = raw.accessControlObjectGroupByDatasource?.edges?.map(
      ({ node }) => ({
        dataSourceId: node.datasource?.id || '',
        dataSourceName: node.datasource?.name || '',
        dataSourceType: node.datasource?.type,
        objectsCount: node?.count || 0,
        ownersCount: node.datasource?.coOwners?.length + 1 || 1
      })
    )

    return { cards, total: raw.accessControlObjectGroupByDatasource?.count || 0 }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const mapAccessControlEntitiesGroupByType = (raw: any): AccessControlUserEntityCard[] => {
  const {
    accessControlUsersEntityGroupedByType,
    accessControlGroupsEntityGroupedByType,
    atRisk
  } = raw
  const rawNode = accessControlGroupsEntityGroupedByType || accessControlUsersEntityGroupedByType
  return rawNode?.edges?.map(({ node }) => ({
    id: node.id,
    name: node.name,
    entitiesCount: node.entities?.count || 0,
    atRiskCount:
      atRisk?.edges?.find(({ node: riskyNode }) => riskyNode?.id === node.id)?.node?.entities
        ?.count || 0,
    associatedDatasources: []
  }))
}

export const queryUserEntitiesGroupByDatasource = (
  entityTypes: { id: string; name: string }[],
  params: IGetAccessControlUsersFilterParams
) => {
  const { datasourceId, groupId } = params
  const filterDataSourceIds = datasourceId ? `datasourceIds: ["${datasourceId}"],` : ''
  const node = groupId
    ? `accessControlGroupsEntityGroupedByDatasource`
    : `accessControlUsersEntityGroupedByDatasource`
  const queryFragment = (id, name) =>
    gql`
      ${name}: ${node}(entityTypeId: "${id}" ${filterDataSourceIds}) {
        edges {
            node {
                id
                datasource {
                    name
                    type
                }
            }
        }
      }
  `
  const FRAGMENT = entityTypes.map((type) => queryFragment(type.id, type.name)).join('\n')

  return gql`
  query accessControl${groupId ? 'Group' : 'User'}sEntityGroupedByDatasource{
    ${FRAGMENT}
}
  `
}

export const mapAccessControlEntitiesGroupByDatasource = (
  raw: any,
  entityCards: AccessControlUserEntityCard[]
): {
  totalCards: number
  cards: AccessControlUserEntityCard[]
  entitiesAtRisk: number
  entityTypes: number
} => {
  try {
    const entityCardsWithDs = entityCards.map((card) => {
      const ds =
        raw?.[card.name]?.edges?.map(({ node }) => ({
          id: node?.id || '',
          name: node.datasource?.name || '',
          type: node.datasource?.type || ''
        })) || []
      return {
        ...card,
        associatedDatasources: ds
      }
    })

    const entitiesAtRisk = entityCardsWithDs.reduce((acc, card) => acc + card.atRiskCount, 0)

    return {
      totalCards: entityCardsWithDs.length,
      cards: entityCardsWithDs,
      entitiesAtRisk,
      entityTypes: entityCardsWithDs.length
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryAccess360Widget = (params: IGetAccessControlUsersFilterParams) => {
  const filterStr = getUserFiltersString(params)
  const dsIdsFilter = params.datasourceId ? `datasourceIds: ["${params.datasourceId}"],` : ''
  return gql`
    query access360Widget {
      openAccessObjects: accessControlObjects(hasOpenAccess: true ${dsIdsFilter}) {
        count
      }
      excessiveAccessObjects: accessControlObjects(hasExcessiveAccess: true ${dsIdsFilter}) {
        count
      }
      crossDepartmentAccessObjects: accessControlObjects(hasCrossDepartmentAccess: true ${dsIdsFilter}) {
        count
      }
      objects(isSensitive: true ${dsIdsFilter}) {
        count
      }
      usersWithAccessToEntities: accessControlUsers(hasEntityAccess: true ${filterStr}) {
        count
      }
      accessControlUsers(sensitivity: [HIGH, MEDIUM] ${filterStr}) {
        count
      }
    }
  `
}

export const mapQueryAccess360Widget = (raw: any): Access360Widget => {
  const {
    openAccessObjects,
    excessiveAccessObjects,
    crossDepartmentAccessObjects,
    objects,
    accessControlUsers,
    usersWithAccessToEntities
  } = raw
  return {
    usersCount: accessControlUsers?.count || 0,
    openAccessObjects: openAccessObjects?.count || 0,
    execessiveAccessObjects: excessiveAccessObjects?.count || 0,
    objectsCount: objects?.count || 0,
    crossDepartmentAccessObjects: crossDepartmentAccessObjects?.count || 0,
    usersWithAccessToEntities: usersWithAccessToEntities?.count || 0
  }
}

export const queryEntityTypesWithAccess = (params: IGetAccessControlUsersFilterParams) => {
  const { datasourceId } = params
  return gql`
  query accessControlUsersEntityGroupedByType {
      accessControlUsers(hasEntityAccess: true) {
        count
      }
      accessControlUsersEntityGroupedByType(datasourceIds: "${datasourceId}") {
          edges {
              node {
                  name
                  id
                  entities {
                      count
                  }
                  # TODO
                  accessibleEntityCount: entities(isSensitive: true) {
                      count
                  }
              }
          }
      }
  }
  `
}

export const mapQueryEntityTypesWithAccess = (raw: any): AccessControlEntityAccessWidget => {
  const { accessControlUsers, accessControlUsersEntityGroupedByType } = raw

  return {
    totalUsers: accessControlUsers?.count || 0,
    entityTypes: accessControlUsersEntityGroupedByType?.edges?.map(({ node }) => ({
      id: node.id,
      name: node.name,
      entityCount: node.entities?.count || 0,
      accessibleEntityCount: node.accessibleEntityCount?.count || 0
    }))
  }
}

export const queryObjectsWithCrossDepartmentAccessWithDepartments = (
  params: IGetAccessControlUsersFilterParams
) => {
  const { datasourceId, sensitivityLevel } = params
  const filterDataSourceIds = `datasourceIds: ["${datasourceId}"]`

  return gql`
    query accessControlObjectsCrossDeptAccessGroupedByDept {
        accessControlObjectsCrossDeptAccessGroupedByDept(
            ${filterDataSourceIds}
            sensitivity: ${sensitivityLevel}
        ) {
            departmentName
            ownerCount
            objectCount
            crossDepartmentAccessInfo {
                userCount
                departmentName
            }
        }
        totalDepartments: accessControlObjectGroupByDepartment(
          hasCrossDepartmentAccess: true
          sensitivity: ${sensitivityLevel}
          ${filterDataSourceIds}
        ) {
          count
        }
        impactedAccessOwners: accessControlObjectGroupByUserOwner(
          hasCrossDepartmentAccess: true
          sensitivity: ${sensitivityLevel}
          ${filterDataSourceIds}
        ) {
            count
        }
        totalObjects: accessControlObjects(
          hasCrossDepartmentAccess: true
          sensitivity: ${sensitivityLevel}
          ${filterDataSourceIds}
        ) {
            count
        }
    }
  `
}

export const mapQueryObjectsWithCrossDepartmentAccessWithDepartments = (
  raw: any
): AccessControlCrossDepartmentAccessWidget => {
  const {
    totalObjects,
    impactedAccessOwners,
    accessControlObjectsCrossDeptAccessGroupedByDept,
    totalDepartments
  } = raw

  return {
    totalObjects: totalObjects?.count || 0,
    totalDepartments: totalDepartments?.count || 0,
    totalOwners: impactedAccessOwners?.count || 0,
    departments: accessControlObjectsCrossDeptAccessGroupedByDept?.map((node) => ({
      name: node?.departmentName || '',
      totalObjects: +node?.objectCount || 0,
      totalOwners: +node?.ownerCount || 0,
      crossDepartments: node?.crossDepartmentAccessInfo?.map((node) => ({
        name: node?.departmentName || '',
        userCount: +node?.userCount || 0
      }))
    }))
  }
}

export const queryAccessibleWidget = (params: IGetAccessControlUsersFilterParams) => {
  const { datasourceId, entityId, attributeId } = params
  const filterDataSourceId = datasourceId ? `datasourceId: "${datasourceId}"` : ''
  if (entityId) {
    return gql`
      query accessibleWidgets {
        userEntities(entityIds: ["${entityId}"], ${filterDataSourceId}) {
          edges {
            node {
                userAccess {
                    count
                }
                groupAccess {
                    count
                }
            }
          }
        }
      }
    `
  } else if (attributeId) {
    // todo add attribute integration
    return ``
  }
  return ``
}

export const mapQueryAccessibleWidget = (raw: any): AccessControlAccessibleWidget => {
  const { userEntities } = raw
  return {
    usersCount: userEntities?.edges?.[0]?.node?.userAccess?.count || 0,
    groupsCount: userEntities?.edges?.[0]?.node?.groupAccess?.count || 0,
    rolesCount: 0,
    resourcesCount: 0
  }
}

export const queryAccessControlFilters = () => {
  return gql`
    query accessControlFilters {
      entityType {
        edges {
          node {
            id
            name
          }
        }
      }
      totalDepartments: accessControlUsersGroupedByDept {
        count
        edges {
          node {
            departmentName
          }
        }
      }
      datasources(isIdpEnabled: true) {
        edges {
          node {
            id
            name
          }
        }
      }
      attribute(booleanFilter: [{ key: ENABLED, value: true }]) {
        edges {
          node {
            id
            name
            internalName
          }
        }
      }
    }
  `
}

export const mapQueryAccessControlFilters = (raw: any): AccessControlFilters => {
  const { entityType, datasources, attribute } = raw
  return {
    entityTypes: entityType?.edges?.map(({ node }) => ({
      id: node.id,
      name: node.name
    })),
    datasources: datasources?.edges?.map(({ node }) => ({
      id: node.id,
      name: node.name
    })),
    departments: raw?.totalDepartments?.edges?.map(({ node }) => node?.departmentName || ''),
    attributes: attribute?.edges?.map(({ node }) => ({
      id: node.id,
      name: node.name,
      internalName: node.internalName
    }))
  }
}

export const queryGroups = (params: IGetAccessControlGroupsFilterParams) => {
  const { entityId, datasourceId } = params
  const filters = getUserFiltersString(params, { hasPagination: true })

  const filterDatasource = datasourceId ? ` ,datasourceId: "${datasourceId}"` : ``

  const query = entityId
    ? gql`
    query accessControlGroups {
      userEntities(id: "${entityId}" ${filterDatasource}) {
          edges {
              node {
                  groupAccess(${filters})  {
                    count
                    edges {
                    node {
                      id
                      name
                      type
                      dataPrivilegeLevel
                      memberUsers {
                        count
                      }
                      memberGroups {
                        count
                      }
                      attributesGroupedByType {
                        count
                      }
                      datasources {
                        count
                      }

                    }
                  }
                  }
                  userAccess{
                      count
                  }
              }
          }
      }
  }
  `
    : gql`
      {
        totalGroups: accessControlGroups${filterDatasource ? `(${filterDatasource})` : ''} {
          count
        }
        highSensitiveCount: accessControlGroups(sensitivity: [HIGH] ${filterDatasource}) {
          count
        }
        accessControlGroups(${filters}) {
          count
          edges {
            node {
              id
              name
              type
              dataPrivilegeLevel
              memberUsers {
                count
              }
              memberGroups {
                count
              }
              attributesGroupedByType {
                count
              }
              datasources {
                count
              }
              dataPrivilegedMembers: memberUsers(sensitivity:[${DataSensitivityLevels.High}]) {
                count
              }
            }
          }
        }
      }
  `
  return query
}

export const mapQueryGroups = (raw: any) => {
  const { accessControlGroups, totalGroups, highSensitiveCount } = raw

  const list = accessControlGroups.edges.map(({ node }) => ({
    id: node?.id,
    name: node?.name,
    type: node?.type,
    dataPrivilegeLevel: node?.dataPrivilegeLevel,
    usersCount: node?.memberUsers?.count || 0,
    groupsCount: node?.memberGroups?.count || 0,
    attributesCount: node?.attributesGroupedByType?.count || 0,
    datasourcesCount: node?.datasources?.count || 0,
    dataPrivilegeMemberCount: node?.dataPrivilegedMembers?.count || 0
  }))

  return {
    list,
    total: accessControlGroups?.count,
    groupsCount: totalGroups?.count,
    highSensitiveCount: highSensitiveCount?.count
  }
}

export const queryGroupOverview = (params: IGetAccessControlGroupsFilterParams) => {
  const { id, datasourceId } = params
  const dsIdsFilter = datasourceId ? `, datasourceIds: "${datasourceId}"` : ``
  const dsKeyfilterStr = datasourceId ? ` ,{ key: DATASOURCE_ID, values: "${datasourceId}"}` : ``
  const filterAccessGroupIds = id ? ` ,accessControlGroupIds: "${id}"` : ``
  return gql`
    query groupDetailsOverview {
      accessControlObjects(accessControlGroupIds: "${id}" ${dsIdsFilter} sensitivity: ${
    DataSensitivityLevels.High
  }) {
        count
      }
      entitiesCount: userEntities(
        filter: [{ key: ACCESS_CONTROL_GROUP_IDS, values: ["${id}"]} ${dsKeyfilterStr}]
      ) {
        count
      }
      ${
        dsIdsFilter
          ? `openAccessObjects: accessControlObjects(
        first: 1,
        hasOpenAccess: true,
        ${filterAccessGroupIds}
        ${dsIdsFilter}
      ) {
        count
      }
      excessiveAccessObjects: accessControlObjects(
        first: 1,
        hasExcessiveAccess: true
        ${filterAccessGroupIds}
        ${dsIdsFilter}
      ) {
        count
      }
      crossDeptAccessObjects: accessControlObjects(
        first: 1,
        hasCrossDepartmentAccess: true,
        ${filterAccessGroupIds}
        ${dsIdsFilter}
      ) {
        count
      }`
          : ''
      }
      accessControlGroups(id: "${id}") {
        edges {
          node {
            id
            name
            type
            dataPrivilegeLevel
            memberGroups {
              count
              edges {
                node {
                  id
                  name
                }
              }
            }
            memberUsers {
              count
            }
            objects {
              count
            }
            datasources {
              count
            }
            attributesGroupedBySensitivity {
              edges {
                node {
                  count
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapQueryGroupOverview = (raw: any): AccessControlGroupDetailsOverview => {
  const { accessControlGroups, entitiesCount } = raw

  const group = accessControlGroups?.edges?.[0]?.node
  let attributesCount = 0
  group?.attributesGroupedBySensitivity.edges.forEach(({ node }) => {
    attributesCount += node?.count || 0
  })

  const subGroups = group?.memberGroups?.edges.map(({ node }) => {
    return node
  })

  return {
    id: group?.id,
    name: group?.name,
    type: group?.type,
    dataPrivilegeLevel: group?.dataPrivilegeLevel,
    usersCount: group?.memberUsers?.count,
    groupsCount: group?.memberGroups?.count,
    subGroups,
    objectsCount: group?.objects?.count,
    datasourcesCount: group?.datasources?.count,
    entitiesCount: entitiesCount?.count || 0,
    attributesCount
  }
}

export const queryUsersByGroup = (params: IGetAccessControlUsersFilterParams) => {
  let filterStr = ''
  const filters = getUserFiltersString(params, { hasPagination: true })
  if (params.groupId) {
    filterStr += `,accessControlGroupIds: ["${params.groupId}"]`
  }
  return `
    query usersForGroup {
      totalContractors: accessControlUsers(employeeType: ${
        AccessControlEmployeeType.Contractor
      } ${filterStr}) {
        count
      }
      totalEmployees: accessControlUsers(employeeType: ${
        AccessControlEmployeeType.Employee
      }, ${filterStr}) {
        count
      }
      accessControlUsers(${filters + filterStr}) {
        count
        edges {
          node {
            id
            name
            userType
            employeeType
            dataPrivilegeLevel
            indirectMember: memberOfGroups(isDirect: false){
              count
            }
            directMember: memberOfGroups(isDirect: true){
              count
            }
          }
        }
      }
    }
  `
}

export const mapQueryUsersByGroup = (raw: any) => {
  const { totalContractors, totalEmployees, accessControlUsers } = raw

  const usersList = accessControlUsers.edges.map(({ node }) => ({
    id: node?.id,
    name: node?.name,
    employeeType: node?.employeeType,
    dataPrivilegeLevel: node?.dataPrivilegeLevel,
    isDirectMember: !!node?.directMember?.count
  }))

  return {
    usersList,
    totalUsers: accessControlUsers.count,
    totalContractorsCount: totalContractors.count,
    totalEmployeesCount: totalEmployees.count
  }
}

export const querySubGroups = (params: IGetAccessControlGroupsFilterParams) => {
  let filterStr = ''
  const groupFilters = getGroupFiltersString(params)
  if (params.groupId) {
    filterStr += `accessControlGroupIds: ["${params.groupId}"]`
  }
  return gql`
    {
      accessControlGroups(${filterStr}) {
        edges {
          node {
            id
            name
            memberGroups${groupFilters ? `(${groupFilters})` : ''} {
              count
              edges {
                node {
                  id
                  name
                  dataPrivilegeLevel
                  memberUsers {
                    count
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapQuerySubGroups = (raw: any) => {
  const { accessControlGroups } = raw
  const subGroups = accessControlGroups?.edges[0]?.node?.memberGroups?.edges?.map(({ node }) => {
    return {
      id: node?.id,
      name: node?.name,
      dataPrivilegeLevel: node?.dataPrivilegeLevel,
      membersCount: node?.memberUsers?.count
    }
  })
  return {
    list: subGroups,
    total: accessControlGroups?.edges[0]?.node?.memberGroups?.count || 0
  }
}

export const queryGroupsByUser = (params: IGetAccessControlGroupsFilterParams) => {
  const groupFilters = getGroupFiltersString(params)
  return `
    {
      accessControlUsers(id: "${params?.id}") {
        edges {
          node {
            id
            memberOfGroups${groupFilters ? `(${groupFilters})` : ''} {
              count
              edges {
                node {
                  id
                  name
                  dataPrivilegeLevel
                  attributesGroupedByType {
                    count
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapQueryGroupsByUser = (raw: any) => {
  const { accessControlUsers } = raw
  const groups = accessControlUsers?.edges[0]?.node.memberOfGroups?.edges.map(({ node }) => {
    return {
      id: node?.id,
      name: node?.name,
      dataPrivilegeLevel: node?.dataPrivilegeLevel,
      attributesCount: node?.attributesGroupedByType?.count
    }
  })
  return {
    groups,
    totalGroups: accessControlUsers?.edges[0]?.node.memberOfGroups?.count
  }
}

export const queryAccessControlIdpDatasources = () => {
  return gql`
    query datasourcesList {
      datasources {
        edges {
          node {
            id
            name
            type
          }
        }
      }
    }
  `
}

export const mapQueryAccessControlIdpDatasources = (raw: any): DataSource[] => {
  const { datasources } = raw
  return datasources?.edges?.map(({ node }) => ({
    id: node.id,
    name: node.name,
    type: node.type
  }))
}

const getFragmentForCronSchedule = (params: IdentityProviderParams) => {
  return `
  cronSchedule: {
    cronType: ${params?.cronSchedule?.cronType}
    humanReadableSchedule: {
      frequencyType: ${params.cronSchedule?.humanReadableSchedule?.frequencyType}
      frequencyValue: "${params.cronSchedule?.humanReadableSchedule?.frequencyValue}"
    }
  }
  `
}

const getFragmentForGoogle = (params: IdentityProviderParams) => {
  return `
    googleWorkspaceConfiguration: {
      delegatedCredential: "${params.delegatedCredential}"
      accountJson: "${params.accountJson}"
    }
    name: "${params.name}"
    ownerEmail: "${params.email}"
    datasourceIds: ${JSON.stringify(params.datasourceIds)}
    ${getFragmentForCronSchedule(params)}
  `
}

const getFragmentForActiveDirectory = (params: IdentityProviderParams, isUpdate = false) => {
  return `activeDirectoryConfiguration: {
            username: "${params.username}"
            password: "${params.password}"
            endpoint: "${params.endpoint}"
            domain: "${params.domain}"
            ssl: ${params.sslEnabled}
          }
          name: "${params.name}"
          ownerEmail: "${params.email}"
          datasourceIds: ${JSON.stringify(params.datasourceIds)}
          ${getFragmentForCronSchedule(params)}
          ${
            !isUpdate
              ? `entraAd: ${params.entraAd}
          `
              : ''
          }
        `
}

export const mutationCreateIdentityProvider = (params: IdentityProviderParams) => {
  const { type } = params
  const isGoogle = type === DirectoryOptions.Google
  let fragment = ``
  if (isGoogle) {
    fragment = `
      createGoogleWorkspaceIdp(
        clientMutationId: "1",
        googleWorkspaceData: {
          ${getFragmentForGoogle(params)}
        }
      )
    `
  } else {
    fragment = `
     createActiveDirectoryIdp(
        activeDirectoryData: {
          ${getFragmentForActiveDirectory(params)}
        }
        clientMutationId: "1"
      )
    `
  }

  return gql`
    mutation createActiveDirectoryIdp {
      ${fragment}{
        clientMutationId
        idpId
      }
    }
  `
}

export const queryAccessControlIdpList = (params: IGetAccessControlUsersFilterParams) => {
  const filters = getUserFiltersString(params, { hasPagination: true })
  return gql`
    query accessControlIdentityProviderList {
      identityProvider(${filters}) {
        count
        edges {
          node {
            id
            name
            idpType
            lastSyncedAt
            datasource {
              count
            }
          }
        }
      }
    }
  `
}

export const mapQueryAccessControlIdpList = (
  raw: any
): {
  list: DirectoryService[]
  total: number
} => {
  try {
    return {
      list: raw.identityProvider?.edges?.map(({ node }) => ({
        id: node?.id || '',
        name: node?.name || '',
        idpType: node?.idpType || '',
        lastSyncedAt: node?.lastSyncedAt || '',
        datasourceCount: node?.datasource?.count || 0
      })),
      total: raw.identityProvider?.count || 0
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryAccessControlIdpById = (id: string) => {
  return gql`
    query accessControlIdentityProviderById {
      identityProvider(filter: [{key: ID, values: ["${id}"]}]) {
        edges {
          node {
            id
            name
            description
            ownerEmail
            # endpoint
            # domain
            idpType
            lastSyncedAt
            cronSchedule {
              humanReadableSchedule{
                frequencyType,
                frequencyValue
              }
              cronType
            }
            datasource {
              count
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapQueryAccessControlIdpById = (raw: any): DirectoryService => {
  try {
    const { datasource, ...rest } = raw?.identityProvider?.edges?.[0]?.node
    return {
      ...rest,
      datasources: datasource?.edges?.map(({ node }) => ({
        id: node.id
      })),
      datasourceCount: datasource?.count || 0
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const mutationUpdateIdentityProvider = (params: IdentityProviderParams) => {
  const isGoogle = params.type === DirectoryOptions.Google
  const updateFragment = isGoogle
    ? getFragmentForGoogle(params)
    : getFragmentForActiveDirectory(params, true)
  const { id } = params

  return gql`
    mutation updateIdp {
      updateIdp(
        clientMutationId: "1",
        idpId: "${id}",
        idpData: {
          ${updateFragment}
        }
      ) {
        clientMutationId
      }
    }
  `
}

export const queryAlertsOverviewWidget = (params: IGetAccessControlUsersFilterParams) => {
  const datasourceFilter = params.datasourceId ? `, datasourceIds: ["${params.datasourceId}"],` : ''
  return gql`
    query accessControlAlertsOverview {
      alert(
        first: 50
        severity: ${SEVERITY_LEVEL.critical}
        filter: [
          { key: STATUS, values: ["${ALERT_STATUS_API_MAP[ALERT_STATUS.active]}", "${
    ALERT_STATUS_API_MAP[ALERT_STATUS.resolving]
  }", "${ALERT_STATUS_API_MAP[ALERT_STATUS.muting]}", "${
    ALERT_STATUS_API_MAP[ALERT_STATUS.unmuting]
  }"] }
          { key : POLICY_TYPE, values: ["${POLICY_TYPES.ACCESS_POLICY}"]}
        ]
        ${datasourceFilter}
        ${params.emailId ? `subAlertAssignees: ["${params.emailId}"]` : ''}
      ) {
        edges {
          node {
            id
            name
            status
            severity
            generatedAt
            assignees
            datasource {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapQueryAlertsOverviewWidget = (raw: any): AlertsOverviewWidget[] => {
  const { edges } = raw.alert

  const alerts: AlertsOverviewWidget[] = edges.map(({ node }) => {
    const { assignees, datasource: { edges: datasourceEdges = [] } = {} } = node

    const [datasourceItem] = datasourceEdges || []
    const { node: { id: datasourceId = null } = {} } = datasourceItem || {}

    return {
      id: node.id,
      name: node.name,
      status: node.status,
      severity: node.severity,
      generatedAt: node.generatedAt,
      assignees: assignees?.length,
      datasourceId
    }
  })
  return alerts
}
export const mutationTestConnection = (params: TestConnectionParams) => {
  const { type } = params
  const isGoogle = type === DirectoryOptions.Google
  if (isGoogle) {
    return gql`
      mutation testConnectionGoogleWorkspaceIdp {
        testConnectionGoogleWorkspaceIdp(
          clientMutationId: "1",
          googleWorkspaceConfiguration: {
          delegatedCredential: "${params.delegatedCredential}"
          accountJson: "${params.accountJson}"
        }) {
          clientMutationId
          success
        }
      }
    `
  } else {
    return gql`
      mutation testConnectionActiveDirectoryIdp {
        testConnectionActiveDirectoryIdp(
          clientMutationId: "1",
          activeDirectoryConfiguration: {
            username: "${params.username}"
            password: "${params.password}"
            endpoint: "${params.endpoint}"
            domain: "${params.domain}"
            ssl: ${params.sslEnabled}
          }) {
          clientMutationId
          success
        }
      }
    `
  }
}

export const queryOwnersCountByDatasource = (params: IGetAccessControlUsersFilterParams) => {
  const { datasourceId } = params
  const datasourceFilter = datasourceId ? `, datasourceIds: ["${datasourceId}"]` : ''
  return gql`
    query accessControlObjectGroupByUserOwner {
      accessControlObjectGroupByUserOwner(${datasourceFilter}) {
        count
      }
    }
  `
}

export const mapOwnersCountByDatasource = (raw: any): number => {
  return raw.accessControlObjectGroupByUserOwner?.count || 0
}
