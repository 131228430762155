import { isAirByteDatasource } from './datasourcesList'
import ChartBarSimple from '../../../components/ChartBarSimple/chartBarSimple'
import PercentageIndicator from '../../../components/PercentageIndicator/percentage'
import TrendWidget from '../../../components/TrendSummaryWidget/trendSummaryWidget'
import PrintPageHeader from '../../components/header'
import {
  DatasourceAtrributeDelta,
  HistoricalStats,
  fetchDeltaHighSensitivyAttributeInstance,
  fetchDeltaPrintDataSourcesTotalAttributes,
  stats
} from '../../printSlice'
import { RootState } from '../../../rootReducer'
import { DATA_SOURCE_TYPES } from '../../../constants'
import { filterCurrentDayData } from '../../../utils/timeUtil'
import {
  getDeltaReportAttributeInstanceAggregation,
  percentageDeltaCalculator
} from '../deltaReportUtil'
import { DataSource } from '../../../services/api/apiTypes'
import { Table } from 'semantic-ui-react'
import { FormattedNumber, useIntl } from 'react-intl'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import dayjs from 'dayjs'

interface IProps {
  historicalStats?: HistoricalStats
  subText?: string
  fetchDeltaHighSensitivyAttributeInstance: (datasourceIds: string[]) => void
  fetchDeltaPrintDataSourcesTotalAttributes: (datasourceId: string) => void
  historicalHighSensitivityAttributes?: {
    startTimestamp: string
    endTimestamp: string
    attributes: stats[]
  }[]
  unstructDatasourcesList?: DataSource[]
  datasourceId?: string
  datasourceName?: string
  datasourceAttributeInstanceStats?: DatasourceAtrributeDelta
  datasourceHighSensitiveAttributeInstanceStats?: DatasourceAtrributeDelta
  datasourceTotalAttributeInstanceStats?: DatasourceAtrributeDelta
  datasourceType?: DATA_SOURCE_TYPES
}
const renderBarLegend = () => (
  <div
    className="recharts-legend-wrapper"
    style={{
      position: 'absolute',
      width: '973px',
      height: 'auto',
      left: '50px'
    }}
  >
    <ul
      className="recharts-default-legend xs-mt-16"
      style={{ padding: '0px', margin: '0px', textAlign: 'left' }}
    >
      <li
        className="recharts-legend-item legend-item-0"
        style={{ display: 'inline-block', marginRight: '10px' }}
      >
        <svg
          className="recharts-surface"
          width="40"
          height="14"
          viewBox="0 0 32 32"
          version="1.1"
          style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: '12px' }}
        >
          <path
            stroke="none"
            fill="#6941C6"
            d="M0,4h100v24h-100z"
            className="recharts-legend-icon"
          ></path>
        </svg>
        <span className="recharts-legend-item-text">High Sensitivity</span>
      </li>
      <li
        className="recharts-legend-item legend-item-1"
        style={{ display: 'inline-block', marginRight: '10px' }}
      >
        <svg
          className="recharts-surface"
          width="40"
          height="14"
          viewBox="0 0 32 32"
          version="1.1"
          style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: '12px' }}
        >
          <path
            stroke="none"
            fill="#D9D9D9"
            d="M0,4h100v24h-100z"
            className="recharts-legend-icon"
          ></path>
        </svg>
        <span className="recharts-legend-item-text">Total Attribute</span>
      </li>
    </ul>
  </div>
)
const getAttrStats = (attributes) => {
  return attributes.map((stats) => {
    const agreegatedAttributes = stats.attributes.reduce(
      (acc, attribute) => {
        return {
          startTotal: acc.startTotal + attribute.startTotal,
          total: acc.total + attribute.total,
          added: acc.added + attribute.added,
          deleted: acc.deleted + attribute.deleted,
          startAdded: acc.startAdded + attribute.startAdded,
          startDeleted: acc.startDeleted + attribute.startDeleted
        }
      },
      { startTotal: 0, startDeleted: 0, startAdded: 0, total: 0, added: 0, deleted: 0 }
    )
    return {
      startTimestamp: stats.startTimestamp,
      endTimestamp: stats.endTimestamp,
      startTotal: agreegatedAttributes.startTotal,
      total: agreegatedAttributes.total,
      added: agreegatedAttributes.added,
      deleted: agreegatedAttributes.deleted,
      startAdded: agreegatedAttributes.startAdded,
      startDeleted: agreegatedAttributes.startDeleted
    }
  })
}
const AttributesHighTotal = ({
  historicalStats,
  fetchDeltaHighSensitivyAttributeInstance,
  fetchDeltaPrintDataSourcesTotalAttributes,
  historicalHighSensitivityAttributes,
  datasourceHighSensitiveAttributeInstanceStats,
  datasourceTotalAttributeInstanceStats,
  unstructDatasourcesList = [],
  datasourceId = '',
  subText,
  datasourceName,
  datasourceType
}: IProps): React.ReactElement => {
  const intl = useIntl()
  const showDeleted = datasourceType ? !isAirByteDatasource(datasourceType) : true
  useEffect(() => {
    if (!historicalHighSensitivityAttributes?.length) {
      unstructDatasourcesList &&
        unstructDatasourcesList.length &&
        fetchDeltaHighSensitivyAttributeInstance(unstructDatasourcesList.map((ds) => ds.id || ''))
    }
  }, [
    fetchDeltaHighSensitivyAttributeInstance,
    historicalHighSensitivityAttributes,
    unstructDatasourcesList,
    datasourceId
  ])

  useEffect(() => {
    if (datasourceId) {
      fetchDeltaPrintDataSourcesTotalAttributes(datasourceId)
    }
  }, [datasourceId, fetchDeltaPrintDataSourcesTotalAttributes])
  const totalAttrInstances = datasourceId
    ? datasourceTotalAttributeInstanceStats?.[datasourceId]
    : historicalStats?.unstructuredAttributeInstanceStats

  const highSensitiveAttrInstances = datasourceId
    ? datasourceHighSensitiveAttributeInstanceStats?.[datasourceId]
    : historicalHighSensitivityAttributes

  const agreegatedAttrStats = getDeltaReportAttributeInstanceAggregation(totalAttrInstances || [])
  const agreegatedHighSensitiveAttrStats = getDeltaReportAttributeInstanceAggregation(
    highSensitiveAttrInstances || []
  )
  const aggreegatedPercentageChange = percentageDeltaCalculator(
    agreegatedAttrStats.startTotal,
    agreegatedAttrStats.total
  )
  const attributeInstanceStats = getAttrStats(totalAttrInstances || [])
  const filteredAttributeInstanceStats = filterCurrentDayData(attributeInstanceStats)
  const attributeHighSensitivityInstanceStats = filterCurrentDayData(
    getAttrStats(highSensitiveAttrInstances || [])
  )
  const barData = filteredAttributeInstanceStats.map((stats, index) => {
    return {
      name:
        dayjs(stats.startTimestamp).format('D MMM') +
        ' - ' +
        dayjs(stats.endTimestamp).format('D MMM'),
      Total: stats.total,
      'High Sensitivity': attributeHighSensitivityInstanceStats[index]?.total
    }
  })
  const tableData =
    filteredAttributeInstanceStats.map((stats, index) => {
      return {
        dateRange:
          dayjs(stats.startTimestamp).format('D MMM') +
          ' - ' +
          dayjs(stats.endTimestamp).format('D MMM'),
        startTotalAttributes: +stats?.startTotal || 0,
        totalAttributes: +stats.total || 0,
        startTotalPiiAttributes: +attributeHighSensitivityInstanceStats[index]?.startTotal || 0,
        totalPiiAttributes: +attributeHighSensitivityInstanceStats[index]?.total || 0
      }
    }) || []
  const yLabelFormatter = (value) => intl.formatNumber(value, { notation: 'compact' })
  return (
    <div className="sensitive-files delta-print-page" id={'attribute-high-total' + datasourceId}>
      <PrintPageHeader
        text={'Sensitive Data Count: Total V/S High'}
        {...(subText
          ? { subText }
          : datasourceId
          ? {
              subText: (
                <>
                  Datasource: <strong>{datasourceName}</strong>
                </>
              )
            }
          : {})}
        {...(subText || datasourceId ? { className: 'align-horizontal' } : {})}
      />
      <div className="xs-m-48 border">
        <TrendWidget
          data={[
            {
              count: agreegatedAttrStats.total,
              text: 'Current',
              percentage: aggreegatedPercentageChange
            },
            { count: agreegatedAttrStats.added, text: 'Instances Added', bulletColor: '#13C2C2' },
            {
              count: showDeleted ? agreegatedAttrStats.deleted : null,
              text: 'Instances Deleted',
              bulletColor: '#CF1322'
            }
          ]}
          weeksCount={4}
        />
      </div>
      <div className="xs-mx-48 xs-mb-48">
        <p className="widget-title  widget-title-uppercase xs-mb-24">Total v/s High Sensitivity</p>
        <div className="xs-mb-4">
          <span className="attribute-counts xs-ml-0">
            <span className="attribute-bold">
              <FormattedNumber
                value={agreegatedHighSensitiveAttrStats?.total || 0}
                notation="compact"
              />
            </span>
            High Sensitivity
          </span>
          <span className="attribute-counts xs-ml-60">
            <span className="attribute-bold">
              <FormattedNumber value={agreegatedAttrStats?.total || 0} notation="compact" />
            </span>
            Total Attributes
          </span>
        </div>
        <ChartBarSimple
          data={barData}
          chartResponsiveHeight={250}
          showAxisLine={true}
          print={true}
          cartesianGridHorrizontal={true}
          cartesianGridVertical={true}
          barSize={33}
          xLabelAngle={0}
          xAxisAngle={0}
          tickFontSize={{ fontSize: '12px' }}
          barColors={[
            { key: 'High Sensitivity', color: '#6941C6' },
            { key: 'Total', color: '#D9D9D9' }
          ]}
          yLabelFormatter={yLabelFormatter}
          YTickFormatter={yLabelFormatter}
          defaultTooltip={true}
          renderLegend={renderBarLegend}
        />
      </div>
      <div className="xs-mt-24 xs-mx-48 border">
        <Table very="simple" celled>
          <Table.Header>
            <Table.Row>
              {['Date Range', 'Total Attributes', 'High Sensitivity'].map((text, index) => (
                <Table.HeaderCell key={index} textAlign="center" className="xs-py-16">
                  <p className="text-title">{text}</p>
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {tableData.map((data, index) => (
              <Table.Row key={index}>
                <Table.Cell>{data.dateRange}</Table.Cell>
                <Table.Cell>
                  <FormattedNumber value={data.totalAttributes} notation="compact" />
                  <PercentageIndicator
                    size="sm"
                    percentage={percentageDeltaCalculator(
                      data.startTotalAttributes,
                      data.totalAttributes,
                      true
                    )}
                  />
                </Table.Cell>
                <Table.Cell>
                  <FormattedNumber value={data.totalPiiAttributes} notation="compact" />
                  <PercentageIndicator
                    size="sm"
                    percentage={percentageDeltaCalculator(
                      data.startTotalPiiAttributes,
                      data.totalPiiAttributes,
                      true
                    )}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </div>
  )
}
const mapStateToProps = (state: RootState) => ({
  historicalHighSensitivityAttributes: state.print.historicalHighSensitivityAttributes,
  datasourceHighSensitiveAttributeInstanceStats:
    state.print.datasourceHighSensitivityAttributeInstanceStats,
  datasourceTotalAttributeInstanceStats: state.print.datasourceTotalAttributeInstanceStats
})

const mapDispatchToProps = {
  fetchDeltaHighSensitivyAttributeInstance,
  fetchDeltaPrintDataSourcesTotalAttributes
}

export default connect(mapStateToProps, mapDispatchToProps)(AttributesHighTotal)
