import ErrorBoundary from '.'
import apiService from '../../services/api/apiService'
import React from 'react'
import { parse } from 'query-string'

interface IProps {
  children: React.ReactNode
}

const ReportingErrorBoundary = ({ children }: IProps) => {
  const { requestId = '' } = parse(window.location.search) as {
    requestId: string
  }
  const handleError = (error) => {
    requestId &&
      apiService.postPdfDownloadError(
        requestId,
        {
          message: error.stack
        },
        'failed'
      )
  }
  return <ErrorBoundary onError={handleError}>{children}</ErrorBoundary>
}

export default ReportingErrorBoundary
