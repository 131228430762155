import { INotification } from '../notificationSlice'
import { Message, Icon } from 'semantic-ui-react'
import { FormattedMessage } from 'react-intl'
import React from 'react'

type IProps = INotification & {
  onClose: (id: string) => void
}

const NotificationItem = (props: IProps): any => {
  const { id, success, error, values, skipIntl = false, onClose } = props
  const handleClose = () => onClose(id)
  const isSuccess = !!success
  const isError = !!error

  const content = success || error

  return (
    <Message
      positive={isSuccess}
      negative={isError}
      onDismiss={handleClose}
      className="xs-p-16"
      data-test-id="notification-notification-item"
    >
      {isError && <Icon name="warning circle" className="xs-mt-4" />}
      {isSuccess && <Icon name="check circle" className="xs-mt-4" />}
      <Message.Content className="xs-mr-8">
        {isSuccess && (
          <p className="msg-title xs-mb-4">
            <FormattedMessage id="notification.success.title" />
          </p>
        )}
        {isError && (
          <p className="msg-title xs-mb-4">
            <FormattedMessage id="notification.error.title" />
          </p>
        )}
        <p className="msg-content xs-mt-0">
          {skipIntl ? content : <FormattedMessage id={content} values={values} />}
        </p>
      </Message.Content>
    </Message>
  )
}

export default NotificationItem
