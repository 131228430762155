import {
  mapQueryCompactLabelSets,
  mapQueryLabelById,
  mapQueryLabelSetById,
  mapQueryLabelSets,
  mapQueryLabelSetsList,
  mapQueryLabelsList,
  mapQueryLabelsWidget,
  mapQueryRulesetAttributes,
  mutationAttachLabel,
  mutationCreateLabel,
  mutationCreateLabelSet,
  mutationDeleteLabel,
  mutationSaveDocumentLabelProvider,
  mutationUpdateLabel,
  mutationUpdateLabelSet,
  queryCompactLabelSets,
  queryLabelById,
  queryLabelSetById,
  queryLabelSets,
  queryLabelSetsList,
  queryLabelsList,
  queryLabelsWidget,
  queryRulesetAttributes
} from './queries'
import {
  AttachDocumentParams,
  AttributesBySensitivity,
  CreateLabelParams,
  CreateLabelSetParams,
  DocumentLabel,
  DocumentLabelProvider,
  DocumentLabelSet,
  LabelByIdParams,
  LabelsWidgetParams,
  ListQueryParams,
  UpdateLabelParams,
  UpdateLabelSetParams
} from './types'
import graphqlService from '../../services/graphqlService'
import apiService from '../../services/api/apiService'
import { CriteriaOperatorTypes, Ruleset } from '../../utils/rulesetUtils'
import { AttributeSet } from '../attributes/attributesSlice'
import { Classification } from '../../services/api/apiTypes'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

export const ACTION_FETCH_LABEL_SETS_LIST = 'documentLabels/getLabelSetsList'
export const fetchDocumentLabelSetsList = createAsyncThunk(
  ACTION_FETCH_LABEL_SETS_LIST,
  async (params: ListQueryParams) => {
    const resultRaw = await graphqlService.execute(queryLabelSetsList(params))
    return mapQueryLabelSetsList(resultRaw)
  }
)

export const ACTION_FETCH_COMPACT_LABEL_SETS_LIST = 'documentLabels/getCompactLabelSetsList'
export const fetchCompactDocumentLabelSetsList = createAsyncThunk(
  ACTION_FETCH_COMPACT_LABEL_SETS_LIST,
  async () => {
    const resultRaw = await graphqlService.execute(queryCompactLabelSets())
    return mapQueryCompactLabelSets(resultRaw)
  }
)

export const ACTION_FETCH_LABELS_LIST = 'documentLabels/getLabelsList'
export const fetchDocumentLabelsList = createAsyncThunk(
  ACTION_FETCH_LABELS_LIST,
  async (params: ListQueryParams) => {
    const resultRaw = await graphqlService.execute(queryLabelsList(params))
    return mapQueryLabelsList(resultRaw)
  }
)

export const ACTION_FETCH_LABEL_SETS = 'documentLabels/getLabelSets'
export const fetchDocumentLabelSets = createAsyncThunk(ACTION_FETCH_LABEL_SETS, async () => {
  const resultRaw = await graphqlService.execute(queryLabelSets())
  return mapQueryLabelSets(resultRaw)
})

export const ACTION_FETCH_LABEL_SET_BY_ID = 'documentLabels/getLabelSetById'
export const fetchDocumentLabelSetById = createAsyncThunk(
  ACTION_FETCH_LABEL_SET_BY_ID,
  async (id: string) => {
    const resultRaw = await graphqlService.execute(queryLabelSetById(id))
    return mapQueryLabelSetById(resultRaw)
  }
)

export const ACTION_FETCH_LABEL_BY_ID = 'documentLabels/getLabelById'
export const fetchDocumentLabelById = createAsyncThunk(
  ACTION_FETCH_LABEL_BY_ID,
  async (params: LabelByIdParams) => {
    const resultRaw = await graphqlService.execute(queryLabelById(params))
    return mapQueryLabelById(resultRaw)
  }
)

export const ACTION_DOCUMENT_LABEL_PROVIDER_CONNECT = 'documentLabels/connectProvider'
export const saveDocumentLabelProvider = createAsyncThunk(
  ACTION_DOCUMENT_LABEL_PROVIDER_CONNECT,
  async (params: DocumentLabelProvider) => {
    await graphqlService.execute(mutationSaveDocumentLabelProvider(params))
  }
)

export const ACTION_DOCUMENT_LABEL_PROVIDER_TEST_CONNECTION = 'documentLabels/test-connection'
export const documentLabelProviderTestConnection = createAsyncThunk(
  ACTION_DOCUMENT_LABEL_PROVIDER_TEST_CONNECTION,
  async (params: DocumentLabelProvider) => {
    return await apiService.testDocumentLabelProviderConnection(params)
  }
)

export const ACTION_DOCUMENT_LABEL_SET_CREATE = 'documentLabels/createSet'
export const createDocumentLabelSet = createAsyncThunk(
  ACTION_DOCUMENT_LABEL_SET_CREATE,
  async (params: CreateLabelSetParams) => {
    await graphqlService.execute(mutationCreateLabelSet(params))
  }
)

export const ACTION_DOCUMENT_LABEL_SET_UPDATE = 'documentLabels/updateSet'
export const updateDocumentLabelSet = createAsyncThunk(
  ACTION_DOCUMENT_LABEL_SET_UPDATE,
  async (params: UpdateLabelSetParams) => {
    await graphqlService.execute(
      mutationUpdateLabelSet({
        ...params
      })
    )
  }
)

export const ACTION_DOCUMENT_LABEL_CREATE = 'documentLabels/createLabel'
export const createDocumentLabel = createAsyncThunk(
  ACTION_DOCUMENT_LABEL_CREATE,
  async (params: CreateLabelParams) => {
    await graphqlService.execute(mutationCreateLabel(params))
  }
)

export const ACTION_DOCUMENT_LABEL_UPDATE = 'documentLabels/updateLabel'
export const updateDocumentLabel = createAsyncThunk(
  ACTION_DOCUMENT_LABEL_UPDATE,
  async (params: UpdateLabelParams) => {
    await graphqlService.execute(mutationUpdateLabel(params))
  }
)

export const ACTION_FETCH_CLASSIFICATIONS = 'documentLabels/classifications'
export const fetchClassifications = createAsyncThunk(
  ACTION_FETCH_CLASSIFICATIONS,
  async () => await apiService.getClassificationsList()
)

export const ACTION_FETCH_LABEL_ATTRIBUTES = 'documentLabels/attributes'
export const fetchRulesetAttributes = createAsyncThunk(ACTION_FETCH_LABEL_ATTRIBUTES, async () => {
  const resultRaw = await graphqlService.execute(queryRulesetAttributes())
  return mapQueryRulesetAttributes(resultRaw)
})

export const ACTION_DOCUMENT_LABEL_DELETE = 'documentLabels/deleteLabel'
export const deleteDocumentLabel = createAsyncThunk(
  ACTION_DOCUMENT_LABEL_DELETE,
  async (params: LabelByIdParams) => {
    await graphqlService.execute(mutationDeleteLabel(params))
  }
)

export const ACTION_FETCH_LABELS_WIDGET = 'documentLabels/fetchWidget'
export const fetchLabelsWidget = createAsyncThunk(
  ACTION_FETCH_LABELS_WIDGET,
  async (params: LabelsWidgetParams): Promise<DocumentLabel[]> => {
    const resultRaw = await graphqlService.execute(queryLabelsWidget(params))
    return mapQueryLabelsWidget(resultRaw)
  }
)

export const ACTION_DOCUMENT_LABEL_ATTACH = 'documentLabels/attachLabel'
export const attachDocumentLabel = createAsyncThunk(
  ACTION_DOCUMENT_LABEL_ATTACH,
  async (params: AttachDocumentParams) => {
    const saveLbSetResponse = await graphqlService.execute(
      mutationAttachLabel({
        ...params
      })
    )
    return saveLbSetResponse
  }
)

interface DocumentLabelsState {
  sets?: DocumentLabelSet[]
  totalSets?: number
  selectedSet?: DocumentLabelSet
  labels?: DocumentLabel[]
  totalLabels?: number
  selectedLabel?: DocumentLabel
  compactSetList?: { list: DocumentLabelSet[]; total: number }
  showLabelSetModal: boolean
  showLabelModal: boolean
  selectedLabelSetId?: string
  selectedLabelId?: string
  rulesets: Ruleset[]
  classifications?: Classification[]
  attributeSets?: AttributeSet[]
  attributesBySensitivity?: AttributesBySensitivity
  groupOperator: CriteriaOperatorTypes
  isLabelAttached?: boolean
  labelsWidgetData?: DocumentLabel[]
  labelModalMode?: 'create' | 'edit' | 'view'
}

const initialState: DocumentLabelsState = {
  showLabelSetModal: false,
  showLabelModal: false,
  rulesets: [],
  groupOperator: CriteriaOperatorTypes.or
}

const documentLabelsSlice = createSlice({
  name: 'documentLabels',
  initialState,
  reducers: {
    setShowLabelSetModal: (state, action) => {
      state.showLabelSetModal = action.payload
    },
    setShowLabelModal: (state, action) => {
      state.showLabelModal = action.payload
    },
    setSelectedLabelSetId: (state, action) => {
      state.selectedLabelSetId = action.payload
    },
    setSelectedLabelId: (state, action) => {
      state.selectedLabelId = action.payload
    },
    setLabelModalMode: (state, action: PayloadAction<'create' | 'edit' | 'view'>) => {
      state.labelModalMode = action.payload
    },
    toggleCollapseAll: (state, action) => {
      const newRulesets = JSON.parse(JSON.stringify(state.rulesets || []))
      newRulesets.forEach((ruleset) => {
        ruleset.collasped = action.payload
      })
      state.rulesets = newRulesets
    },
    toggleRulesetCollapsed: (
      state,
      { payload }: { payload: { rulesetIndex: number; flag?: boolean } }
    ) => {
      const { rulesetIndex, flag } = payload
      const newRulesets = JSON.parse(JSON.stringify(state.rulesets || []))
      newRulesets[rulesetIndex].collasped =
        flag !== undefined ? flag : !newRulesets[rulesetIndex].collasped
      state.rulesets = newRulesets
    },
    setRulesets: (state, action) => {
      state.rulesets = action.payload
    },
    addRuleset: (state, action) => {
      const newRulesets = [...(state.rulesets || [])]
      newRulesets.push(action.payload)
      state.rulesets = newRulesets
    },
    saveRuleset: (state, action) => {
      const { ruleset, rulesetIndex } = action.payload
      const newRulesets = JSON.parse(JSON.stringify(state.rulesets || []))
      newRulesets[rulesetIndex] = ruleset
      state.rulesets = newRulesets
    },
    deleteRuleset: (state, action) => {
      const { rulesetIndex } = action.payload
      const newRulesets = JSON.parse(JSON.stringify(state.rulesets || []))
      newRulesets.splice(rulesetIndex, 1)
      state.rulesets = newRulesets
    },
    setGroupOperator: (state, action) => {
      state.groupOperator = action.payload
    },
    resetSelectedLabel: (state) => {
      state.selectedLabel = initialState.selectedLabel
    },
    resetSelectedLabelSet: (state) => {
      state.selectedSet = initialState.selectedSet
    },
    resetSelectedLabelSetId: (state) => {
      state.selectedLabelSetId = initialState.selectedLabelSetId
    },
    resetSelectedLabelId: (state) => {
      state.selectedLabelId = initialState.selectedLabelId
    },
    resetLabelModalMode: (state) => {
      state.labelModalMode = initialState.labelModalMode
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDocumentLabelSetsList.fulfilled, (state, { payload }) => {
      state.sets = payload.list
      state.totalSets = payload.total
    })
    builder.addCase(fetchDocumentLabelsList.fulfilled, (state, { payload }) => {
      state.labels = payload.list
      state.totalLabels = payload.total
    })
    builder.addCase(fetchDocumentLabelSets.fulfilled, (state, { payload }) => {
      state.sets = payload
    })
    builder.addCase(fetchCompactDocumentLabelSetsList.fulfilled, (state, { payload }) => {
      state.compactSetList = payload
    })
    builder.addCase(fetchDocumentLabelSetById.fulfilled, (state, { payload }) => {
      state.selectedSet = payload
    })
    builder.addCase(fetchDocumentLabelById.fulfilled, (state, { payload }) => {
      state.selectedLabel = payload.label
      state.groupOperator = payload.operator
      state.rulesets = payload.rulesets
    })
    builder.addCase(fetchClassifications.fulfilled, (state, action) => {
      state.classifications = action.payload
    })
    builder.addCase(fetchRulesetAttributes.fulfilled, (state, action) => {
      state.attributeSets = action.payload.attributeSets
      state.attributesBySensitivity = action.payload.attributesBySensitivity
    })
    builder.addCase(attachDocumentLabel.fulfilled, (state) => {
      state.isLabelAttached = true
    })
    builder.addCase(attachDocumentLabel.rejected, (state) => {
      state.isLabelAttached = false
    })
    builder.addCase(fetchLabelsWidget.fulfilled, (state, { payload }) => {
      state.labelsWidgetData = payload
    })
  }
})

export const {
  setShowLabelSetModal,
  setShowLabelModal,
  setSelectedLabelSetId,
  setSelectedLabelId,
  toggleCollapseAll,
  toggleRulesetCollapsed,
  setRulesets,
  addRuleset,
  saveRuleset,
  deleteRuleset,
  setGroupOperator,
  resetSelectedLabel,
  resetSelectedLabelSet,
  resetSelectedLabelSetId,
  resetSelectedLabelId,
  setLabelModalMode,
  resetLabelModalMode
} = documentLabelsSlice.actions

export default documentLabelsSlice.reducer
