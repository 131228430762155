import {
  Consent,
  ConsentCategoryListParams,
  ConsentCategoryPayload,
  ConsentManagementLog,
  ConsentManagementLogsParams,
  StructSubscriptionPayload,
  SubscriptionPayload,
  SystemLog
} from './consentManagementSlice'
import { LIGHTBEAM_CONSENT_SOURCE } from './constants'
import { DATA_SOURCE_TYPES, LIMIT_DEFAULT } from '../../constants'
import {
  getAfterCursor,
  parameterizeArrayofObjects,
  stringifyParams
} from '../../utils/graphqlUtil'
import { gql } from 'graphql-request'
import { uniq } from 'underscore'

const mapConsentLogBaseEntry = (edge) => {
  const node = edge.node
  const entityEdge = node.entity?.edges[0]?.node
  return {
    id: node.id,
    email: node.communicationTypeValue,
    name: entityEdge?.name[0],
    entityId: entityEdge?.id,
    createdAt: node.consentInfo?.timestamp,
    location: node.responseMetadata?.location,
    ipAddress: node.responseMetadata?.ipAddress,
    type: entityEdge?.type.edges[0]?.node.name,
    createdBy: node.createdBy,
    consentSource:
      node.createdBy === LIGHTBEAM_CONSENT_SOURCE
        ? node.preferenceCenter?.edges[0]?.node
        : edge.node.datasource?.edges[0]?.node,
    comment: '',
    consents: [] as Consent[]
  }
}

const mapConsentsBaseEntry = (edge: any) => {
  const entityEdge = edge.entity?.edges[0]?.node
  const isLightbeamCreated = edge.preferenceCenter?.edges.length > 0
  return {
    id: edge.id,
    email: edge.communicationTypeValue,
    name: entityEdge?.name[0],
    entityId: entityEdge?.id,
    createdAt: edge?.timestamp,
    location: edge.responseMetadata?.location,
    ipAddress: edge.responseMetadata?.ipAddress,
    type: entityEdge?.type?.edges[0]?.node.name,
    consentSource: isLightbeamCreated
      ? edge.preferenceCenter?.edges[0]?.node
      : edge.datasource?.edges[0]?.node,
    comment: edge.comment,
    consents: [] as Consent[]
  }
}

const mapConsentLogs = (edge: any): ConsentManagementLog => {
  try {
    const consentHistoryNode = edge.node
    const consentHistoryBaseNode = mapConsentsBaseEntry(consentHistoryNode)
    const isLightbeamCreated = consentHistoryNode.preferenceCenter?.edges.length > 0

    consentHistoryNode.consentInfo.forEach((node) => {
      let consentInfo
      if (isLightbeamCreated) {
        const consentInfoNode = node?.preferenceCategory.edges[0]?.node
        consentInfo = {
          name: consentInfoNode?.name,
          description: consentInfoNode?.displayDescription,
          id: consentInfoNode?.id
        }
      } else {
        //Needs to be checked for Structured Ds
        const consentInfoNode = node?.preferences?.edges[0]?.node
        if (consentInfoNode) {
          consentInfo = {
            name: consentInfoNode?.name,
            description: consentInfoNode?.description
          }
        }
      }
      consentHistoryBaseNode?.consents?.push({
        syncStatus: node?.syncStatus,
        consentEvent: node?.change?.toLowerCase(),
        consentInfo
      })
    })
    return consentHistoryBaseNode
  } catch (error) {
    console.error(error)
    throw error
  }
}

const mapSystemLogs = (rawResult: any) => {
  return rawResult.consentHistory.edges.map((edge: any) => {
    const node = edge.node
    const entityEdge = node.entity?.edges[0]?.node
    return {
      id: node.id,
      email: node.communicationTypeValue,
      name: entityEdge?.name[0],
      entityId: entityEdge?.id,
      createdAt: node.consentInfo?.timestamp,
      location: node.responseMetadata?.location,
      ipAddress: node.responseMetadata?.ipAddress,
      syncStatus: node.syncStatus,
      consentSource: edge.node.datasource.edges[0]?.node.name,
      consentEvent: node.consentInfo?.change.toLowerCase(),
      type: entityEdge?.type.edges[0]?.node.name,
      consentInfo: node.preferences.edges.map((edge: any) => {
        const node = edge?.node
        return {
          name: node?.name,
          description: node?.description,
          ...(node?.id ? { id: node?.id } : {})
        }
      })
    }
  })
}

export const mapQuerySystemLogs = (rawResult: any): { list: SystemLog[]; count: number } => ({
  count: rawResult.consentHistory.count,
  list: mapSystemLogs(rawResult)
})

export const mapQueryConsentManagementLogs = (rawResult: any): { list: ConsentManagementLog[] } => {
  const mainNode = rawResult.consents
  const consentLogs = mainNode.edges || []
  return { list: consentLogs.map(mapConsentLogs) }
}

const consentHistoryDetailedFragment = ({ cursorParam, filterParams, dateFilter }) => `
  consents(sortByAsc: false, sortField: TIMESTAMP, first: ${LIMIT_DEFAULT} ${cursorParam} ${dateFilter} ${filterParams} ){
    edges {
      node {
        id
        communicationTypeValue
        comment
        createdAt
        timestamp
        responseMetadata {
          location
          ipAddress
        }
        datasource {
          edges {
            node {
              id
              name
            }
          }
        }
        preferenceCenter {
          edges {
            node {
              id
              name
            }
          }
        }
        entity {
          edges {
            node {
              id
              name
              type {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
        }
        consentInfo{
          syncStatus
          change
          preferences{
            edges{
              node{
                name
                description
              }
            }
          }
          preferenceCategory{
            edges{
              node{
                id
                name
                displayDescription
              }
            }
          }
        }
      }
    }
  }
`

const systemLogsDetailedFragment = ({ cursorParam, filterParams, dateFilter }) => `
  consentHistory(sortByAsc: false, sortField: CREATED_AT, first: ${LIMIT_DEFAULT} ${cursorParam} ${dateFilter} ${filterParams}) {
    count
    edges {
      node {
        id
        communicationTypeValue
        createdBy
        type
        syncStatus
        comment
        responseMetadata {
          location
          ipAddress
        }
        consentInfo {
          ... on HubspotConsentInfo {
            timestamp
            change
          }
        }
        entity {
          edges {
            node {
              id
              name
              type {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
        }
        datasource {
          edges {
            node {
              id
              name
            }
          }
        }
        preferenceCenter {
          edges {
            node {
              id
              name
            }
          }
        }
        preferenceCategory {
          edges {
            node {
              id
              name
            }
          }
        }
        preferences {
          edges {
            node {
                name
                description
            }
          }
        }
      }
    }
  }
`

function parseConsentLogsParams(params: ConsentManagementLogsParams) {
  const cursor = getAfterCursor(params.page || 1, LIMIT_DEFAULT)
  const cursorParam = cursor ? `, after: "${cursor}"` : ''
  const filtersParameterized = parameterizeArrayofObjects(params.filters || [])
  const filterParams = filtersParameterized ? `, filter:${filtersParameterized}` : ''
  let dateFilter = ''
  if (params.start && params.end) {
    const start = `"${params.start}"`
    const end = `"${params.end}"`
    const key = 'TIMESTAMP'
    dateFilter = `dateFilter: {${stringifyParams({ start, end, key })}}`
  }
  return { cursorParam, filterParams, dateFilter }
}

export const queryConsentMangementLogs = (params: ConsentManagementLogsParams) => {
  const { cursorParam, filterParams, dateFilter } = parseConsentLogsParams(params)
  return gql`
    query consentLogs {
      ${consentHistoryDetailedFragment({ cursorParam, filterParams, dateFilter })}
    }
  `
}

export const queryConsentManagementLogsCount = (params: ConsentManagementLogsParams) => {
  const { cursorParam, filterParams, dateFilter } = parseConsentLogsParams(params)
  return gql`
    query consentLogsCount {
      consents( first: ${LIMIT_DEFAULT} ${cursorParam} ${dateFilter} ${filterParams}){
        count
      }
    }
  `
}

export const querySystemLogs = (params: ConsentManagementLogsParams) => {
  const { cursorParam, filterParams, dateFilter } = parseConsentLogsParams(params)
  return gql`
    query systemLogs{
      ${systemLogsDetailedFragment({ cursorParam, filterParams, dateFilter })}
    }
  `
}

export const mapQueryConsentManagementLogsByUser = (rawResult: any) => {
  return mapQueryConsentManagementLogs(rawResult)
}

export const queryConsentLogsForUser = (params: ConsentManagementLogsParams) => {
  const { ...consentLogParams } = params
  const { cursorParam, filterParams, dateFilter } = parseConsentLogsParams(consentLogParams)
  return gql`
    query consentHistoryForUserEntity {
            ${consentHistoryDetailedFragment({
              cursorParam,
              filterParams,
              dateFilter
            })}
    }
  `
}

export const queryConsentLogsForUserConsentCount = (params: ConsentManagementLogsParams) => {
  const { ...consentLogParams } = params
  const { cursorParam, filterParams, dateFilter } = parseConsentLogsParams(consentLogParams)
  return gql`
    query countConsentHistoryForUserEntity {
            consents(first: ${LIMIT_DEFAULT} ${cursorParam} ${dateFilter} ${filterParams}){
              count
        }
    }
  `
}

export const mapQueryConsentManagementLogsByUserSummary = (rawResult: any) => {
  const count = rawResult.latestConsentHistoryByEntity.count
  const list = rawResult.latestConsentHistoryByEntity.edges.map((edge) => {
    const node = edge.node
    const base: ConsentManagementLog = {
      id: node.id,
      createdAt: node.consentInfo.timestamp,
      consents: [
        {
          consentEvent: node.consentInfo.change.toLowerCase(),
          consentInfo: node.preferences.edges[0]?.node
        }
      ]
    }
    if (node.createdBy === LIGHTBEAM_CONSENT_SOURCE) {
      base.consentSource = node.preferenceCenter.edges[0]?.node
    }
    if (node.createdBy === DATA_SOURCE_TYPES.hubspot) {
      base.consentSource = node.datasource.edges[0]?.node
    }
    //Needs to check for Structured Ds
    return base as ConsentManagementLog
  })
  return { list, count }
}

export const queryConsentLogsForUserSummary = (entityId: string) => {
  return gql`
    {
      latestConsentHistoryByEntity(entityId: "${entityId}") {
        count
        edges {
          node {
            id
            communicationTypeValue
            createdBy
            consentInfo {
              ... on HubspotConsentInfo {
                change
                timestamp
              }
            }
            preferenceCenter {
              edges {
                node {
                  id
                  name
                  preferenceCenterGroupId
                }
              }
            }
            datasource {
              edges {
                node {
                  id
                  name
                }
              }
            }
            preferences {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapQueryConsentSourceByDatasource = (rawResult: any) => {
  return (
    rawResult.consentManagementSettings.edges
      .map((edge) => edge.node.datasource.edges[0]?.node)
      .filter(Boolean) || []
  )
}

export const queryConsentSourceByDatasource = gql`
  {
    consentManagementSettings {
      edges {
        node {
          datasource {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`

export const mapQueryConsentSourceByPreferenceCenter = (rawResult: any) => {
  return rawResult.preferenceCenter.edges
    .map((edge) => edge.node)
    .map((item) => ({ name: item.name, id: item.preferenceCenterGroupId }))
}

export const queryConsentSourceByPreferenceCenter = gql`
  {
    preferenceCenter {
      edges {
        node {
          name
          preferenceCenterGroupId
        }
      }
    }
  }
`

export const mapQueryEntityTypes = (rawResult: any) => {
  const entityTypeEdges = rawResult?.consentHistoryGroupByEntityType?.edges || []

  // Filter edges that have consents with non-zero edges
  return entityTypeEdges
    .map((edge: any) => edge?.node?.entityType?.edges[0]?.node)
    .filter((entity: any) => entity)
}

export const queryEntityTypes = gql`
  query entityType {
    consentHistoryGroupByEntityType {
      edges {
        node {
          entityType {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`

export const mapQueryLocations = (rawResult: any) => {
  return rawResult.consentHistoryGroupByLocation.edges
    .map((edge: any) => edge?.node?.location)
    .filter(Boolean)
}

export const queryLocations = gql`
  {
    consentHistoryGroupByLocation {
      edges {
        node {
          location
        }
      }
    }
  }
`

export const mapQuerySystemLogsCount = (key, rawResult) => {
  return {
    [key]: rawResult.consentHistory.count
  }
}

export const querySystemLogsCount = (params) => {
  const filtersParameterized = parameterizeArrayofObjects(params.filters || [])
  let filters = ''
  if (filtersParameterized) {
    filters = `(filter:${filtersParameterized})`
  }
  return gql`
    {
      consentHistory ${filters} {
        count
      }
    }
  `
}

export const mapQueryConsentCategories = (rawResult: any) => {
  return rawResult.preferenceCategory.edges.map((edge) => edge.node) || []
}

export const queryConsentCategories = gql`
  {
    preferenceCategory {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

export const mutateCreateConsentCategory = (params: ConsentCategoryPayload) => {
  return gql`
    mutation {
      createPreferenceCategory(
        clientMutationId: "create-category"
        preferenceCategoryData: {
          name: "${params.name}"
          owner: "${params.currentUserEmail}"
          displayTitle: "${params.displayTitle}"
          displayDescription: "${encodeURIComponent(params.displayDescription)}"
        }
      ) {
        clientMutationId
        preferenceCategoryId
      }
    }
  `
}

export const mutateUpdateConsentCategory = (params: ConsentCategoryPayload) => {
  return gql`
    mutation {
      updatePreferenceCategory(
        clientMutationId: "update-category"
        preferenceCategoryId: "${params.id}"
        preferenceCategoryData: {
          owner: "${params.currentUserEmail}"
          name: "${params.name}"
          displayTitle: "${params.displayTitle}"
          displayDescription: "${encodeURIComponent(params.displayDescription)}"
        }
      ) {
        clientMutationId
        preferenceCategoryId
      }
    }`
}

export const mutateDeleteConsentCategory = (categoryId: string) => {
  return gql`
    mutation {
      deletePreferenceCategory(
        clientMutationId: "delete-category"
        preferenceCategoryId: "${categoryId}"
      ) {
        clientMutationId
      }
    }
  `
}

export const mapFetchCategory = (rawResult: any) => {
  const consentCategory = rawResult.preferenceCategory?.edges[0]?.node
  if (consentCategory.displayDescription) {
    consentCategory.displayDescription = decodeURIComponent(consentCategory.displayDescription)
  }
  return rawResult.preferenceCategory?.edges[0]?.node
}

export const queryFetchCategory = (categoryId: string) => {
  return gql`
    query fetchPreferenceCategory {
      preferenceCategory(id: "${categoryId}") {
        edges{
          node{
            id
            name
            displayTitle
            displayDescription
          }
        }
      }
    }
  `
}

export const mapFetchConsentCategoriesList = (rawResult: any) => {
  const preferenceCategory = rawResult.preferenceCategory
  return {
    count: preferenceCategory.count,
    list: preferenceCategory.edges.map((edge) => {
      const { preferenceCenter, ...consentCategoryNode } = edge.node
      consentCategoryNode.preferenceCenter = preferenceCenter.edges.map((edge) => edge.node)
      consentCategoryNode.displayDescription = decodeURIComponent(
        consentCategoryNode.displayDescription
      )
      return consentCategoryNode
    })
  }
}

export const queryConsentCategoriesList = (params: ConsentCategoryListParams) => {
  const cursor = getAfterCursor(params.page || 1, LIMIT_DEFAULT)
  const cursorParam = cursor ? `, after: "${cursor}"` : ''
  const filtersParameterized = parameterizeArrayofObjects(params.filters || [])
  const filterParams = filtersParameterized ? `, filter:${filtersParameterized}` : ''
  return gql`
    query fetchPreferenceCategory {
      preferenceCategory(sortField: UPDATED_AT, sortByAsc: false ${cursorParam} ${filterParams}) {
        count
        edges {
          node {
            id
            name
            displayTitle
            displayDescription
            createdBy
            preferenceCenter {
              count
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapFetchPreferenceCenterForConsentCategories = (rawResult: any) => {
  const preferenceCenters = rawResult.preferenceCategory.edges
    .map((item) => item.node.preferenceCenter.edges)
    .filter((item) => item.length)
    .flat(1)
    .map((item) => item.node)

  return uniq(preferenceCenters, (item) => item.preferenceCenterGroupId).map((item) => ({
    id: item.preferenceCenterGroupId,
    name: item.name
  }))
}

export const queryPreferenceCenterForConsentCategories = gql`
  query fetchPreferenceCenter {
    preferenceCategory {
      edges {
        node {
          preferenceCenter {
            edges {
              node {
                preferenceCenterGroupId
                name
              }
            }
          }
        }
      }
    }
  }
`

function getStringifiedCommunicationMethods(communicationMethods) {
  return communicationMethods
    .map((method) => {
      const currentMethod: string[] = []
      Object.keys(method).forEach((key) => {
        currentMethod.push(`${key}: "${method[key]}"`)
      })
      return `{${currentMethod.join(',')}}`
    })
    .join(',')
}

export const mutateCreateSubscription = (params: SubscriptionPayload) => {
  return gql`
    mutation {
      createPreferences(
        clientMutationId: "create-subscription"
        preferencesData: {
          preferenceType: CUSTOM
          datasourceId: "${params.datasourceId}"
          customPreferenceInput:{
            name: "${params.name}"
            communicationMethods: [${getStringifiedCommunicationMethods(
              params.communicationMethods
            )}]
            description: "${params.description || ''}"
          }
        }
      ) {
        clientMutationId
        preferenceIds
      }
    }
  `
}

export const mutateUpdateSubscription = (params: SubscriptionPayload) => {
  return gql`
    mutation {
      updatePreferences(
        clientMutationId: "update-subscription"
        preferencesData: {
          id: "${params.id}"
          preferenceType: CUSTOM
          datasourceId: "${params.datasourceId}"
          customPreferenceInput: {
            name: "${params.name}"
            communicationMethods: [${getStringifiedCommunicationMethods(
              params.communicationMethods
            )}]
            description: "${params.description || ''}"
          }
        }
      ) {
        clientMutationId
        preferenceIds
      }
    }
  `
}

export const mutationDeleteSubscription = (subscriptionId: string) => {
  return gql`
    mutation{
      deletePreferences(
        clientMutationId:"delete-subscription"
        preferencesIds:"${subscriptionId}"
      ){
        clientMutationId
        preferenceIds
      }
    }
  `
}

export const mutationCreateStructSubscription = (params: StructSubscriptionPayload) => {
  const { datasourceId, clusterId, subscription: subs } = params

  return gql`
    mutation {
      createPreferences(
        clientMutationId: "create-subscription"
        preferencesData: {
          preferenceType: STRUCTURED_DATA
          datasourceId: "${datasourceId}"
          structuredDataPreferenceInput: {
            name: "${subs.name}"
            description: "${subs.name}"
            subscriptionType: "${subs.communication_method}"
            structuredDataMapper: {
              emailColumnId: "${subs.column_id}"
              timestampColumnId: "${subs.timestamp_column_id}"
              timestampFormat: ${subs.timestamp_format}
              consentColumnId: "${subs.consent_column_id}"
              consentColumnDataType: ${subs.consent_column_format}
              optInValue: "${subs.opt_in_value}"
              optOutValue: "${subs.opt_out_value}"
              ${clusterId ? `clusterId: "${clusterId}"` : ''}
            }
          }
        }
      ) {
        preferenceIds
      }
    }
  `
}

export const mutationGenerateSampleConsentForStructuredData = (
  params: StructSubscriptionPayload
) => {
  const { datasourceId, clusterId, subscription: subs } = params

  return gql`
    mutation {
      generateSampleConsentForStructuredData(
        clientMutationId: "generate-sample-consent-for-structured-data"
        preferenceData: {
          datasourceId: "${datasourceId}"
          tableId: "${subs.table_id}"
          structuredDataMapper: {
            emailColumnId: "${subs.column_id}"
            timestampColumnId: "${subs.timestamp_column_id}"
            timestampFormat: ${subs.timestamp_format}
            consentColumnId: "${subs.consent_column_id}"
            consentColumnDataType: ${subs.consent_column_format}
            optInValue: "${subs.opt_in_value}"
            optOutValue: "${subs.opt_out_value}"
            ${clusterId ? `clusterId: "${clusterId}"` : ''}
          }
        }
      ) {
        consentData {
          count
          edges {
            node {
              id
              communicationTypeValue
              createdBy
              type
              syncStatus
              comment
              responseMetadata {
                location
                ipAddress
              }
              consentInfo {
                ... on HubspotConsentInfo {
                  timestamp
                  change
                }
              }
              entity {
                edges {
                  node {
                    id
                    name
                    type {
                      edges {
                        node {
                          id
                          name
                        }
                      }
                    }
                  }
                }
              }
              datasource {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              preferenceCenter {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              preferenceCategory {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              preferences {
                edges {
                  node {
                      name
                      description
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapQueryModesOfCommunication = (rawResult: any) => {
  const communicationEdges = rawResult?.consentHistoryGroupByCommunicationType?.edges || []

  // Filter edges that have non-empty consents and map to communicationType
  return communicationEdges
    .map((edge: any) => edge?.node?.communicationType)
    .filter((type: any) => type)
}

export const queryModesOfCommunication = gql`
  {
    consentHistoryGroupByCommunicationType {
      edges {
        node {
          communicationType
        }
      }
    }
  }
`

export const mapQueryCreatedByDatasources = (rawResult: any) => {
  return rawResult.consentManagementSettings.edges[0].node.datasourceTypes
}

export const queryCreatedByDatasources = gql`
  {
    consentManagementSettings {
      edges {
        node {
          datasourceTypes
        }
      }
    }
  }
`

export const mapSampleConsentForStructuredData = (
  rawResult: any
): { list: ConsentManagementLog[]; count: number } => {
  try {
    const sampleConsentLogs = rawResult.generateSampleConsentForStructuredData.consentData.edges.map(
      (edge: any) => {
        const node = edge.node
        const consentHistoryBaseNode = mapConsentLogBaseEntry(edge)
        let consentInfo
        const consentInfoNode = node.preferences.edges[0]?.node
        if (consentInfoNode) {
          consentInfo = {
            name: consentInfoNode?.name,
            description: consentInfoNode?.description
          }
        }
        consentHistoryBaseNode.consents.push({
          syncStatus: node.syncStatus,
          consentEvent: node.consentInfo.change.toLowerCase(),
          consentInfo
        })
        return consentHistoryBaseNode
      }
    )

    return { count: sampleConsentLogs.length, list: sampleConsentLogs || [] }
  } catch (error) {
    console.error(error)
    throw error
  }
}

/* Query to fetch data for overview page */
export const queryConsentStats = () => {
  return gql`
    {
      consentStats {
        count
        edges {
          node {
            source
            datasource {
              edges {
                node {
                  id
                  name
                  type
                }
              }
            }
            preference {
              edges {
                node {
                  id
                  name
                }
              }
            }
            preferenceCenter {
              edges {
                node {
                  id
                  name
                }
              }
            }
            preferenceCategory {
              edges {
                node {
                  id
                  displayTitle
                }
              }
            }
            consentStats {
              total
              optIn
              optOut
            }
            locationStats {
              total
              optIn
              optOut
              location
            }
            weeklyStats {
              total
              optIn
              optOut
              start
              end
            }
            monthlyStats {
              total
              optIn
              optOut
              start
              end
            }
          }
        }
      }
    }
  `
}

/* Start of new consentStats query integration */

const formatRange = (start: Date, end: Date): string => {
  const options: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric' }
  const startDate = start.toLocaleDateString('en-US', options)
  const endDate = end.toLocaleDateString('en-US', options)
  const startFormatted = `${startDate.split(' ')[1]} ${startDate.split(' ')[0]}`
  const endFormatted = `${endDate.split(' ')[1]} ${endDate.split(' ')[0]}`
  return `${startFormatted} - ${endFormatted}`
}

/**
 * Takes stats as input
 * and performs some operation to
 * return updated formattedStats.
 *
 * @param stats
 * @param formattedStats
 * @param dataSource
 * @param preferenceCenter
 * @param consentStatSource
 * @returns formattedStats
 */
const getFormattedStats = (
  stats,
  formattedStats,
  dataSource,
  preferenceCenter,
  consentStatSource
) => {
  const { id: dataSourceId, name: dataSourceName, type: dataSourceType } = dataSource
  const { id: preferenceCenterId, name: preferenceCenterName } = preferenceCenter

  const isDatasourcePreference = consentStatSource === 'DATASOURCE_PREFERENCE'

  if (
    consentStatSource === 'DATASOURCE_PREFERENCE' ||
    consentStatSource === 'PREFERENCE_CENTER' ||
    dataSourceId ||
    preferenceCenterId
  ) {
    stats?.forEach((range, index) => {
      if (formattedStats[index]) {
        const datasourceOptInIndex = formattedStats[index]?.additionalData['Opt-Ins']?.findIndex(
          (item) => item?.id === dataSourceId
        )

        const datasourceOptOutIndex = formattedStats[index]?.additionalData['Opt-Outs']?.findIndex(
          (item) => item?.id === dataSourceId
        )

        const preferenceCenterOptInIndex = formattedStats[index]?.additionalData[
          'Opt-Ins'
        ]?.findIndex((item) => item?.id === preferenceCenterId)

        const preferenceCenterOptOutIndex = formattedStats[index]?.additionalData[
          'Opt-Outs'
        ]?.findIndex((item) => item?.id === preferenceCenterId)

        formattedStats[index] = {
          ...formattedStats[index],
          totalConsents: formattedStats[index].totalConsents + range?.total || 0,
          totalOptIns: formattedStats[index].totalOptIns + range?.optIn || 0,
          totalOptOuts: formattedStats[index].totalOptOuts + range?.optOut || 0,
          additionalData: {
            'Opt-Ins': (
              isDatasourcePreference ? datasourceOptInIndex > -1 : preferenceCenterOptInIndex > -1
            )
              ? formattedStats[index].additionalData['Opt-Ins'].map((item) => ({
                  ...item,
                  value:
                    item.id === (isDatasourcePreference ? dataSourceId : preferenceCenterId)
                      ? item.value + range?.optIn || 0
                      : item.value
                }))
              : [
                  ...formattedStats[index].additionalData['Opt-Ins'],
                  ...(range.optIn > 0
                    ? [
                        {
                          id: isDatasourcePreference ? dataSourceId : preferenceCenterId,
                          icon: isDatasourcePreference ? dataSourceType : '',
                          label: isDatasourcePreference ? dataSourceName : preferenceCenterName,
                          value: range.optIn || 0
                        }
                      ]
                    : [])
                ],
            'Opt-Outs': (
              isDatasourcePreference ? datasourceOptOutIndex > -1 : preferenceCenterOptOutIndex > -1
            )
              ? formattedStats[index].additionalData['Opt-Outs'].map((item) => ({
                  ...item,
                  value:
                    item.id === (isDatasourcePreference ? dataSourceId : preferenceCenterId)
                      ? item.value + range?.optOut || 0
                      : item.value
                }))
              : [
                  ...formattedStats[index].additionalData['Opt-Outs'],
                  ...(range.optOut > 0
                    ? [
                        {
                          id: isDatasourcePreference ? dataSourceId : preferenceCenterId,
                          icon: isDatasourcePreference ? dataSourceType : '',
                          label: isDatasourcePreference ? dataSourceName : preferenceCenterName,
                          value: range?.optOut || 0
                        }
                      ]
                    : [])
                ]
          }
        }
      } else {
        const slotName = formatRange(new Date(range.start), new Date(range.end))
        formattedStats[index] = {
          name: slotName,
          dateRange: {
            name: slotName,
            start: range.start,
            end: range.end
          },
          totalConsents: range?.total || 0,
          totalOptIns: range?.optIn || 0,
          totalOptOuts: range?.optOut || 0,
          additionalData: {
            ...(range?.optIn > 0
              ? {
                  'Opt-Ins': [
                    {
                      id: isDatasourcePreference ? dataSourceId : preferenceCenterId,
                      icon: isDatasourcePreference ? dataSourceType : '',
                      label: isDatasourcePreference ? dataSourceName : preferenceCenterName,
                      value: range?.optIn || 0
                    }
                  ]
                }
              : { 'Opt-Ins': [] }),
            ...(range?.optOut > 0
              ? {
                  'Opt-Outs': [
                    {
                      id: isDatasourcePreference ? dataSourceId : preferenceCenterId,
                      icon: isDatasourcePreference ? dataSourceType : '',
                      label: isDatasourcePreference ? dataSourceName : preferenceCenterName,
                      value: range?.optOut || 0
                    }
                  ]
                }
              : { 'Opt-Outs': [] })
          }
        }
      }
    })
  }

  return formattedStats
}

const getFormattedRegionConsents = (
  regionStats,
  formattedRegionStats,
  dataSource,
  preferenceCenter,
  consentStatSource
) => {
  const { id: dataSourceId, name: dataSourceName, type: dataSourceType } = dataSource
  const { id: preferenceCenterId, name: preferenceCenterName } = preferenceCenter

  const isDatasourcePreference = consentStatSource === 'DATASOURCE_PREFERENCE'

  if (
    consentStatSource === 'DATASOURCE_PREFERENCE' ||
    consentStatSource === 'PREFERENCE_CENTER' ||
    dataSourceId ||
    preferenceCenterId
  ) {
    regionStats.forEach((regionItem) => {
      const datasourceOptInIndex = formattedRegionStats[regionItem?.location]?.additionalData[
        'Opt-Ins'
      ]?.findIndex((item) => item?.id === dataSourceId)

      const datasourceOptOutIndex = formattedRegionStats[regionItem?.location]?.additionalData[
        'Opt-Outs'
      ]?.findIndex((item) => item?.id === dataSourceId)

      const preferenceCenterOptInIndex = formattedRegionStats[regionItem?.location]?.additionalData[
        'Opt-Ins'
      ]?.findIndex((item) => item?.id === preferenceCenterId)

      const preferenceCenterOptOutIndex = formattedRegionStats[
        regionItem?.location
      ]?.additionalData['Opt-Outs']?.findIndex((item) => item?.id === preferenceCenterId)

      if (formattedRegionStats[regionItem.location]) {
        formattedRegionStats[regionItem.location] = {
          totalConsents:
            formattedRegionStats[regionItem.location].totalConsents + regionItem?.total || 0,
          totalOptIns:
            formattedRegionStats[regionItem.location].totalOptIns + regionItem?.optIn || 0,
          totalOptOuts:
            formattedRegionStats[regionItem.location].totalOptOuts + regionItem?.optOut || 0,
          additionalData: {
            'Opt-Ins': (
              isDatasourcePreference ? datasourceOptInIndex > -1 : preferenceCenterOptInIndex > -1
            )
              ? formattedRegionStats[regionItem.location]?.additionalData['Opt-Ins'].map(
                  (item) => ({
                    ...item,
                    value:
                      item?.id === (isDatasourcePreference ? dataSourceId : preferenceCenterId)
                        ? item?.value + regionItem?.optIn || 0
                        : item?.value
                  })
                )
              : [
                  ...formattedRegionStats[regionItem.location]?.additionalData['Opt-Ins'],
                  ...(regionItem?.optIn > 0
                    ? [
                        {
                          id: isDatasourcePreference ? dataSourceId : preferenceCenterId,
                          icon: isDatasourcePreference ? dataSourceType : '',
                          label: isDatasourcePreference ? dataSourceName : preferenceCenterName,
                          value: regionItem?.optIn
                        }
                      ]
                    : [])
                ],
            'Opt-Outs': (
              isDatasourcePreference ? datasourceOptOutIndex > -1 : preferenceCenterOptOutIndex > -1
            )
              ? formattedRegionStats[regionItem.location]?.additionalData['Opt-Outs'].map(
                  (item) => ({
                    ...item,
                    value:
                      item?.id === (isDatasourcePreference ? dataSourceId : preferenceCenterId)
                        ? item.value + regionItem?.optOut || 0
                        : item?.value
                  })
                )
              : [
                  ...formattedRegionStats[regionItem.location]?.additionalData['Opt-Outs'],
                  ...(regionItem?.optOut > 0
                    ? [
                        {
                          id: isDatasourcePreference ? dataSourceId : preferenceCenterId,
                          icon: isDatasourcePreference ? dataSourceType : '',
                          label: isDatasourcePreference ? dataSourceName : preferenceCenterName,
                          value: regionItem?.optOut || 0
                        }
                      ]
                    : [])
                ]
          }
        }
      } else {
        formattedRegionStats[regionItem.location] = {
          totalConsents: regionItem?.total || 0,
          totalOptIns: regionItem?.optIn || 0,
          totalOptOuts: regionItem?.optOut || 0,
          additionalData: {
            ...(regionItem?.optIn > 0
              ? {
                  'Opt-Ins': [
                    {
                      id: isDatasourcePreference ? dataSourceId : preferenceCenterId,
                      icon: isDatasourcePreference ? dataSourceType : '',
                      label: isDatasourcePreference ? dataSourceName : preferenceCenterName,
                      value: regionItem?.optIn || 0
                    }
                  ]
                }
              : { 'Opt-Ins': [] }),
            ...(regionItem?.optOut > 0
              ? {
                  'Opt-Outs': [
                    {
                      id: isDatasourcePreference ? dataSourceId : preferenceCenterId,
                      icon: isDatasourcePreference ? dataSourceType : '',
                      label: isDatasourcePreference ? dataSourceName : preferenceCenterName,
                      value: regionItem?.optOut || 0
                    }
                  ]
                }
              : { 'Opt-Outs': [] })
          }
        }
      }
    })
  }

  return formattedRegionStats
}

export const mapQueryConsentStats = (raw: any) => {
  try {
    let totalConsentsCount = 0,
      totalOptIns = 0,
      totalOptOuts = 0,
      totalConsentsfromDatasources = 0,
      totalConsentsfromPreferenceCenters = 0,
      totalPreferenceCenters = 0,
      datasourceIds: any = [],
      last4WeeksData: any = [],
      last12MonthsData: any = [],
      consentsByRegionMap: any = {}

    const consentsByDataSourceMap: any = {}

    raw.consentStats.edges.forEach(({ node }) => {
      if (node.source === 'PREFERENCE_CENTER' || node.source === 'DATASOURCE_PREFERENCE') {
        totalConsentsCount += node.consentStats.total
        totalOptIns += node.consentStats.optIn
        totalOptOuts += node.consentStats.optOut
      }
      const datasourceId = node.datasource.edges[0]?.node.id

      if (node.source === 'DATASOURCE_PREFERENCE') {
        totalConsentsfromDatasources += node.consentStats.total

        if (!datasourceIds.includes(datasourceId)) {
          datasourceIds = [...datasourceIds, datasourceId]
          consentsByDataSourceMap[datasourceId] = {
            name: node.datasource.edges[0]?.node.name,
            total: node.consentStats.total,
            totalOptIn: node.consentStats.optIn,
            totalOptOut: node.consentStats.optOut
          }
        } else {
          consentsByDataSourceMap[datasourceId] = {
            ...consentsByDataSourceMap[datasourceId],
            total: consentsByDataSourceMap[datasourceId].total + node.consentStats.total,
            totalOptIn: consentsByDataSourceMap[datasourceId].totalOptIn + node.consentStats.optIn,
            totalOptOut:
              consentsByDataSourceMap[datasourceId].totalOptOut + node.consentStats.optOut
          }
        }
      }

      if (node.source === 'PREFERENCE_CENTER') {
        totalPreferenceCenters += 1
        totalConsentsfromPreferenceCenters += node.consentStats.total
      }

      /* Calculate last 4 weeks data */
      last4WeeksData = getFormattedStats(
        node.weeklyStats,
        last4WeeksData,
        node.datasource.edges[0]?.node || {},
        node.preferenceCenter.edges[0]?.node || {},
        node.source
      )

      /* Calculate last 12 months data */
      last12MonthsData = getFormattedStats(
        node.monthlyStats,
        last12MonthsData,
        node.datasource.edges[0]?.node || {},
        node.preferenceCenter.edges[0]?.node || {},
        node.source
      )

      /* Calculate regions data */
      consentsByRegionMap = getFormattedRegionConsents(
        node.locationStats,
        consentsByRegionMap,
        node.datasource.edges[0]?.node || {},
        node.preferenceCenter.edges[0]?.node || {},
        node.source
      )
    })

    const consentsByRegion = Object.keys(consentsByRegionMap)
      .filter((location) => consentsByRegionMap[location].totalConsents > 0)
      .map((location) => ({
        name: location,
        ...consentsByRegionMap[location]
      }))

    return {
      totalOptIns,
      totalOptOuts,
      totalConsentsCount,
      totalConsentsfromDatasources,
      totalConsentsfromPreferenceCenters: totalConsentsfromPreferenceCenters || 0,
      totalPreferenceCenters,
      dataSourcesCount: datasourceIds.length,
      last4WeeksData,
      last4MonthsData: last12MonthsData.slice(0, 4),
      last12MonthsData,
      consentsByRegion
    }
  } catch (error) {
    console.error(error)
    return {
      totalConsentsCount: 0,
      totalOptIns: 0,
      totalOptOuts: 0,
      totalConsentsfromDatasources: 0,
      totalConsentsfromPreferenceCenters: 0,
      totalPreferenceCenters: 0,
      dataSourcesCount: 0,
      last4WeeksData: [],
      last4MonthsData: [],
      last12MonthsData: [],
      consentsByRegion: []
    }
  }
}

/* End of new consentStats query integration */
