import {
  queryConsentMangementLogs,
  mapQueryConsentManagementLogs,
  queryEntityTypes,
  mapQueryEntityTypes,
  mapQueryLocations,
  queryLocations,
  queryConsentLogsForUser,
  mapQueryConsentManagementLogsByUser,
  queryConsentLogsForUserSummary,
  mapQueryConsentManagementLogsByUserSummary,
  querySystemLogsCount,
  mapQuerySystemLogsCount,
  querySystemLogs,
  mapQuerySystemLogs,
  queryConsentCategories,
  mapQueryConsentCategories,
  queryFetchCategory,
  mapFetchCategory,
  mutateCreateConsentCategory,
  mutateUpdateConsentCategory,
  mutateDeleteConsentCategory,
  queryConsentCategoriesList,
  mapFetchConsentCategoriesList,
  queryPreferenceCenterForConsentCategories,
  mapFetchPreferenceCenterForConsentCategories,
  mutateCreateSubscription,
  mutateUpdateSubscription,
  mutationDeleteSubscription,
  queryModesOfCommunication,
  mapQueryModesOfCommunication,
  queryConsentSourceByDatasource,
  mapQueryConsentSourceByDatasource,
  queryConsentSourceByPreferenceCenter,
  mapQueryConsentSourceByPreferenceCenter,
  queryCreatedByDatasources,
  mapQueryCreatedByDatasources,
  mutationCreateStructSubscription,
  mutationGenerateSampleConsentForStructuredData,
  mapSampleConsentForStructuredData,
  queryConsentStats,
  mapQueryConsentStats,
  queryConsentManagementLogsCount,
  queryConsentLogsForUserConsentCount
} from './queries'
import {
  queryConsentManagementEntityDistribution,
  mapConsentManagementEntityDistribution,
  queryConsentManagementDistributionByDatasource,
  mapConsentManagementDistributionByDatasource,
  queryConsentManagementComplainceStausDetails,
  mapConsentManagementComplainceStausDetails,
  mapConsentManagementConsentByTypeDatasourceSubscriptionList,
  queryConsentManagementConsentByTypeDatasourceSubscriptionList,
  mapConsentManagementConsentByTrendOverTime,
  queryConsentManagementConsentByTrendOverTime,
  queryConsentManagementConsentCategoriesByPreferenceCenterId,
  mapConsentManagementConsentCategoriesByPreferenceCenterId,
  mapConsentManagementConsentByPrefenrenceCenterList,
  queryConsentManagementConsentByPrefenrenceCenterList
} from './overview/queries'
import {
  mapConsentManagementSettingsDatasourceDetail,
  mapConsentManagementSettingsDatasourceList,
  mapConsentManagementSettingsDatasourcesToAdd,
  mapConsentManagementSettingsPreferenceCenterListByDatasourceId,
  mapConsentManagementSettingsSubscriptionListCommunicationMode,
  mapConsentManagementStructDSDatabasesAndClusters,
  mapConsentManagementSupportedDataSourceTypes,
  mapQueryConsentLogSubscriptionFilters,
  mapQueryStructTablesByDatabaseId,
  mapQuerySubscriptionList,
  mapSubscription,
  mutationConsentManagementDeleteDatasource,
  mutationConsentManagementScanDatasource,
  mutationConsentManagementUpdateDatasourcesList,
  queryConsentManagementSettingsDatasourceDetail,
  queryConsentManagementSettingsDatasourceList,
  queryConsentManagementSettingsDatasourcesToAdd,
  queryConsentManagementSettingsPreferenceCenterListByDatasourceId,
  queryConsentManagementSettingsSubscriptionListCommunicationMode,
  queryConsentManagementSupportedDataSourceTypes,
  queryStructDSDatabasesAndClusters,
  queryStructTablesByDatabaseId,
  querySubscription,
  querySubscriptionList
} from './settings/settingsQueries'
import { Subscription } from './settings/structuredDSOnboarding/onboardStructuredDSTakeover'
import { DATA_SOURCE_TYPES, PAGE } from '../../constants'
import graphqlService from '../../services/graphqlService'
import apiService from '../../services/api/apiService'
import { RootState } from '../../rootReducer'
import { DownloadListParams } from '../../interfaces'

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

//Overview: Enitity Distribution

export const ACTION_CONSENT_MANAGEMENT_ENTITY_DISTRIBUTION_WIDGET =
  'consentManagement/overview/consentByEntity'

export const fetchConsentManagementEntityDistributionWidget = createAsyncThunk(
  ACTION_CONSENT_MANAGEMENT_ENTITY_DISTRIBUTION_WIDGET,
  async () => {
    const result = await graphqlService.execute(queryConsentManagementEntityDistribution())
    return mapConsentManagementEntityDistribution(result)
  }
)

// Consent By Trend Over Time
export const ACTION_CONSENT_MANAGEMENT_CONSENT_BY_TREND_OVER_TIME_WIDGET =
  'consentManagement/overview/consentByTrendOverTime'

export const fetchConsentManagementConsentByTrendOverTimeWidget = createAsyncThunk(
  ACTION_CONSENT_MANAGEMENT_CONSENT_BY_TREND_OVER_TIME_WIDGET,
  async ({
    selectedDateRangeOption,
    datasourceTypes = []
  }: {
    selectedDateRangeOption: string
    datasourceTypes: string[]
  }) => {
    const result = queryConsentManagementConsentByTrendOverTime(
      selectedDateRangeOption,
      datasourceTypes
    )

    const queryResponse = await graphqlService.execute(result.trendQuery)

    return mapConsentManagementConsentByTrendOverTime(queryResponse, result.dateRanges)
  }
)

//Consent By Type Datasource
export const ACTION_CONSENT_MANAGEMENT_CONSENTBY_TYPE_DATASOURCE_SUBSCRIPTION_LIST_WIDGET =
  'consentManagement/overview/consentByTypeDatasourceSubscriptionList'

export const fetchConsentManagementConsentByTypeDatasourceSubscriptionListWidget = createAsyncThunk(
  ACTION_CONSENT_MANAGEMENT_CONSENTBY_TYPE_DATASOURCE_SUBSCRIPTION_LIST_WIDGET,
  async () => {
    const result = await graphqlService.execute(
      queryConsentManagementConsentByTypeDatasourceSubscriptionList()
    )
    return mapConsentManagementConsentByTypeDatasourceSubscriptionList(result)
  }
)

//Overview: Consent By Type Preference Center Categories

export const ACTION_CM_CONSENTBY_TYPE_PREFERENCECENTER_CATEGORIES_WIDGET =
  'consentManagement/overview/PreferenceCenterCategories'

export const fetchConsentManagementConsentCategoriesByPreferenceCenterId = createAsyncThunk(
  ACTION_CM_CONSENTBY_TYPE_PREFERENCECENTER_CATEGORIES_WIDGET,
  async (preferenceGroupId?: string) => {
    const result = await graphqlService.execute(
      queryConsentManagementConsentCategoriesByPreferenceCenterId(preferenceGroupId)
    )
    return mapConsentManagementConsentCategoriesByPreferenceCenterId(result)
  }
)

// Overview: Consent By Prefenrence Center list
export const ACTION_CONSENT_MANAGEMENT_CONSENTBY_PREFENRENCECENTER_LIST_WIDGET =
  'consentManagement/overview/PrefenrenceCenterList'

export const fetchConsentManagementConsentByPreferenceCenterListWidget = createAsyncThunk(
  ACTION_CONSENT_MANAGEMENT_CONSENTBY_PREFENRENCECENTER_LIST_WIDGET,
  async () => {
    const result = await graphqlService.execute(
      queryConsentManagementConsentByPrefenrenceCenterList()
    )
    return mapConsentManagementConsentByPrefenrenceCenterList(result)
  }
)

// Overview : Compliance status
export const ACTION_CONSENT_MANAGEMENT_COMPLIANCE_STATUS_WIDGET =
  'consentManagement/overview/ComplianceStatus'

export const fetchConsentManagementComplianceStatusWidget = createAsyncThunk(
  ACTION_CONSENT_MANAGEMENT_COMPLIANCE_STATUS_WIDGET,
  async () => {
    const result = await graphqlService.execute(queryConsentManagementComplainceStausDetails())
    return mapConsentManagementComplainceStausDetails(result)
  }
)

//Overview: Consent By Distributed Datasource

export const ACTION_CONSENT_MANAGEMENT_DISTRIBUTIONBY_DATASOURCE_WIDGET =
  'consentManagement/overview/DistributionByDatasource'

export const fetchConsentManagementDistributionByDatasourceWidget = createAsyncThunk(
  ACTION_CONSENT_MANAGEMENT_DISTRIBUTIONBY_DATASOURCE_WIDGET,
  async () => {
    const result = await graphqlService.execute(queryConsentManagementDistributionByDatasource())
    return mapConsentManagementDistributionByDatasource(result)
  }
)

// Settings: Datasource List
export const ACTION_CONSENT_MANAGEMENT_SETTINGS_DATASOURCELIST =
  'consentManagement/setting/datasourceList'

export const fetchConsentManagementSettingsDatasourceList = createAsyncThunk(
  ACTION_CONSENT_MANAGEMENT_SETTINGS_DATASOURCELIST,
  async (datasourceTypes?: string[]) => {
    const result = await graphqlService.execute(
      queryConsentManagementSettingsDatasourceList(datasourceTypes || [])
    )
    return mapConsentManagementSettingsDatasourceList(result)
  }
)

// Settings: Datasource to add
export const ACTION_CONSENT_MANAGEMENT_SETTINGS_DATASOURCES_TO_ADD =
  'consentManagement/setting/datasourcesToAdd'

export const fetchConsentManagementSettingsDatasourcesToAdd = createAsyncThunk(
  ACTION_CONSENT_MANAGEMENT_SETTINGS_DATASOURCES_TO_ADD,
  async (datasourceTypes?: string[]) => {
    const result = await graphqlService.execute(
      queryConsentManagementSettingsDatasourcesToAdd(datasourceTypes || [])
    )
    return mapConsentManagementSettingsDatasourcesToAdd(result)
  }
)

// Settings: Subscription List
export const ACTION_CONSENT_MANAGEMENT_SETTINGS_SUBSCRIPTION =
  'consentManagement/setting/subscriptionList'

export const fetchSubscriptionList = createAsyncThunk(
  ACTION_CONSENT_MANAGEMENT_SETTINGS_SUBSCRIPTION,
  async (datasourceId: string) => {
    const result = await graphqlService.execute(querySubscriptionList(datasourceId))
    const {
      list,
      count
    }: {
      list: SubscriptionNode[]
      count: number
    } = mapQuerySubscriptionList(result)
    return { list, total: count }
  }
)

export const ACTION_DELETE_SUBSCRIPTION = 'consentManagement/setting/deleteSubscription'

export const deleteSubscription = createAsyncThunk(
  ACTION_DELETE_SUBSCRIPTION,
  async (subscriptionId: string) => {
    await graphqlService.execute(mutationDeleteSubscription(subscriptionId))
  }
)

export const ACTION_FETCH_SUBSCRIPTION = 'consentManagement/fetchSubscription'

export const fetchSubscription = createAsyncThunk(
  ACTION_FETCH_SUBSCRIPTION,
  async (subscriptionId: string) => {
    const result = await graphqlService.execute(querySubscription(subscriptionId))
    return mapSubscription(result)
  }
)

export const ACTION_CREATE_OR_UPDATE_SUBSCRIPTION = 'consentManagement/createOrUpdateSubscription'

export const createOrUpdateSubscription = createAsyncThunk(
  ACTION_CREATE_OR_UPDATE_SUBSCRIPTION,
  async (params: SubscriptionPayload) => {
    const mutateQuery = params.id ? mutateUpdateSubscription : mutateCreateSubscription
    return await graphqlService.execute(mutateQuery(params))
  }
)

export const ACTION_GENERATE_SAMPLE_CONSENT_FOR_STRUCTURED_DATA =
  'consentManagement/generateSampleConsentForStructuredData'

export const generateSampleConsentForStructuredData = createAsyncThunk(
  ACTION_GENERATE_SAMPLE_CONSENT_FOR_STRUCTURED_DATA,
  async (params: StructSubscriptionPayload, { rejectWithValue }) => {
    try {
      const raw = await graphqlService.execute(
        mutationGenerateSampleConsentForStructuredData(params)
      )
      const {
        list,
        count
      }: { list: ConsentManagementLog[]; count: number } = mapSampleConsentForStructuredData(raw)
      return { list, total: count }
    } catch (error: any) {
      return rejectWithValue({
        statusMessage: error?.errors?.[0]?.message ?? 'consentManagement.strucutds.error.sampleLogs'
      })
    }
  }
)

export const ACTION_CREATE_STRUCT_SUBSCRIPTION = 'consentManagement/createStructSubscription'

export const createStructSubscription = createAsyncThunk(
  ACTION_CREATE_STRUCT_SUBSCRIPTION,
  async (params: StructSubscriptionPayload, { rejectWithValue }) => {
    try {
      return await graphqlService.execute(mutationCreateStructSubscription(params))
    } catch (error: any) {
      return rejectWithValue({ statusMessage: error.errors?.[0]?.message })
    }
  }
)

// Settings: Communication Mode List
export const ACTION_CONSENT_MANAGEMENT_SETTINGS_SUBSCRIPTION_COMMUNICATION_MODE =
  'consentManagement/setting/subscriptionList/communicationMode'

export const fetchConsentManagementSettingsSubscriptionListCommunicationMode = createAsyncThunk(
  ACTION_CONSENT_MANAGEMENT_SETTINGS_SUBSCRIPTION_COMMUNICATION_MODE,
  async (args: { datasourceId: string; preferenceId: string }) => {
    const result = await graphqlService.execute(
      queryConsentManagementSettingsSubscriptionListCommunicationMode(
        args.datasourceId,
        args.preferenceId
      )
    )
    return mapConsentManagementSettingsSubscriptionListCommunicationMode(result)
  }
)

//Settings: Datasdource Detail

export const ACTION_CONSENT_MANAGEMENT_SETTINGS_DATASOURCEDETAIL =
  'consentManagement/setting/datasourceDetail'

export const fetchConsentManagementSettingsDatasourceDetail = createAsyncThunk(
  ACTION_CONSENT_MANAGEMENT_SETTINGS_DATASOURCEDETAIL,
  async ({ datasourceDetailId }: { datasourceDetailId: string }) => {
    const result = await graphqlService.execute(
      queryConsentManagementSettingsDatasourceDetail(datasourceDetailId)
    )
    return mapConsentManagementSettingsDatasourceDetail(result)
  }
)

//setting update datasources list
export const ACTION_CONSENT_MANAGEMENT_SETTINGS_UPDATE_DATASOURCES_LIST =
  'consentManagement/setting/updateDatasourcesList'

export const updateConsentManagementSettingsDatasourcesList = createAsyncThunk(
  ACTION_CONSENT_MANAGEMENT_SETTINGS_UPDATE_DATASOURCES_LIST,
  async (datasourceId: string) => {
    await graphqlService.execute(mutationConsentManagementUpdateDatasourcesList(datasourceId))
  }
)

// Settings: Datasource to Delete
export const ACTION_CONSENT_MANAGEMENT_SETTINGS_DELETE_DATASOURCE =
  'consentManagement/setting/deleteDatasourcesList'

export const deleteConsentManagementSettingsDatasource = createAsyncThunk(
  ACTION_CONSENT_MANAGEMENT_SETTINGS_DELETE_DATASOURCE,
  async (datasourceId: string) => {
    await graphqlService.execute(mutationConsentManagementDeleteDatasource(datasourceId))
  }
)

export const ACTION_CONSENT_MANAGEMENT_SETTINGS_SCAN_DATASOURCE =
  'consentManagement/setting/scanDatasource'

export const scanConsentManagementSettingsDatasource = createAsyncThunk(
  ACTION_CONSENT_MANAGEMENT_SETTINGS_SCAN_DATASOURCE,
  async (datasourceId: string) => {
    await graphqlService.execute(mutationConsentManagementScanDatasource(datasourceId))
  }
)

export const ACTION_CONSENT_MANAGEMENT_SUPPORTED_DATASOURCE_TYPES =
  'consentManagement/supportedDataSourceTypes'

export const fetchConsentManagementSupportedDataSourceTypes = createAsyncThunk(
  ACTION_CONSENT_MANAGEMENT_SUPPORTED_DATASOURCE_TYPES,
  async () => {
    const rawResult = await graphqlService.execute(queryConsentManagementSupportedDataSourceTypes())
    return mapConsentManagementSupportedDataSourceTypes(rawResult)
  }
)

export const ACTION_CONSENT_MANAGEMENT_PREFERENCE_CENTER_LIST_BY_DATASOURCEID =
  'consentManagement/preferenceCenterListByDatasourceId'

export const fetchConsentManagementPreferenceCenterListByDatasourceId = createAsyncThunk(
  ACTION_CONSENT_MANAGEMENT_PREFERENCE_CENTER_LIST_BY_DATASOURCEID,
  async (datasourceId: string) => {
    const rawResult = await graphqlService.execute(
      queryConsentManagementSettingsPreferenceCenterListByDatasourceId(datasourceId)
    )
    return mapConsentManagementSettingsPreferenceCenterListByDatasourceId(rawResult)
  }
)

export type ConsentsByKey = {
  name: string
  total: number
  type?: string
  id?: string
  preferenceGroupId?: string
  consentData?: { label: string; value: number }[]
}
export type ConsentsByDataSource = {
  id: string
  name: string
  type: DATA_SOURCE_TYPES
  total: number
  totalOptIns?: number
  totalOptOuts?: number
  totalOther?: number
}
export type ConsentsByEntity = {
  name: string
  totalOptIns: number
  totalOptOuts: number
  id?: string
}

export type ConsentsByRegion = {
  name: string
  totalOptIns: number
  totalOptOuts: number
}

export type ConsentsByTrendOverTime = {
  name: string
  totalOptIns: number
  totalOptOuts: number
  totalConsents: number
  additionalData?: {
    [ConsentManagementConsent.optIn]?: {
      label: string
      value: number
    }[]
    [ConsentManagementConsent.optOut]?: {
      label: string
      value: number
    }[]
  }
  dateRange?: {
    start: string
    end: string
  }
}

export enum ConsentManagementLogEvents {
  optIn = 'subscribed',
  optOut = 'unsubscribed',
  subscribed = 'subscribed',
  unsubscribed = 'unsubscribed',
  updated = 'Updated',
  unknown = 'unknown'
}

export type ConsentManagementLogItem = {
  id?: string
  name?: string
  type?: string
}
export enum ConsentManagementLogStatuses {
  optIn = 'optIn',
  optOut = 'optOut',
  subscribed = 'subscribed',
  unsubscribed = 'unsubscribed'
}

export enum ConsentManagementConsent {
  optIn = 'Opt-Ins',
  optOut = 'Opt-Outs'
}

export enum ConsentManagementLogSyncStatus {
  success = 'success',
  failed = 'failed',
  all = 'all'
}

export type ConsentManagementLogInfoItem = {
  id?: string
  name?: string
  description?: string
}
export type ComplianceDetail = {
  complianceName: string
  data: {
    isCompliant: boolean
    count: number
    key: string
  }
}

export type ConsentManagementSource = {
  id: string
  name: string
}

export type EntityType = {
  id: string
  name: string
}

export type DatasourceListNode = {
  icon: string
  title: string
  subscriptionCount: number
  consents: string
  alerts: number
  id: string
}

export type DatasourceToAdd = {
  id: string
  name: string
  type: string
}

export type DatasourceDetail = {
  name?: string
  type?: string
  consentHistoryCount?: number
  communicationPreferencesCount?: number
  preferenceCenterNamesIds?: { id: string; name: string }[]
}

export type SubscriptionNode = {
  id: string
  subscriptionName?: string
  description?: string
  preferenceCenterNames?: string[]
  preferenceCenterCount?: number
  optOutCount?: number
  optInCount?: number
  communicationModeCount?: number
}

export type CommunicationModeNode = {
  modeName?: string
  optIn?: number
  optOut?: number
}

export interface ConsentCategoryListParams {
  [PAGE]: number
  filters: {
    key: string
    values: string[]
  }[]
}

export interface ConsentManagementLogsParams {
  [PAGE]?: number
  start?: string
  end?: string
  entityId?: string
  filters?: Array<{
    key: string
    values: string[]
  }>
}

export type ConsentsByRegionData = {
  name: string
  totalOptIns: number
  totalOptOuts: number
  totalConsents: number
  additionalData?: {
    [ConsentManagementConsent.optIn]?: {
      label: string
      value: number
    }[]
    [ConsentManagementConsent.optOut]?: {
      label: string
      value: number
    }[]
  }
}

export interface SystemLogParams {
  groupName: string
  groupParams: ConsentManagementLogsParams[]
}

export type Consent = {
  syncStatus?: ConsentManagementLogSyncStatus
  consentEvent: ConsentManagementLogEvents
  consentInfo: ConsentManagementLogInfoItem
}

export type ConsentManagementLog = {
  id: string
  entityId?: string
  email?: string
  name?: string
  type?: string
  country?: string
  location?: string
  consentSource?: { id: string; name: string }
  createdAt?: string
  ipAddress?: string
  lawfulLegalBasis?: string
  comment?: string
  consents: Consent[]
}

export type SystemLog = {
  id: string
  entityId?: string
  email?: string
  name?: string
  type?: string
  entityAttached?: boolean
  country?: string
  location?: string
  syncStatus?: ConsentManagementLogSyncStatus
  consentSource?: string
  consentEvent?: ConsentManagementLogEvents
  createdAt?: string
  updatedAt?: string
  updateTimestamp?: string
  ipAddress?: string
  lawfulLegalBasis?: string
  consentInfo: ConsentManagementLogInfoItem[]
}

export type CountsBySyncStatus = {
  all?: number
  success?: number
  failed?: number
}

export type ConsentCategory = {
  id?: string
  name: string
  displayTitle: string
  displayDescription: string
  createdBy?: string
}

export type ConsentCategoryWithPreferenceCenter = ConsentCategory & {
  preferenceCenter: {
    id: string
    name: string
  }[]
}

export type SubscriptionPayload = {
  id?: string
  name: string
  datasourceId: string
  communicationMethods: {
    displayCommunicationMethod: string
    communicationMethod: string
  }[]
  description: string
}

export interface StructSubscriptionPayload {
  datasourceId: string
  clusterId?: string
  subscription: Subscription
}

export type PreferenceCenterNode = {
  id: string
  preferenceCenterGroupId: string
  name: string
  datasourceList: { id: string; name: string; type: string }[]
}

export type ConsentCategoryPayload = {
  currentUserEmail: string
} & ConsentCategory

export enum OnBoardStructuredDSSteps {
  databaseAndCluster = 0,
  subscriptions,
  review
}

export type DatabasesAndClustersOption = {
  id: string
  name: string
  clusterOptions: { id: string; name: string }[]
}

export const ACTION_CONSENT_MANAGEMENT_LOGS = 'consentManagement/logs'

export const fetchConsentManagementLogs = createAsyncThunk(
  ACTION_CONSENT_MANAGEMENT_LOGS,
  async (params: ConsentManagementLogsParams) => {
    const rawResult = await graphqlService.execute(queryConsentMangementLogs(params))
    const { list }: { list: ConsentManagementLog[] } = mapQueryConsentManagementLogs(rawResult)
    return { list }
  }
)

export const ACTION_FETCH_CONSENT_MANAGEMENT_LOGS_COUNT = 'consentManagement/logs/count'

export const fetchConsentManagementLogsCount = createAsyncThunk(
  ACTION_FETCH_CONSENT_MANAGEMENT_LOGS_COUNT,
  async (params: ConsentManagementLogsParams) => {
    const rawResult = await graphqlService.execute(queryConsentManagementLogsCount(params))
    return rawResult.consents.count
  }
)

export const ACTION_CONSENT_MANAGEMENT_LOGS_GROUP_BY_USER_SUMMARY =
  'consentManagement/logsGroupByUserSummary'

export const fetchConsentManagementLogsGroupByUserSummary = createAsyncThunk(
  ACTION_CONSENT_MANAGEMENT_LOGS_GROUP_BY_USER_SUMMARY,
  async (entityId: string) => {
    const rawResult = await graphqlService.execute(queryConsentLogsForUserSummary(entityId))
    const {
      list,
      count
    }: {
      list: ConsentManagementLog[]
      count: number
    } = mapQueryConsentManagementLogsByUserSummary(rawResult)
    return { list, total: count }
  }
)

export const ACTION_CONSENT_MANAGEMENT_LOGS_GROUP_BY_USER = 'consentManagement/logsGroupByUser'

export const fetchConsentManagementLogsGroupByUser = createAsyncThunk(
  ACTION_CONSENT_MANAGEMENT_LOGS_GROUP_BY_USER,
  async (params: ConsentManagementLogsParams) => {
    const rawResult = await graphqlService.execute(queryConsentLogsForUser(params))
    const { list }: { list: ConsentManagementLog[] } = mapQueryConsentManagementLogsByUser(
      rawResult
    )
    return { list }
  }
)

export const ACTION_FETCH_CONSENT_MANAGEMENT_GROUP_BY_USER_LOGS_COUNT =
  'consentManagement/groupByUserLogs/count'

export const fetchConsentManagementLogsGroupByUserCount = createAsyncThunk(
  ACTION_FETCH_CONSENT_MANAGEMENT_GROUP_BY_USER_LOGS_COUNT,
  async (params: ConsentManagementLogsParams) => {
    const rawResult = await graphqlService.execute(queryConsentLogsForUserConsentCount(params))
    return rawResult.consents.count
  }
)

export const ACTION_CONSENT_MANAGEMENT_DATASOURCES = 'consentManagement/consentSources/datasource'

export const fetchConsentSourcesDatasource = createAsyncThunk(
  ACTION_CONSENT_MANAGEMENT_DATASOURCES,
  async () => {
    const rawResult = await graphqlService.execute(queryConsentSourceByDatasource)
    return mapQueryConsentSourceByDatasource(rawResult)
  }
)

export const ACTION_CONSENT_MANAGEMENT_PREFERENCE_CENTERS =
  'consentManagement/consentSources/preferenceCenters'

export const fetchConsentSourcesPreferenceCenter = createAsyncThunk(
  ACTION_CONSENT_MANAGEMENT_PREFERENCE_CENTERS,
  async () => {
    const rawResult = await graphqlService.execute(queryConsentSourceByPreferenceCenter)
    return mapQueryConsentSourceByPreferenceCenter(rawResult)
  }
)

export const ACTION_CONSENT_MANGEMENT_ENTITY_TYPES = 'consentManagement/entityTypes'

export const fetchEntityTypes = createAsyncThunk(
  ACTION_CONSENT_MANGEMENT_ENTITY_TYPES,
  async () => {
    const rawResult = await graphqlService.execute(queryEntityTypes)
    return mapQueryEntityTypes(rawResult)
  }
)

export const ACTION_CONSENT_MANAGEMENT_LOCATIONS = 'consentManagement/locations'

export const fetchLocations = createAsyncThunk(ACTION_CONSENT_MANAGEMENT_LOCATIONS, async () => {
  const rawResult = await graphqlService.execute(queryLocations)
  return mapQueryLocations(rawResult)
})

export const ACTION_CONSENT_MANAGEMENT_MODES_OF_COMMUNICATION =
  'consentManagement/modesOfCommunication'

export const fetchModesOfCommunication = createAsyncThunk(
  ACTION_CONSENT_MANAGEMENT_MODES_OF_COMMUNICATION,
  async () => {
    const rawResult = await graphqlService.execute(queryModesOfCommunication)
    return mapQueryModesOfCommunication(rawResult)
  }
)

const ACTION_CONSENT_MANAGEMENT_CREATED_BY_DATASOURCES = 'consentManagement/createdByDatasources'

export const fetchCreatedByDatasources = createAsyncThunk(
  ACTION_CONSENT_MANAGEMENT_CREATED_BY_DATASOURCES,
  async () => {
    const rawResult = await graphqlService.execute(queryCreatedByDatasources)
    return mapQueryCreatedByDatasources(rawResult)
  }
)

export const ACTION_FETCH_SYSTEM_LOGS = 'consentManagement/systemLogs'

export const fetchSystemLogs = createAsyncThunk(
  ACTION_FETCH_SYSTEM_LOGS,
  async (params: ConsentManagementLogsParams) => {
    const rawResult = await graphqlService.execute(querySystemLogs(params))
    const { list, count: total }: { list: SystemLog[]; count: number } = mapQuerySystemLogs(
      rawResult
    )
    return { list, total }
  }
)

export const ACTION_SYSTEM_LOG_SYNC_COUNT = 'consentManagement/systemLogs/syncCount'

export const fetchSystemLogsCounts = createAsyncThunk(
  ACTION_SYSTEM_LOG_SYNC_COUNT,
  async (params: SystemLogParams) => {
    const { groupName, groupParams } = params
    const rawResult = await graphqlService.execute(querySystemLogsCount(groupParams))
    return mapQuerySystemLogsCount(groupName, rawResult)
  }
)

export const ACTION_CONSENT_MANAGEMENT_CONSENT_CATEGORY = 'consentManagement/consentCategory'

export const fetchConsentCategories = createAsyncThunk(
  ACTION_CONSENT_MANAGEMENT_CONSENT_CATEGORY,
  async () => {
    const rawResult = await graphqlService.execute(queryConsentCategories)
    return mapQueryConsentCategories(rawResult)
  }
)

export const ACTION_CONSENT_MANAGEMENT_SUBSCRIPTION_LIST = 'consentManagement/subscriptionList'

export const fetchSubscriptions = createAsyncThunk(
  ACTION_CONSENT_MANAGEMENT_SUBSCRIPTION_LIST,
  async () => {
    const rawResult = await graphqlService.execute(querySubscriptionList({}))
    return mapQueryConsentLogSubscriptionFilters(rawResult)
  }
)

export const ACTION_CONSENT_MANAGEMENT_LOGS_EXPORT = 'consentManagement/logs/export'

export const fetchConsentManagementFilesToExport = createAsyncThunk(
  ACTION_CONSENT_MANAGEMENT_LOGS_EXPORT,
  async (params: DownloadListParams) => {
    await apiService.downloadFile(params)
  }
)
export const ACTION_FETCH_CONSENT_CATEGORY_LIST = 'consentCategories/fetchList'

export const fetchConsentCategoryList = createAsyncThunk(
  ACTION_FETCH_CONSENT_CATEGORY_LIST,
  async (params: ConsentCategoryListParams) => {
    const rawResult = await graphqlService.execute(queryConsentCategoriesList(params))
    return mapFetchConsentCategoriesList(rawResult)
  }
)

export const ACTION_FETCH_CONSENT_CATEGORY = 'consentCategories/fetch'

export const fetchConsentCategory = createAsyncThunk(
  ACTION_FETCH_CONSENT_CATEGORY,
  async (categoryId: string) => {
    const rawResult = await graphqlService.execute(queryFetchCategory(categoryId))
    return mapFetchCategory(rawResult)
  }
)

export const ACTION_CREATE_OR_UPDATE_CONSENT_CATEGORY =
  'consentCategories/createOrUpdateConsentCategory'

export const createOrUpdateConsentCategory = createAsyncThunk(
  ACTION_CREATE_OR_UPDATE_CONSENT_CATEGORY,
  async (params: ConsentCategory, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState
      const currentUserEmail = state.user.userInfo?.emailId || ''
      const allParams: ConsentCategoryPayload = { ...params, currentUserEmail }
      const mutateQuery = params.id ? mutateUpdateConsentCategory : mutateCreateConsentCategory
      return await graphqlService.execute(mutateQuery(allParams))
    } catch (error: any) {
      return rejectWithValue({ statusMessage: error.errors?.[0]?.message })
    }
  }
)

const ACTION_FETCH_PREFERENCE_CENTER_FOR_CONSENT_CATEGORY =
  'consentCategories/fetchPreferenceCenter'

export const fetchPreferenceCenterForConsentCategory = createAsyncThunk(
  ACTION_FETCH_PREFERENCE_CENTER_FOR_CONSENT_CATEGORY,
  async () => {
    const rawResult = await graphqlService.execute(queryPreferenceCenterForConsentCategories)
    return mapFetchPreferenceCenterForConsentCategories(rawResult)
  }
)

export const ACTION_DELETE_CONSENT_CATEGORY = 'consentCategories/delete'

export const deleteCategory = createAsyncThunk(
  ACTION_DELETE_CONSENT_CATEGORY,
  async (categoryId: string) => {
    return await graphqlService.execute(mutateDeleteConsentCategory(categoryId))
  }
)

export const ACTION_FETCH_DATABASES_AND_CLUSTERS = 'consentManagement/databasesAndClusters'

export const fetchDatabasesAndClusters = createAsyncThunk(
  ACTION_FETCH_DATABASES_AND_CLUSTERS,
  async (datasourceId: string) => {
    const result = await graphqlService.execute(queryStructDSDatabasesAndClusters(datasourceId))
    return mapConsentManagementStructDSDatabasesAndClusters(result)
  }
)

export interface StructTablesParams {
  datasourceId: string
  databaseId: string
}

export interface StructColumn {
  id: string
  name: string
  type: string
  piiAttributeId: string
}
export interface StructTable {
  id: string
  name: string
  columns: StructColumn[]
  sampleData: any
}

export const ACTION_FETCH_TABLES_BY_DATABASE_ID = 'consentManagement/tablesByDatabaseId'

export const fetchTablesByDatabaseId = createAsyncThunk(
  ACTION_FETCH_TABLES_BY_DATABASE_ID,
  async (params: StructTablesParams) => {
    const result = await graphqlService.execute(queryStructTablesByDatabaseId(params))
    return mapQueryStructTablesByDatabaseId(result)
  }
)

export const ACTION_FETCH_CONSENT_STATS = 'consentManagement/consentStats'

export const fetchConsentStats = createAsyncThunk(ACTION_FETCH_CONSENT_STATS, async () => {
  const result = await graphqlService.execute(queryConsentStats())
  return mapQueryConsentStats(result)
})

export interface ConsentsCategoriesByPreferenceCenter {
  [key: string]: ConsentsByKey[]
}
/** Initial state */
type ConsentManagementState = {
  overview: {
    totalConsents?: number
    totalOptIns?: number
    totalOptOuts?: number
    totalPreferenceCenters?: number
    totalDataSources?: number
    totalConsentsfromDatasources?: number
    totalConsentsfromPreferenceCenters?: number
    totalSystemAlerts?: number
    totalViolations?: number
    totalEntitiesAffected?: number
    consentsByRegion?: ConsentsByRegionData[]
    consentsByTrendOverTime?: ConsentsByTrendOverTime[]
    consentsByTrendOverLast4Weeks?: ConsentsByTrendOverTime[]
    consentsByTrendOverLast4Months?: ConsentsByTrendOverTime[]
    consentsByTrendOverLast12Months?: ConsentsByTrendOverTime[]
    consentsBySource?: ConsentsByKey[]
    consentsByDataSource?: ConsentsByDataSource[]
    consentsByEntity?: ConsentsByEntity[]
    consentsByTypePreferenceCenter?: ConsentsByKey[]
    consentsCategoriesByPreferenceCenter?: ConsentsByKey[]
    preferenceCenterTotalConsentRecordCount?: number
    consentsByTypeDatasourceSubscriptionList?: ConsentsByKey[]
    consentsByDatasourceDistribution?: ConsentsByKey[]
    consentsByPreferenceCenterList?: ConsentsByKey[]
    complianceDetails?: ConsentsByKey[]
    entityIdentifiedByLB?: number
    entityUnIdentifiedByLB?: number
  }
  settings: {
    datasourceList?: DatasourceListNode[]
    subscriptionList?: SubscriptionNode[]
    subscriptionListTotal?: number
    communicationModeDetails?: CommunicationModeNode[]
    datasourceDetail?: DatasourceDetail
    datasourceTypes?: string[]
    datasourcesToAddToConsentManagement?: DatasourceToAdd[]
    activeRemoteDatasourceToAddId?: string
    subscription?: {
      name: string
      description: string
      type: string
      communicationMethods: {
        communicationMethod: string
        displayCommunicationMethod: string
      }[]
    }
    preferenceCenterListByDatasourceId: PreferenceCenterNode[]
    activeStructuredDatasourceToAddEdit: {
      datasourceId?: string
      datasourceName?: string
      datasourceType?: string
      step1: {
        databasesAndClusterOptions: DatabasesAndClustersOption[]
        selectedDatabase?: string
        selectedCluster?: string | undefined
      }
      step2: {
        tableOptions: StructTable[]
        subscriptionDetails: Subscription
        emailAttributeId?: string
      }
    }
  }
  consentLogs?: ConsentManagementLog[]
  exportLogs?: { data: string; fileName: string }
  systemLogs?: SystemLog[]
  logsTotal?: number
  entityTypes?: EntityType[]
  supportedDataSourceTypes?: string[]
  purposes?: string[]
  locations?: string[]
  modesOfCommunication?: string[]
  createdByDatasources?: string[]
  datasources?: ConsentManagementSource[]
  preferenceCenters?: ConsentManagementSource[]
  consentCategories?: {
    id: string
    name: string
  }[]
  subscriptions?: {
    id: string
    name: string
  }[]
  systemLogsAll: CountsBySyncStatus
  categories: {
    selected?: ConsentCategory
    list?: ConsentCategory[]
    count?: number
    preferenceCenters?: {
      id: string
      name: string
    }[]
  }
}

export const initialState: ConsentManagementState = {
  overview: {
    consentsByPreferenceCenterList: [],
    consentsByTypePreferenceCenter: [],
    consentsCategoriesByPreferenceCenter: [],
    consentsByTypeDatasourceSubscriptionList: [],
    consentsByRegion: [],
    consentsByTrendOverTime: [],
    consentsByTrendOverLast4Weeks: [],
    consentsByTrendOverLast4Months: [],
    consentsByTrendOverLast12Months: [],
    consentsByEntity: [],
    consentsByDatasourceDistribution: [],
    totalSystemAlerts: 0,
    totalViolations: 0,
    totalConsentsfromDatasources: 0,
    complianceDetails: [],
    entityIdentifiedByLB: 0,
    entityUnIdentifiedByLB: 0
  },
  entityTypes: [],
  locations: [],
  consentCategories: [],
  modesOfCommunication: [],
  purposes: [
    'Newsletter and Email Communication',
    'Communication over Call',
    'Data Sharing with Partners'
  ],
  settings: {
    datasourceList: [],
    subscriptionList: [],
    subscriptionListTotal: 0,
    communicationModeDetails: [],
    datasourceTypes: [],
    datasourcesToAddToConsentManagement: [],
    subscription: undefined,
    preferenceCenterListByDatasourceId: [],
    activeStructuredDatasourceToAddEdit: {
      step1: {
        databasesAndClusterOptions: []
      },
      step2: {
        tableOptions: [],
        subscriptionDetails: {}
      }
    }
  },
  systemLogsAll: {},
  categories: {}
}

const consentManagementSlice = createSlice({
  name: 'consentManagement',
  initialState,
  reducers: {
    resetLogs: (state) => {
      state.consentLogs = initialState.consentLogs
      state.systemLogs = initialState.systemLogs
      state.logsTotal = initialState.logsTotal
    },
    resetCurrentCategory: (state) => {
      state.categories.selected = initialState.categories.selected
    },
    setActiveRemoteDatasourceToAdd: (state, action) => {
      state.settings.activeRemoteDatasourceToAddId = action.payload
    },
    resetActiveRemoteDatasourceToAdd: (state) => {
      state.settings.activeRemoteDatasourceToAddId =
        initialState.settings.activeRemoteDatasourceToAddId
    },
    setActiveStructuredDatasourceToAddEdit: (state, action) => {
      const { payload } = action
      state.settings.activeStructuredDatasourceToAddEdit = {
        ...state.settings.activeStructuredDatasourceToAddEdit,
        datasourceId: payload.key,
        datasourceName: payload.label,
        datasourceType: payload.icon
      }
    },
    resetActiveStructuredDatasourceToAddEdit: (state) => {
      state.settings.activeStructuredDatasourceToAddEdit =
        initialState.settings.activeStructuredDatasourceToAddEdit
    },
    resetCurrentSubscription: (state) => {
      state.settings.subscription = initialState.settings.subscription
    },
    resetExportLogs: (state) => {
      state.exportLogs = initialState.exportLogs
    },
    setSelectedDatabaseStructDSOnboarding: (state, action) => {
      state.settings.activeStructuredDatasourceToAddEdit.step1.selectedDatabase = action.payload
      state.settings.activeStructuredDatasourceToAddEdit.step2 =
        initialState.settings.activeStructuredDatasourceToAddEdit.step2
    },
    setSelectedClusterStructDSOnboarding: (state, action) => {
      state.settings.activeStructuredDatasourceToAddEdit.step1.selectedCluster = action.payload
      state.settings.activeStructuredDatasourceToAddEdit.step2 =
        initialState.settings.activeStructuredDatasourceToAddEdit.step2
    },
    setSubscriptionDetails: (state, action) => {
      state.settings.activeStructuredDatasourceToAddEdit.step2.subscriptionDetails = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchConsentManagementLogs.fulfilled, (state, { payload }) => {
      state.consentLogs = payload.list
    })
    builder.addCase(fetchConsentManagementLogsGroupByUser.fulfilled, (state, { payload }) => {
      state.consentLogs = payload.list
    })
    builder.addCase(
      fetchConsentManagementLogsGroupByUserSummary.fulfilled,
      (state, { payload }) => {
        state.consentLogs = payload.list
        state.logsTotal = payload.total || 0
      }
    )
    builder.addCase(fetchConsentManagementLogsCount.fulfilled, (state, { payload }) => {
      state.logsTotal = payload || 0
    })
    builder.addCase(fetchConsentManagementLogsGroupByUserCount.fulfilled, (state, { payload }) => {
      state.logsTotal = payload || 0
    })
    builder.addCase(generateSampleConsentForStructuredData.fulfilled, (state, { payload }) => {
      const data = payload as { list: ConsentManagementLog[]; total: number }
      state.consentLogs = data.list
      state.logsTotal = data.total
    })
    builder.addCase(fetchSystemLogs.fulfilled, (state, { payload }) => {
      state.systemLogs = payload.list
      state.logsTotal = payload.total || 0
    })
    builder.addCase(fetchConsentSourcesDatasource.fulfilled, (state, { payload }) => {
      state.datasources = payload
    })
    builder.addCase(fetchConsentSourcesPreferenceCenter.fulfilled, (state, { payload }) => {
      state.preferenceCenters = payload
    })
    builder.addCase(fetchEntityTypes.fulfilled, (state, { payload }) => {
      state.entityTypes = payload
    })
    builder.addCase(fetchLocations.fulfilled, (state, { payload }) => {
      state.locations = payload
    })
    builder.addCase(fetchModesOfCommunication.fulfilled, (state, { payload }) => {
      state.modesOfCommunication = payload
    })
    builder.addCase(fetchCreatedByDatasources.fulfilled, (state, { payload }) => {
      state.createdByDatasources = payload
    })
    builder.addCase(fetchConsentCategories.fulfilled, (state, { payload }) => {
      state.consentCategories = payload
    })
    builder.addCase(fetchSubscriptions.fulfilled, (state, { payload }) => {
      state.subscriptions = payload
    })
    builder.addCase(fetchConsentStats.fulfilled, (state, { payload }) => {
      state.overview = {
        ...state.overview,
        totalConsents: payload.totalConsentsCount,
        totalOptIns: payload.totalOptIns,
        totalOptOuts: payload.totalOptOuts,
        totalPreferenceCenters: payload.totalPreferenceCenters,
        totalDataSources: payload.dataSourcesCount,
        totalConsentsfromDatasources: payload.totalConsentsfromDatasources,
        totalConsentsfromPreferenceCenters: payload.totalConsentsfromPreferenceCenters,
        consentsByTrendOverLast4Weeks: payload.last4WeeksData,
        consentsByTrendOverLast4Months: payload.last4MonthsData,
        consentsByTrendOverLast12Months: payload.last12MonthsData,
        consentsByRegion: payload.consentsByRegion
      }
    })
    builder.addCase(
      fetchConsentManagementConsentCategoriesByPreferenceCenterId.fulfilled,
      (state, { payload }) => {
        state.overview.consentsCategoriesByPreferenceCenter = payload.preferenceCenterCategoryData
        state.overview.preferenceCenterTotalConsentRecordCount =
          payload.preferenceCenterTotalConsentRecordCount
      }
    )
    builder.addCase(
      fetchConsentManagementConsentByPreferenceCenterListWidget.fulfilled,
      (state, { payload }) => {
        state.overview.consentsByPreferenceCenterList = payload
      }
    )
    builder.addCase(
      fetchConsentManagementConsentByTrendOverTimeWidget.fulfilled,
      (state, { payload }) => {
        state.overview.consentsByTrendOverTime = payload
      }
    )
    builder.addCase(
      fetchConsentManagementEntityDistributionWidget.fulfilled,
      (state, { payload }) => {
        state.overview = {
          ...state.overview,
          consentsByEntity: payload.entitiesDistributionData,
          entityIdentifiedByLB: payload.entityIdentifiedByLB,
          entityUnIdentifiedByLB: payload.entityUnIdentifiedByLB
        }
      }
    )
    builder.addCase(
      fetchConsentManagementConsentByTypeDatasourceSubscriptionListWidget.fulfilled,
      (state, { payload }) => {
        state.overview.consentsByTypeDatasourceSubscriptionList = payload
      }
    )
    builder.addCase(
      fetchConsentManagementDistributionByDatasourceWidget.fulfilled,
      (state, { payload }) => {
        state.overview.consentsByDatasourceDistribution = payload
      }
    )
    builder.addCase(
      fetchConsentManagementComplianceStatusWidget.fulfilled,
      (state, { payload }) => {
        state.overview.complianceDetails = payload
      }
    )
    builder.addCase(
      fetchConsentManagementSettingsDatasourceList.fulfilled,
      (state, { payload }) => {
        state.settings.datasourceList = payload.datasourceList
      }
    )
    builder.addCase(
      fetchConsentManagementPreferenceCenterListByDatasourceId.fulfilled,
      (state, { payload }) => {
        state.settings.preferenceCenterListByDatasourceId = payload
      }
    )
    builder.addCase(
      fetchConsentManagementSettingsDatasourcesToAdd.fulfilled,
      (state, { payload }) => {
        state.settings.datasourcesToAddToConsentManagement = payload
      }
    )
    builder.addCase(fetchSubscriptionList.fulfilled, (state, { payload }) => {
      state.settings = {
        ...state.settings,
        subscriptionList: payload.list,
        subscriptionListTotal: payload.total || 0
      }
    })
    builder.addCase(fetchSubscription.fulfilled, (state, { payload }) => {
      state.settings = {
        ...state.settings,
        subscription: payload
      }
    })
    builder.addCase(
      fetchConsentManagementSettingsSubscriptionListCommunicationMode.fulfilled,
      (state, { payload }) => {
        state.settings.communicationModeDetails = payload
      }
    )
    builder.addCase(
      fetchConsentManagementSupportedDataSourceTypes.fulfilled,
      (state, { payload }) => {
        state.settings.datasourceTypes = payload
      }
    )
    builder.addCase(
      fetchConsentManagementSettingsDatasourceDetail.fulfilled,
      (state, { payload }) => {
        state.settings.datasourceDetail = payload
      }
    )
    builder.addCase(fetchSystemLogsCounts.fulfilled, (state, { payload }) => {
      state.systemLogsAll = {
        ...state.systemLogsAll,
        ...payload
      }
    })
    builder.addCase(fetchConsentCategory.fulfilled, (state, { payload }) => {
      state.categories.selected = payload
    })
    builder.addCase(fetchConsentCategoryList.fulfilled, (state, { payload }) => {
      state.categories.list = payload.list
      state.categories.count = payload.count
    })
    builder.addCase(fetchPreferenceCenterForConsentCategory.fulfilled, (state, { payload }) => {
      state.categories.preferenceCenters = payload
    })
    builder.addCase(fetchDatabasesAndClusters.fulfilled, (state, { payload }) => {
      state.settings.activeStructuredDatasourceToAddEdit.step1.databasesAndClusterOptions = payload
    })
    builder.addCase(fetchTablesByDatabaseId.fulfilled, (state, { payload }) => {
      state.settings.activeStructuredDatasourceToAddEdit.step2.tableOptions = payload.structTables
      state.settings.activeStructuredDatasourceToAddEdit.step2.emailAttributeId =
        payload.emailAttributeId
    })
  }
})

export const {
  resetLogs,
  resetCurrentCategory,
  setActiveRemoteDatasourceToAdd,
  resetActiveRemoteDatasourceToAdd,
  resetCurrentSubscription,
  resetExportLogs,
  setActiveStructuredDatasourceToAddEdit,
  setSelectedDatabaseStructDSOnboarding,
  setSelectedClusterStructDSOnboarding,
  setSubscriptionDetails,
  resetActiveStructuredDatasourceToAddEdit
} = consentManagementSlice.actions

export default consentManagementSlice.reducer
