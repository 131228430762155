import { FileSharesCountParams } from './dashboardSummarySlice'
import { MAIL_FOLDER_TYPE } from '../../constants'
import { gql } from 'graphql-request'

export interface IGetDatasourcePoliciesCountParams {
  datasourceId: string
}

export const queryPoliciesCountForDatasource = (
  params: IGetDatasourcePoliciesCountParams
): string => gql`
{
    datasources(id: "${params.datasourceId}"){
      edges{
        node{
          policies{
            count
          }
        }
      }
    }
  }`

export const mapQueryPoliciesCountForDatasource = (raw: any): { policiesCount?: number } => {
  try {
    return {
      policiesCount: raw.datasources.edges[0].node.policies.count
    }
  } catch (error) {
    console.error(error)
    return { policiesCount: 0 }
  }
}

export const queryPiiEmailsCount = (params: IGetDatasourcePoliciesCountParams): string => gql`
  {
      datasources(id: "${params.datasourceId}"){
        edges{
          node{
            mail(datasourceId: "${
              params.datasourceId
            }",booleanFilter: {key: HAS_PII_DATA, value: true}){
              count
            }
            
              ${
                window.__featureFlags.outlookProcessInboxMail
                  ? `
                  inboxEmails: mail(datasourceId: "${params.datasourceId}",booleanFilter: {key: HAS_PII_DATA, value: true}, filter: {key: MAIL_FOLDER_TYPE, values: ["${MAIL_FOLDER_TYPE.Inbox}"]}){
                  count
                }
              `
                  : ''
              }
            
           
            sentEmails: mail(datasourceId: "${
              params.datasourceId
            }",booleanFilter: {key: HAS_PII_DATA, value: true}, filter: {key: MAIL_FOLDER_TYPE, values: ["${
  MAIL_FOLDER_TYPE.Sent
}"]}){
              count
            }
          }
        }
      }
    }`

export const mapQueryPiiEmailsCount = (
  raw: any
): { emailsCount: number; inboxCount: number; sentCount: number } => {
  let emailsCount = 0,
    inboxCount = 0,
    sentCount = 0
  if (
    raw &&
    raw.datasources &&
    raw.datasources.edges[0] &&
    raw.datasources.edges[0].node &&
    raw.datasources.edges[0].node.mail
  ) {
    emailsCount = raw.datasources.edges[0]?.node?.mail?.count || 0
    inboxCount = raw.datasources.edges[0]?.node?.inboxEmails?.count || 0
    sentCount = raw.datasources.edges[0]?.node?.sentEmails?.count || 0
  }

  return { emailsCount, inboxCount, sentCount }
}

export const queryFileSharesCount = (params: FileSharesCountParams): string => {
  return gql`
  {
    datasources(id: "${params.datasourceId}"){
      edges {
        node {
          fileShare {
            count
          }
        }
      }
    }
  }
  `
}
export const mapQueryFileSharesCount = (raw: any): number => {
  try {
    return raw.datasources?.edges[0]?.node?.fileShare?.count || 0
  } catch (error) {
    console.error(error)
    throw error
  }
}
