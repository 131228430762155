import { sortByOrder } from './sortUtil'
import { ATTRIBUTE_SET_TYPES, CLASSICATION_TYPE, SENSITIVE_LABEL } from '../constants'
import { SORT_ORDER } from '../interfaces'
import type { PiaAttributeSetAttribute } from '../features/pia/queries'
import { AttributeNameIds } from '../features/attributes/attributesSlice'

// TO DO: Revamp with the new attribute sets in attributeIconsUtil after the backend implements them.

export const attributeSetTypeImage = {
  [ATTRIBUTE_SET_TYPES.PERSONAL]: 'attributeSet-icon-identity.svg',
  [ATTRIBUTE_SET_TYPES.FINANCIAL]: 'attributeSet-icon-financial.svg',
  [ATTRIBUTE_SET_TYPES.NATIONAL]: 'attributeSet-icon-national.svg',
  [ATTRIBUTE_SET_TYPES.MEDICAL]: 'attributeSet-icon-medical.svg',
  [ATTRIBUTE_SET_TYPES.LEGAL]: 'attributeSet-icon-legal.svg',
  [ATTRIBUTE_SET_TYPES.OTHERS]: 'attributeSet-icon-others.svg'
}

export const classificationTypeImage = {
  [CLASSICATION_TYPE.IDENTITY]: 'doc_identity.svg',
  [CLASSICATION_TYPE.FINANCIAL]: 'attributeSet-icon-financial.svg',
  [CLASSICATION_TYPE.MEDICAL]: 'doc_medical.svg',
  [CLASSICATION_TYPE.LEGAL]: 'doc_legal.svg',
  [CLASSICATION_TYPE.OTHERS]: 'doc_others.svg',
  [CLASSICATION_TYPE.GOVT_ID]: 'doc_national.svg',
  [CLASSICATION_TYPE.FINANCIAL_DOCUMENT]: 'attributeSet-icon-financial.svg',
  [CLASSICATION_TYPE.HUMAN_RESOURCE]: 'doc_hr.svg',
  [CLASSICATION_TYPE.SOCIAL]: 'doc_social.svg',
  [CLASSICATION_TYPE.ATTENTION_NEEDED]: 'doc_attentionNeeded.svg',
  [CLASSICATION_TYPE.UNCLASSIFIED]: 'doc_unclassified_grey.svg',
  [CLASSICATION_TYPE.EXPORT_CONTROLLED]: 'doc_export_controlled.svg',
  [CLASSICATION_TYPE.BUSINESS_OPERATIONS]: 'attribute-icon-business-operations.svg',
  [CLASSICATION_TYPE.CUSTOM]: 'doc_custom.svg'
}

export const CARDS_ICON_MAPPER = {
  [CLASSICATION_TYPE.IDENTITY]: classificationTypeImage[CLASSICATION_TYPE.IDENTITY],
  [CLASSICATION_TYPE.FINANCIAL]: classificationTypeImage[CLASSICATION_TYPE.FINANCIAL],
  [CLASSICATION_TYPE.FINANCIAL_DOCUMENT]:
    classificationTypeImage[CLASSICATION_TYPE.FINANCIAL_DOCUMENT],
  [CLASSICATION_TYPE.MEDICAL]: classificationTypeImage[CLASSICATION_TYPE.MEDICAL],
  [CLASSICATION_TYPE.HUMAN_RESOURCE]: classificationTypeImage[CLASSICATION_TYPE.HUMAN_RESOURCE],
  [CLASSICATION_TYPE.LEGAL]: classificationTypeImage[CLASSICATION_TYPE.LEGAL],
  [CLASSICATION_TYPE.UNCLASSIFIED]: classificationTypeImage[CLASSICATION_TYPE.UNCLASSIFIED],
  [CLASSICATION_TYPE.OTHERS]: classificationTypeImage[CLASSICATION_TYPE.OTHERS],
  [CLASSICATION_TYPE.EXPORT_CONTROLLED]:
    classificationTypeImage[CLASSICATION_TYPE.EXPORT_CONTROLLED],
  [CLASSICATION_TYPE.BUSINESS_OPERATIONS]:
    classificationTypeImage[CLASSICATION_TYPE.BUSINESS_OPERATIONS],
  [CLASSICATION_TYPE.CUSTOM]: classificationTypeImage[CLASSICATION_TYPE.CUSTOM]
}

export const getAttributeSetImage = (type: ATTRIBUTE_SET_TYPES): string =>
  attributeSetTypeImage[type || ATTRIBUTE_SET_TYPES.OTHERS] ||
  attributeSetTypeImage[ATTRIBUTE_SET_TYPES.OTHERS]

export const getClassificationTypeImage = (type: CLASSICATION_TYPE | string | undefined) => {
  const typeFromRaw = CARDS_ICON_MAPPER[type || '']

  return (
    classificationTypeImage[type || typeFromRaw || CLASSICATION_TYPE.UNCLASSIFIED] ||
    classificationTypeImage[CLASSICATION_TYPE.CUSTOM]
  )
}

export const sortAttributesByInstanceCountAndSensitivity = (
  attributes: PiaAttributeSetAttribute[],
  sortOrder: SORT_ORDER
): PiaAttributeSetAttribute[] => {
  const highSensitivityAttributes =
    sortByOrder(
      attributes.filter((attr) => attr.sensitivityLabel === SENSITIVE_LABEL.HIGH) || [],
      'instanceCount',
      sortOrder
    ) || []

  const mediumSensitivityAttributes =
    sortByOrder(
      attributes.filter((attr) => attr.sensitivityLabel === SENSITIVE_LABEL.MEDIUM) || [],
      'instanceCount',
      sortOrder
    ) || []

  const lowSensitivityAttributes =
    sortByOrder(
      attributes.filter((attr) => attr.sensitivityLabel === SENSITIVE_LABEL.LOW) || [],
      'instanceCount',
      sortOrder
    ) || []

  return [...highSensitivityAttributes, ...mediumSensitivityAttributes, ...lowSensitivityAttributes]
}

export const sortAttributesBySensitivity = (attributes: AttributeNameIds[]): AttributeNameIds[] => {
  const highSensitivityAttributes = attributes.filter(
    (attr) => attr.sensitivityLabel === SENSITIVE_LABEL.HIGH
  )

  const mediumSensitivityAttributes = attributes.filter(
    (attr) => attr.sensitivityLabel === SENSITIVE_LABEL.MEDIUM
  )

  const lowSensitivityAttributes = attributes.filter(
    (attr) => attr.sensitivityLabel === SENSITIVE_LABEL.LOW
  )

  return [...highSensitivityAttributes, ...mediumSensitivityAttributes, ...lowSensitivityAttributes]
}

export const getAttributeUniqueValues = (list: { internalName: string }[]) =>
  Array.from(new Set(list.map(({ internalName }) => internalName)))

export const getAttributeValuesData = (list: { internalName: string; name: string }[]) =>
  list.map(({ internalName, name }) => ({ key: internalName, name }))
