import { Cookie, ProblematicDomainGroup, ProblematicCategoryGroup } from './domainsSlice'

export function checkForCookieCollision(
  cookies: Cookie[]
): {
  cookieCollision: boolean
  problematicCategories: string[]
  problematicDomains: string[]
  problematicGroupsByDomains: ProblematicDomainGroup[]
} {
  if (!cookies.length) {
    return {
      cookieCollision: false,
      problematicCategories: [],
      problematicDomains: [],
      problematicGroupsByDomains: []
    }
  }
  const domainCategoryMap: {
    [key: string]: Set<Cookie['cookieCategoryId']>
  } = {}

  const problematicCategories = new Set<string>()
  const problematicDomains = new Set<string>()

  for (const cookie of cookies) {
    if (cookie.domain) {
      if (!domainCategoryMap[cookie.domain]) {
        domainCategoryMap[cookie.domain] = new Set<string>()
      }
      if (cookie.cookieCategoryId) {
        domainCategoryMap[cookie.domain].add(cookie.cookieCategoryId)
      }
      if (domainCategoryMap[cookie.domain].size > 1) {
        if (cookie.cookieCategoryId) {
          domainCategoryMap[cookie.domain].forEach((categoryId) => {
            if (categoryId) {
              problematicCategories.add(categoryId)
            }
          })
        }
        problematicDomains.add(cookie.domain)
      }
    }
  }

  const cookieCollision = problematicCategories.size > 0 || problematicDomains.size > 0

  const problematicGroupsByDomains: ProblematicDomainGroup[] = []

  for (const domain of problematicDomains) {
    const cookiesByDomain = cookies.filter((cookie) => cookie.domain === domain)
    const domainItem: ProblematicDomainGroup = {
      domain,
      categories: []
    }

    const uniqCategories = cookiesByDomain
      .map((cookie) => cookie.cookieCategoryId)
      .filter((categoryId) => categoryId !== undefined)

    for (const categoryId of new Set(uniqCategories as string[])) {
      const categoryItem: ProblematicCategoryGroup = {
        categoryId,
        cookies: cookiesByDomain.filter((cookie) => cookie.cookieCategoryId === categoryId)
      }
      domainItem.categories.push(categoryItem as ProblematicCategoryGroup)
    }

    problematicGroupsByDomains.push(domainItem)
  }

  return {
    cookieCollision,
    problematicCategories: [...problematicCategories],
    problematicDomains: [...problematicDomains],
    problematicGroupsByDomains
  }
}

export const cookieConsentCountries = [
  {
    country: 'United States',
    states: [
      'Alabama',
      'Alaska',
      'Arizona',
      'Arkansas',
      'California',
      'Colorado',
      'Connecticut',
      'Delaware',
      'Florida',
      'Georgia',
      'Hawaii',
      'Idaho',
      'Illinois',
      'Indiana',
      'Iowa',
      'Kansas',
      'Kentucky',
      'Louisiana',
      'Maine',
      'Maryland',
      'Massachusetts',
      'Michigan',
      'Minnesota',
      'Mississippi',
      'Missouri',
      'Montana',
      'Nebraska',
      'Nevada',
      'New Hampshire',
      'New Jersey',
      'New Mexico',
      'New York',
      'North Carolina',
      'North Dakota',
      'Ohio',
      'Oklahoma',
      'Oregon',
      'Pennsylvania',
      'Rhode Island',
      'South Carolina',
      'South Dakota',
      'Tennessee',
      'Texas',
      'Utah',
      'Vermont',
      'Virginia',
      'Washington',
      'West Virginia',
      'Wisconsin',
      'Wyoming'
    ]
  },
  {
    country: 'Canada',
    states: [
      'Alberta',
      'British Columbia',
      'Manitoba',
      'New Brunswick',
      'Newfoundland and Labrador',
      'Nova Scotia',
      'Ontario',
      'Prince Edward Island',
      'Quebec',
      'Saskatchewan'
    ]
  },
  {
    country: 'Germany',
    states: [
      'Baden-Württemberg',
      'Bavaria',
      'Berlin',
      'Brandenburg',
      'Bremen',
      'Hamburg',
      'Hesse',
      'Lower Saxony',
      'Mecklenburg-Vorpommern',
      'North Rhine-Westphalia',
      'Rhineland-Palatinate',
      'Saarland',
      'Saxony',
      'Saxony-Anhalt',
      'Schleswig-Holstein',
      'Thuringia'
    ]
  },
  {
    country: 'India',
    states: [
      'Andhra Pradesh',
      'Arunachal Pradesh',
      'Assam',
      'Bihar',
      'Chhattisgarh',
      'Goa',
      'Gujarat',
      'Haryana',
      'Himachal Pradesh',
      'Jharkhand',
      'Karnataka',
      'Kerala',
      'Madhya Pradesh',
      'Maharashtra',
      'Manipur',
      'Meghalaya',
      'Mizoram',
      'Nagaland',
      'Odisha',
      'Punjab',
      'Rajasthan',
      'Sikkim',
      'Tamil Nadu',
      'Telangana',
      'Tripura',
      'Uttar Pradesh',
      'Uttarakhand',
      'West Bengal'
    ]
  },
  {
    country: 'Australia',
    states: [
      'New South Wales',
      'Queensland',
      'South Australia',
      'Tasmania',
      'Victoria',
      'Western Australia'
    ]
  },
  {
    country: 'United Kingdom',
    states: ['England', 'Scotland', 'Wales', 'Northern Ireland']
  },
  {
    country: 'Brazil',
    states: [
      'Acre',
      'Alagoas',
      'Amapá',
      'Amazonas',
      'Bahia',
      'Ceará',
      'Espírito Santo',
      'Goiás',
      'Maranhão',
      'Mato Grosso',
      'Mato Grosso do Sul',
      'Minas Gerais',
      'Pará',
      'Paraíba',
      'Paraná',
      'Pernambuco',
      'Piauí',
      'Rio de Janeiro',
      'Rio Grande do Norte',
      'Rio Grande do Sul',
      'Rondônia',
      'Roraima',
      'Santa Catarina',
      'São Paulo',
      'Sergipe',
      'Tocantins'
    ]
  },
  {
    country: 'France',
    states: [
      'Auvergne-Rhône-Alpes',
      'Bourgogne-Franche-Comté',
      'Brittany',
      'Centre-Val de Loire',
      'Corsica',
      'Grand Est',
      'Hauts-de-France',
      'Île-de-France',
      'Normandy',
      'Nouvelle-Aquitaine',
      'Occitanie',
      'Pays de la Loire',
      "Provence-Alpes-Côte d'Azur"
    ]
  },
  {
    country: 'Mexico',
    states: [
      'Aguascalientes',
      'Baja California',
      'Baja California Sur',
      'Campeche',
      'Chiapas',
      'Chihuahua',
      'Coahuila',
      'Colima',
      'Durango',
      'Guanajuato',
      'Guerrero',
      'Hidalgo',
      'Jalisco',
      'Mexico City',
      'Mexico State',
      'Michoacán',
      'Morelos',
      'Nayarit',
      'Nuevo León',
      'Oaxaca',
      'Puebla',
      'Querétaro',
      'Quintana Roo',
      'San Luis Potosí',
      'Sinaloa',
      'Sonora',
      'Tabasco',
      'Tamaulipas',
      'Tlaxcala',
      'Veracruz',
      'Yucatán',
      'Zacatecas'
    ]
  },
  {
    country: 'Japan',
    states: [
      'Hokkaido',
      'Aomori',
      'Iwate',
      'Miyagi',
      'Akita',
      'Yamagata',
      'Fukushima',
      'Ibaraki',
      'Tochigi',
      'Gunma',
      'Saitama',
      'Chiba',
      'Tokyo',
      'Kanagawa',
      'Niigata',
      'Toyama',
      'Ishikawa',
      'Fukui',
      'Yamanashi',
      'Nagano',
      'Gifu',
      'Shizuoka',
      'Aichi',
      'Mie',
      'Shiga',
      'Kyoto',
      'Osaka',
      'Hyogo',
      'Nara',
      'Wakayama',
      'Tottori',
      'Shimane',
      'Okayama',
      'Hiroshima',
      'Yamaguchi',
      'Tokushima',
      'Kagawa',
      'Ehime',
      'Kochi',
      'Fukuoka',
      'Saga',
      'Nagasaki',
      'Kumamoto',
      'Oita',
      'Miyazaki',
      'Kagoshima',
      'Okinawa'
    ]
  },
  {
    country: 'Italy',
    states: [
      'Abruzzo',
      'Basilicata',
      'Calabria',
      'Campania',
      'Emilia-Romagna',
      'Friuli Venezia Giulia',
      'Lazio',
      'Liguria',
      'Lombardy',
      'Marche',
      'Molise',
      'Piedmont',
      'Puglia',
      'Sardinia',
      'Sicily',
      'Tuscany',
      'Trentino-South Tyrol',
      'Umbria',
      'Veneto'
    ]
  },
  {
    country: 'South Africa',
    states: [
      'Eastern Cape',
      'Free State',
      'Gauteng',
      'KwaZulu-Natal',
      'Limpopo',
      'Mpumalanga',
      'North West',
      'Northern Cape',
      'Western Cape'
    ]
  },
  {
    country: 'China',
    states: [
      'Anhui',
      'Beijing',
      'Chongqing',
      'Fujian',
      'Gansu',
      'Guangdong',
      'Guangxi',
      'Guizhou',
      'Hainan',
      'Hebei',
      'Heilongjiang',
      'Henan',
      'Hong Kong',
      'Hubei',
      'Hunan',
      'Inner Mongolia',
      'Jiangsu',
      'Jiangxi',
      'Jilin',
      'Liaoning',
      'Macau',
      'Ningxia',
      'Qinghai',
      'Shaanxi',
      'Shandong',
      'Shanghai',
      'Shanxi',
      'Sichuan',
      'Tianjin',
      'Tibet',
      'Xinjiang',
      'Yunnan',
      'Zhejiang'
    ]
  },
  { country: 'Afghanistan', states: [] },
  { country: 'Albania', states: [] },
  { country: 'Algeria', states: [] },
  { country: 'Andorra', states: [] },
  { country: 'Angola', states: [] },
  { country: 'Antigua and Barbuda', states: [] },
  { country: 'Argentina', states: [] },
  { country: 'Armenia', states: [] },
  { country: 'Austria', states: [] },
  { country: 'Azerbaijan', states: [] },
  { country: 'Bahamas', states: [] },
  { country: 'Bahrain', states: [] },
  { country: 'Bangladesh', states: [] },
  { country: 'Barbados', states: [] },
  { country: 'Belarus', states: [] },
  { country: 'Belgium', states: [] },
  { country: 'Belize', states: [] },
  { country: 'Benin', states: [] },
  { country: 'Bhutan', states: [] },
  { country: 'Bolivia', states: [] },
  { country: 'Bosnia and Herzegovina', states: [] },
  { country: 'Botswana', states: [] },
  { country: 'Brunei', states: [] },
  { country: 'Bulgaria', states: [] },
  { country: 'Burkina Faso', states: [] },
  { country: 'Burundi', states: [] },
  { country: 'Cabo Verde', states: [] },
  { country: 'Cambodia', states: [] },
  { country: 'Cameroon', states: [] },
  { country: 'Central African Republic', states: [] },
  { country: 'Chad', states: [] },
  { country: 'Chile', states: [] },
  { country: 'Colombia', states: [] },
  { country: 'Comoros', states: [] },
  { country: 'Congo, Democratic Republic of the', states: [] },
  { country: 'Congo, Republic of the', states: [] },
  { country: 'Costa Rica', states: [] },
  { country: 'Croatia', states: [] },
  { country: 'Cuba', states: [] },
  { country: 'Cyprus', states: [] },
  { country: 'Czech Republic', states: [] },
  { country: 'Denmark', states: [] },
  { country: 'Djibouti', states: [] },
  { country: 'Dominica', states: [] },
  { country: 'Dominican Republic', states: [] },
  { country: 'Ecuador', states: [] },
  { country: 'Egypt', states: [] },
  { country: 'El Salvador', states: [] },
  { country: 'Equatorial Guinea', states: [] },
  { country: 'Eritrea', states: [] },
  { country: 'Estonia', states: [] },
  { country: 'Eswatini', states: [] },
  { country: 'Ethiopia', states: [] },
  { country: 'Fiji', states: [] },
  { country: 'Finland', states: [] },
  { country: 'Gabon', states: [] },
  { country: 'Gambia', states: [] },
  { country: 'Georgia', states: [] },
  { country: 'Ghana', states: [] },
  { country: 'Greece', states: [] },
  { country: 'Grenada', states: [] },
  { country: 'Guatemala', states: [] },
  { country: 'Guinea', states: [] },
  { country: 'Guinea-Bissau', states: [] },
  { country: 'Guyana', states: [] },
  { country: 'Haiti', states: [] },
  { country: 'Honduras', states: [] },
  { country: 'Hungary', states: [] },
  { country: 'Iceland', states: [] },
  { country: 'Indonesia', states: [] },
  { country: 'Iran', states: [] },
  { country: 'Iraq', states: [] },
  { country: 'Ireland', states: [] },
  { country: 'Israel', states: [] },
  { country: 'Jamaica', states: [] },
  { country: 'Jordan', states: [] },
  { country: 'Kazakhstan', states: [] },
  { country: 'Kenya', states: [] },
  { country: 'Kiribati', states: [] },
  { country: 'Korea, North', states: [] },
  { country: 'Korea, South', states: [] },
  { country: 'Kosovo', states: [] },
  { country: 'Kuwait', states: [] },
  { country: 'Kyrgyzstan', states: [] },
  { country: 'Laos', states: [] },
  { country: 'Latvia', states: [] },
  { country: 'Lebanon', states: [] },
  { country: 'Lesotho', states: [] },
  { country: 'Liberia', states: [] },
  { country: 'Libya', states: [] },
  { country: 'Liechtenstein', states: [] },
  { country: 'Lithuania', states: [] },
  { country: 'Luxembourg', states: [] },
  { country: 'Madagascar', states: [] },
  { country: 'Malawi', states: [] },
  { country: 'Malaysia', states: [] },
  { country: 'Maldives', states: [] },
  { country: 'Mali', states: [] },
  { country: 'Malta', states: [] },
  { country: 'Marshall Islands', states: [] },
  { country: 'Mauritania', states: [] },
  { country: 'Mauritius', states: [] },
  { country: 'Micronesia', states: [] },
  { country: 'Moldova', states: [] },
  { country: 'Monaco', states: [] },
  { country: 'Mongolia', states: [] },
  { country: 'Montenegro', states: [] },
  { country: 'Morocco', states: [] },
  { country: 'Mozambique', states: [] },
  { country: 'Myanmar', states: [] },
  { country: 'Namibia', states: [] },
  { country: 'Nauru', states: [] },
  { country: 'Nepal', states: [] },
  { country: 'Netherlands', states: [] },
  { country: 'New Zealand', states: [] },
  { country: 'Nicaragua', states: [] },
  { country: 'Niger', states: [] },
  { country: 'Nigeria', states: [] },
  { country: 'North Macedonia', states: [] },
  { country: 'Norway', states: [] },
  { country: 'Oman', states: [] },
  { country: 'Pakistan', states: [] },
  { country: 'Palau', states: [] },
  { country: 'Panama', states: [] },
  { country: 'Papua New Guinea', states: [] },
  { country: 'Paraguay', states: [] },
  { country: 'Peru', states: [] },
  { country: 'Philippines', states: [] },
  { country: 'Poland', states: [] },
  { country: 'Portugal', states: [] },
  { country: 'Qatar', states: [] },
  { country: 'Romania', states: [] },
  { country: 'Russia', states: [] },
  { country: 'Rwanda', states: [] },
  { country: 'Saint Kitts and Nevis', states: [] },
  { country: 'Saint Lucia', states: [] },
  { country: 'Saint Vincent and the Grenadines', states: [] },
  { country: 'Samoa', states: [] },
  { country: 'San Marino', states: [] },
  { country: 'Sao Tome and Principe', states: [] },
  { country: 'Saudi Arabia', states: [] },
  { country: 'Senegal', states: [] },
  { country: 'Serbia', states: [] },
  { country: 'Seychelles', states: [] },
  { country: 'Sierra Leone', states: [] },
  { country: 'Singapore', states: [] },
  { country: 'Slovakia', states: [] },
  { country: 'Slovenia', states: [] },
  { country: 'Solomon Islands', states: [] },
  { country: 'Somalia', states: [] },
  { country: 'South Sudan', states: [] },
  { country: 'Spain', states: [] },
  { country: 'Sri Lanka', states: [] },
  { country: 'Sudan', states: [] },
  { country: 'Suriname', states: [] },
  { country: 'Sweden', states: [] },
  { country: 'Switzerland', states: [] },
  { country: 'Syria', states: [] },
  { country: 'Taiwan', states: [] },
  { country: 'Tajikistan', states: [] },
  { country: 'Tanzania', states: [] },
  { country: 'Thailand', states: [] },
  { country: 'Timor-Leste', states: [] },
  { country: 'Togo', states: [] },
  { country: 'Tonga', states: [] },
  { country: 'Trinidad and Tobago', states: [] },
  { country: 'Tunisia', states: [] },
  { country: 'Turkey', states: [] },
  { country: 'Turkmenistan', states: [] },
  { country: 'Tuvalu', states: [] },
  { country: 'Uganda', states: [] },
  { country: 'Ukraine', states: [] },
  { country: 'United Arab Emirates', states: [] },
  { country: 'Uruguay', states: [] },
  { country: 'Uzbekistan', states: [] },
  { country: 'Vanuatu', states: [] },
  { country: 'Vatican City', states: [] },
  { country: 'Venezuela', states: [] },
  { country: 'Vietnam', states: [] },
  { country: 'Yemen', states: [] },
  { country: 'Zambia', states: [] },
  { country: 'Zimbabwe', states: [] }
]

export const getCCVersionFromName = (name: string) => {
  const lastIndexOfHyphen = name.lastIndexOf('_')
  return lastIndexOfHyphen !== -1 ? name.substring(lastIndexOfHyphen + 1) + '.0' : name
}
