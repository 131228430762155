import { isAirByteDatasource } from './datasourcesList'
import PrintPageHeader from '../../components/header'
import {
  DatasourceAtrributeDelta,
  fetchDeltaHighSensitivyAttributeInstance,
  stats
} from '../../printSlice'
import TrendPage from '../components/trendPageTemplate'
import { RootState } from '../../../rootReducer'
import { DATA_SOURCE_TYPES } from '../../../constants'
import {
  getDeltaReportAggregationTableData,
  getDeltaReportAttributeInstanceAggregation,
  getDeltaReportingAttributesAggregation,
  percentageDeltaCalculator
} from '../deltaReportUtil'
import { DataSource } from '../../../services/api/apiTypes'
import dayjs from 'dayjs'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
interface IProps {
  fetchDeltaHighSensitivyAttributeInstance: (datasourceIds: string[]) => void
  subText?: string
  historicalHighSensitivityAttributes?: {
    startTimestamp: string
    endTimestamp: string
    attributes: stats[]
  }[]
  unstructDatasourcesList?: DataSource[]
  datasourceId?: string
  datasourceName?: string
  datasourceAttributeInstanceStats?: DatasourceAtrributeDelta
  datasourceType?: DATA_SOURCE_TYPES
}
const SensitiveAttributes = ({
  fetchDeltaHighSensitivyAttributeInstance,
  historicalHighSensitivityAttributes,
  unstructDatasourcesList = [],
  datasourceId = '',
  subText,
  datasourceName,
  datasourceAttributeInstanceStats,
  datasourceType
}: IProps): React.ReactElement => {
  useEffect(() => {
    if (!historicalHighSensitivityAttributes?.length) {
      unstructDatasourcesList &&
        unstructDatasourcesList.length &&
        fetchDeltaHighSensitivyAttributeInstance(unstructDatasourcesList.map((ds) => ds.id || ''))
    }
  }, [
    fetchDeltaHighSensitivyAttributeInstance,
    historicalHighSensitivityAttributes,
    unstructDatasourcesList,
    datasourceId
  ])
  const showDeleted = datasourceType ? !isAirByteDatasource(datasourceType) : true
  const attributeInstances = datasourceId
    ? datasourceAttributeInstanceStats?.[datasourceId]
    : historicalHighSensitivityAttributes
  const agreegatedAttrStats = getDeltaReportAttributeInstanceAggregation(attributeInstances || [])
  const aggreegatedPercentageChange = percentageDeltaCalculator(
    agreegatedAttrStats.startTotal,
    agreegatedAttrStats.total
  )
  const attributeInstanceStats = getDeltaReportingAttributesAggregation(attributeInstances)
  const chartData = attributeInstanceStats.map((stats) => {
    return {
      name:
        dayjs(stats.startTimestamp).format('D MMM') +
        ' - ' +
        dayjs(stats.endTimestamp).format('D MMM'),
      value: stats.total
    }
  })
  const barData = attributeInstanceStats.map((stats) => {
    return {
      name:
        dayjs(stats.startTimestamp).format('D MMM') +
        ' - ' +
        dayjs(stats.endTimestamp).format('D MMM'),
      added: stats.added,
      deleted: stats.deleted
    }
  })

  const tableData = getDeltaReportAggregationTableData(attributeInstanceStats, showDeleted)

  return (
    <div className="sensitive-files delta-print-page" id={'sensitive-attributes' + datasourceId}>
      <PrintPageHeader
        text={'Sensitive Data Count: High Sensitivity'}
        {...(subText
          ? { subText }
          : datasourceId
          ? {
              subText: (
                <>
                  Datasource: <strong>{datasourceName}</strong>
                </>
              )
            }
          : {})}
        {...(subText || datasourceId ? { className: 'align-horizontal' } : {})}
      />
      <TrendPage
        chartData={chartData || []}
        tableData={tableData}
        barData={barData || []}
        trendWidgetData={[
          {
            count: agreegatedAttrStats.total,
            text: 'Current',
            percentage: aggreegatedPercentageChange
          },
          { count: agreegatedAttrStats.added, text: 'Instances Added', bulletColor: '#13C2C2' },
          {
            count: showDeleted ? agreegatedAttrStats.deleted : null,
            text: 'Instances Deleted',
            bulletColor: '#CF1322'
          }
        ]}
        tableHeaders={['Date Range', 'Start of range', 'Added', 'Deleted', 'End of range']}
        legendText={'Instances with sensitive data'}
        areaTitleText="Trend for total high sensitivity instances"
        barTitleText="Delta changes for high sensitivity instances"
        barLegendPrefix="Instances"
      />
    </div>
  )
}
const mapStateToProps = (state: RootState) => ({
  historicalHighSensitivityAttributes: state.print.historicalHighSensitivityAttributes,
  datasourceAttributeInstanceStats: state.print.datasourceHighSensitivityAttributeInstanceStats
})

const mapDispatchToProps = {
  fetchDeltaHighSensitivyAttributeInstance
}

export default connect(mapStateToProps, mapDispatchToProps)(SensitiveAttributes)
